import React, { useState } from "react";
import { ProfileChat } from "../../assets/svgs";
import { useAppSelector } from "../../app/hooks";
import noPic from "../../assets/png/no-pic.png";
import { Link, useNavigate } from "react-router-dom";
import { resetAll } from "../../features/auth/AuthSlice";
import { useDispatch } from "react-redux";
export const profileData = [
  {
    name: "Profile",
    link: "/profile",
  },
  {
    name: "Shared Briefs",
    link: "/profile?state=brief",
  },
  {
    name: "Payment",
    link: "/profile?state=payment",
  },
  {
    name: "Settings",
    link: "/profile?state=settings",
  },
  {
    name: "Help",
    link: "/profile",
  },
  {
    name: "Switch Profile",
    link: "/profile",
  },
];
const CustomProfile = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(resetAll());
    navigate("/login");
  };
  const user = useAppSelector((state) => state.auth);
  const userInfo = user?.userData?.user;
  const image =
    userInfo?.profile_image !== null || undefined
      ? userInfo?.profile_image
      : noPic;

  return (
    <div className="w-full  flex flex-col relative pr-4 items-end">
      <span
        className="inline-block relative cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <img className="h-16 w-16 rounded-full" src={image} alt="" />
        <span className="absolute bottom-0 right-0 block h-4 w-4 rounded-full ring-2 ring-white bg-green-400" />
      </span>
      {open && (
        <div
          className="absolute top-20   flex flex-col right-2 bg-white  min-h-[400px]  shadow-xl w-[226px] z-max rounded-[10px] "
          // onMouseLeave={() => setOpen(false)}
        >
          <div className="flex flex-row space-x-3 p-[22px] items-center">
            <ProfileChat />
            <p className="text-lg text-customBlack">Profile</p>
          </div>
          <div className="h-[1px]  w-full bg-border" />
          <div className="w-full  flex flex-col ">
            {profileData.map((item, index) => {
              return (
                <Link
                  to={item?.link}
                  className="text-customBlack text-lg hover:bg-slate-400 px-[22px] py-[16px]"
                  key={index}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
          <div className="h-[1px]  w-full bg-border" />
          <p
            className="text-lg text-customBlack p-[22px]"
            onClick={handleLogout}
          >
            Logout
          </p>
        </div>
      )}
    </div>
  );
};

export default CustomProfile;
