import { Helmet } from "react-helmet";
import HomeNav from "../../components/Navbars/HomeNav";
import NotificationHome from "../../components/Notification/NotificationHome";

const Notification = () => {
  return (
    <div className="w-full flex flex-col">
      <Helmet>
        <title>Notification</title>
      </Helmet>
      <HomeNav isSearch={false} />
      <div className="w-full bg-greyBg flex flex-col z-1">
        <NotificationHome />
      </div>
    </div>
  );
};

export default Notification;
