import React from "react";

interface textSize {
  text: string;
  className?: string;
}
export const BigText = ({ text, className }: textSize) => {
  return (
    <h1
      className={`md:text-[50px] text-[34px] font-[800] leading-[30px] md:leading-[62px] text-[#03132B] ${className} `}
    >
      {text}
    </h1>
  );
};

export const HeaderText = ({ text, className }: textSize) => {
  return (
    <h1
      className={`md:text-[32px] text-[24px] font-[800] leading-[30px] md:leading-[62px] text-[#03132B] ${className} `}
    >
      {text}
    </h1>
  );
};

export const Subtitle = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-base font-[400] leading-[20px] text-[#5E5E5E] font-Circular ${className} `}
    >
      {text}
    </h1>
  );
};

export const ModalSub = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-base font-[400] leading-[20px] text-[#5E5E5E]  ${className} `}
    >
      {text}
    </h1>
  );
};

export const ModalTitle = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-[24px] leading-6 font-[700]  text-[#03132B]  ${className} `}
    >
      {text}
    </h1>
  );
};
export const TopicText = ({ text, className }: textSize) => {
  return (
    <h1
      className={`text-[22px] leading-6 font-[700]  text-[#0e0e0e]  ${className} `}
    >
      {text}
    </h1>
  );
};
