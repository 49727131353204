import React from "react";
import ContainerLayout from "./ContainerLayout";
import log from "../assets/png/login-bg.png";
import logo from "../assets/svgs/laploy-logo.svg";

const LoginContainer = ({ children }: any) => {
  return (
    <div className="layout-bg">
      <div className="w-full flex space-x-8  ">
        <div className=" w-full md:w-1/2 flex flex-col md:pl-16 px-4 bg-[#f9f9f9] min-h-screen ">
          <div className="lg">
            <img src={logo} alt="laploy" className="w-auto h-auto mt-[30px]" />
          </div>
          <div className="md:pb-20 pb-10">{children}</div>
        </div>
        <div className="md:w-1/2 hidden md:block"></div>
      </div>
    </div>
  );
};

export default LoginContainer;
