import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
export interface AuthState {
  loading: boolean;
  token: string;
  allLawyers: any;
  myBriefs: any;
  singleBrief: any;
  error: boolean;
  success: boolean;
  sharedBrief: any;
  singleLawyer: any;
  pendingInterview: any;
  errorMessage: string;
  editLawyerSuccess: boolean;
  allSearchResult: any;
  
}

const initialState: AuthState = {
  loading: false,
  token: "",
  allLawyers: [],
  singleLawyer: {},
  pendingInterview: [],
  myBriefs: [],
  singleBrief: {},
  error: false,
  success: false,
  sharedBrief: [],
  errorMessage: "",
  editLawyerSuccess: false,
  allSearchResult: [],
};

export const lawyerSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
     
    },
    resetAll: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.errorMessage = "";
      state.editLawyerSuccess = false;
    },
 
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllLawyers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLawyers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allLawyers = payload.data;
      })
      .addCase(getAllLawyers.rejected, (state, { payload }) => {
        state.loading = false;

        state.allLawyers = [];
      })
      .addCase(getAllLawyersFirm.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLawyersFirm.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allLawyers = payload.data;
      })
      .addCase(getAllLawyersFirm.rejected, (state, { payload }) => {
        state.loading = false;

        state.allLawyers = [];
      })
      .addCase(getAllLawyersPrivate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLawyersPrivate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allLawyers = payload.data;
      })
      .addCase(getAllLawyersPrivate.rejected, (state, { payload }) => {
        state.loading = false;

        state.allLawyers = [];
      })
      .addCase(getAllLawyersCorporate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLawyersCorporate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allLawyers = payload.data;
      })
      .addCase(getAllLawyersCorporate.rejected, (state, { payload }) => {
        state.loading = false;

        state.allLawyers = [];
      })
      .addCase(editLawyer.pending, (state) => {
        state.loading = true;
      })
      .addCase(editLawyer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.editLawyerSuccess = true;
      })
      .addCase(editLawyer.rejected, (state, { payload }) => {
        state.loading = false;

        state.editLawyerSuccess = false;
      })

      .addCase(getAllMyBrief.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMyBrief.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.myBriefs = payload.data;
      })
      .addCase(getAllMyBrief.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.myBriefs = [];
      })
      .addCase(searchLawyer.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchLawyer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allSearchResult = payload?.data;
      })
      .addCase(searchLawyer.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.allSearchResult = [];
      })
      .addCase(createBrief.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBrief.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(createBrief.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.success = false;
      })
      .addCase(getSingleBrief.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleBrief.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.singleBrief = payload.data;
      })
      .addCase(getSingleBrief.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.success = false;
        state.singleBrief = {};
      })
      .addCase(getSingleLawyer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleLawyer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleLawyer = payload.data;
      })
      .addCase(getSingleLawyer.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.success = false;
        state.singleLawyer = {};
      })
      .addCase(getSingleLawyerFirm.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleLawyerFirm.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleLawyer = payload.data;
      })
      .addCase(getSingleLawyerFirm.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.success = false;
        state.singleLawyer = {};
      })
      .addCase(getSingleLawyerCoop.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleLawyerCoop.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleLawyer = payload.data;
      })
      .addCase(getSingleLawyerCoop.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.success = false;
        state.singleLawyer = {};
      })
      .addCase(getSingleLawyerPrivate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleLawyerPrivate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleLawyer = payload.data;
      })
      .addCase(getSingleLawyerPrivate.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.success = false;
        state.singleLawyer = {};
      })
      .addCase(deleteBrief.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBrief.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(deleteBrief.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.success = false;
      })
      .addCase(sharedBrief.pending, (state) => {
        state.loading = true;
      })
      .addCase(sharedBrief.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sharedBrief = payload.data;
      })
      .addCase(sharedBrief.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.errorMessage = payload.error;
        state.success = false;
      })
      
      ;
    // start off
    // end of session
  },
});

export const getAllLawyers = createAsyncThunk(
  "getAllLawyers",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allLawyers}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getAllLawyersCorporate = createAsyncThunk(
  "getAllLawyersCorporate",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allLawyersCoop}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getAllLawyersFirm = createAsyncThunk(
  "getAllLawyersFirm",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allLawyersFirm}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getAllLawyersPrivate = createAsyncThunk(
  "getAllLawyersPrivate",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allLawyersPrivate}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getAllMyBrief = createAsyncThunk(
  "getAllMyBrief",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.myBrief}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const sharedBrief = createAsyncThunk(
  "sharedBrief",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.sharedBrief}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const createBrief = createAsyncThunk(
  "createBrief",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.myBrief}`,payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const searchLawyer = createAsyncThunk(
  "searchLawyer",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.searchLawyer}${payload}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const editLawyer = createAsyncThunk(
  "editLawyer",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.editLawyer}`,payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getSingleBrief = createAsyncThunk(
  "getSingleBrief",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.singleBrief}/${payload?.id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getSingleLawyer = createAsyncThunk(
  "getSingleLawyer",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.singleLawyer}/${payload}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getSingleLawyerPrivate = createAsyncThunk(
  "getSingleLawyerPriavte",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.singleLawyersPrivate}/${payload}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getSingleLawyerCoop = createAsyncThunk(
  "getSingleLawyerCoop",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.singleLawyersCoop}/${payload}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getSingleLawyerFirm = createAsyncThunk(
  "getSingleLawyerFirm",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.singleLawyersFirm}/${payload}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const deleteBrief = createAsyncThunk(
  "deleteBrief",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.delete(`${url.deleteBrief}/${payload?.id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);



export const lawyerSelector = (state: any) => state.lawyer;

export const { restoreDefault, resetAll } = lawyerSlice.actions;
export default lawyerSlice.reducer;
