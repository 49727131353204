import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { BriefLogo } from "../../../assets/svgs/homesvg";
import SideModal from "../../Modal/SideModal";
import noPic from "../../../assets/png/no-pic.png";
import { ModalTitle, Subtitle, TopicText } from "../../Text";
import { NormalInput } from "../../Input";
import UploadFile from "../../Upload/UploadFile";
import { Btn } from "../../Button";

const SharedBrief = () => {
  const dispatch = useAppDispatch();
  const lawyer = useAppSelector((state) => state.lawyer);
  const allBrief = lawyer?.sharedBrief;
  console.log(allBrief);
  const [open, setOpen] = useState(false);

  const { userData } = useAppSelector((state) => state.auth);
  const user = userData?.user;
  const image =
    user?.profile_image !== null || undefined ? user?.profile_image : noPic;
  const [selectedBrief, setSelectedBrief] = useState<any>(null);

  return (
    <div className="w-full flex flex-col bg-white rounded-[10px]">
      <div className="w-full px-4 2xl:px-6 pt-7 flex flex-col pb-1 border-b border-b-border ">
        <h1 className="text-blueText md:text-2xl text-xl font-bold">
          Shared Briefs
        </h1>
      </div>
      <div className="w-full flex flex-col space-y-4 md:px-6 px-3 mt-6 pb-2">
        {allBrief?.length !== 0 &&
          allBrief?.map((item: any, index: number) => {
            return (
              <div className="w-full flex justify-between items-center">
                <div className="w-auto flex items-center space-x-3">
                  <BriefLogo />
                  <div className="flex flex-col">
                    <h1 className="text-primaryBlue md:text-xl text-base font-bold">
                      {item?.name}
                    </h1>
                    <p className="text-neutral-500 md:text-base text-sm"></p>
                  </div>
                </div>
                <div>
                  <button
                    className="text-customOrange underline whitespace-nowrap"
                    onClick={() => {
                      setOpen(true);
                      setSelectedBrief(item);
                    }}
                  >
                    View Brief{" "}
                  </button>
                </div>
              </div>
            );
          })}
      </div>
      <SideModal title="Brief Details" open={open} setOpen={setOpen}>
        <div className="flex flex-col justify-between relative h-screen">
          <div className="flex flex-col mt-7 px-2">
            <div className="mt-8 space-y-4">
              <ModalTitle text="Name" />
              <Subtitle text={selectedBrief?.name} />
            </div>

            <div className="mt-8 space-y-4">
              <ModalTitle text="Brief" />

              <Subtitle text={selectedBrief?.brief} />
            </div>
            {selectedBrief?._path && (
              <div className="mt-8 space-y-4 h-fit">
                <ModalTitle text="Documents" />
                {selectedBrief?._path.includes(".pdf") ? (
                  <iframe
                    title="Brief Document"
                    src={selectedBrief?._path}
                    width="100%"
                    height="500px"
                  />
                ) : (
                  <img
                    src={selectedBrief?._path}
                    className="w-full h-full max-h-[400px] object-cover"
                    alt=""
                  />
                )}
              </div>
            )}
          </div>

          <div className="block py-6">
            <a
              href={selectedBrief?._path}
              download
              target="_blank"
              rel="noreferrer"
            >
              <Btn name="Download Brief" active={true} />
            </a>
          </div>
          <div className="mt-10 pb-10"></div>
        </div>
      </SideModal>
    </div>
  );
};

export default SharedBrief;
