import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
export interface JobsState {
  loading: boolean;
  editFirmSuccess: boolean;

}

const initialState: JobsState = {
  loading: false,
  editFirmSuccess: false
};

export const firmSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.editFirmSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editFirmData.pending, (state) => {
        state.loading = true;
      })
      .addCase(editFirmData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.editFirmSuccess = true;
      
      })
      .addCase(editFirmData.rejected, (state, { payload }) => {
        state.loading = false;
        state.editFirmSuccess = false;

       
      });
    // start off
    // end of session
  },
});

export const editFirmData = createAsyncThunk(
  "editFirmData",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.editFirm}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const firmSelector = (state: any) => state.firm;

export const { restoreDefault } = firmSlice.actions;
export default firmSlice.reducer;
