import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Industry from "../../components/HomeComponents/Industry";
import HomeNav from "../../components/Navbars/HomeNav";

import BriefProfile from "../../components/ProfileSection/BriefProfile";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getSingleLawyer,
  getSingleLawyerFirm,
  getSingleLawyerCoop,
  getSingleLawyerPrivate,
} from "../../features/auth/LawyerSlice";
const BriefProfilePage = () => {
  const id = useParams();
  const lawyerId = id?.id;
  const dispatch = useAppDispatch();
  const lawyer = useAppSelector((state) => state.lawyer);
  const auth = useAppSelector((state) => state.auth);
  const lawyerInfo = lawyer?.singleLawyer;
  const [selectModal, setSelectedModal] = React.useState("");

  useEffect(() => {
    if (auth?.userData?.user?._type === "lawyer") {
      dispatch(getSingleLawyer(lawyerId));
    }
    if (auth?.userData?.user?._type === "firm") {
      dispatch(getSingleLawyerFirm(lawyerId));
    }
    if (auth?.userData?.user?._type === "corporation") {
      dispatch(getSingleLawyerCoop(lawyerId));
    }
    if (auth?.userData?.user?._type === "private") {
      dispatch(getSingleLawyerPrivate(lawyerId));
    }
  }, []);
  let userType = auth?.userData?.user?._type?.toUpperCase();

  return (
    <>
      <div className="w-full flex flex-col">
        <Helmet>
          <title>{userType}</title>
        </Helmet>
        <HomeNav isSearch={false} />
        <div className="w-full bg-greyBg flex flex-col z-1">
          <BriefProfile />
        </div>
      </div>
    </>
  );
};

export default BriefProfilePage;
