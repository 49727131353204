import React from "react";
import stars from "../../assets/png/star-rate.png";
import { useNavigate } from "react-router-dom";
import { getUserButton } from "../../utils";
import { useAppSelector } from "../../app/hooks";
import { useMediaQuery } from "react-responsive";
interface briefBox {
  name: string;
  sector: string;
  price: string;
  desc: string;
  rate: string;
  image: any;
  id?: string;
  auth?: string;
  year_of_call?: string;
}
const BriefCard = ({
  name,
  sector,
  price,
  desc,
  rate,
  image,
  year_of_call,
  id,
  auth,
}: briefBox) => {
  const navigate = useNavigate();
  const userData = useAppSelector((state) => state.auth);
  const userType = userData.userData.user._type;
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

  return (
    <div className="w-full login-box flex flex-col  h-full flow-hide brief-card-bg   cursor-pointer">
      <div className="border-t-[30px] border-[#CFCFCF] rounded-t-[10px]"></div>
      <div
        className="md:px-4 px-3 md:pt-4 mt-[-20px] md:mt-0 z-10 flow-hide h-full flex flex-col md:space-y-4 space-y-2"
        onClick={() => navigate(`/profile/brief/${id}`)}
      >
        <img
          src={image}
          alt="name"
          className="md:w-[90px] md:h-[90px] w-[70px] h-[70px]  rounded-full mx-auto object-cover"
        />
        <p className="text-[#0e0e0e] md:text-lg text-base font-bold text-center mt-2 ">
          {name}
        </p>
        <p className="text-[#5E5E5E] md:text-base text-sm font-medium text-center mt-1 ">
          {sector}
        </p>
        <p className="text-[#5E5E5E] md:text-base text-sm font-medium text-center mt-1 ">
          Year Of Call : {year_of_call}
        </p>
        <div className="hidden md:flex justify-between item-center mt-3">
          <div className="flex items-center space-x-1">
            <p className="md:text-base text-sm text-[#5e5e5e]">Price</p>
          </div>
          <div className="flex items-center space-x-1">
            <p className="md:text-base text-sm text-[#5e5e5e]">from</p>
            <p className="md:text-lg  text-base font-bold text-[#0e0e0e] ">
              {price}
            </p>
          </div>
        </div>
        {isDesktopOrLaptop && (
          <p className=" line-clamp-3 md:text-base text-sm text-justify text-[#5e5e5e] flex-grow">
            {desc}
          </p>
        )}
      </div>
      <div className="w-full my-4 md:mb-8">
        <div className="w-4/5 px-6 md:py-3 py-2 rounded-[10px] border border-sky-950 justify-center items-center gap-2 mx-auto flex ">
          <div className="text-sky-950 text-sm font-medium font-['Circular Std'] tracking-tight">
            Send Brief
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefCard;
