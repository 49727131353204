import React, { useState, useEffect } from "react";
import LoginBox from "../../components/LoginBox";
import LoginContainer from "../../Layouts/LoginContainers";
import {
  HeaderText,
  Subtitle,
  ModalSub,
  ModalTitle,
} from "../../components/Text";
import { NormalInput } from "../../components/Input";
import { Btn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import { Brief, Message, Security } from "../../assets/svgs";
import { useNavigate } from "react-router-dom";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (email) {
      setActive(true);
    } else setActive(false);
  }, [email]);
  return (
    <LoginContainer>
      <div className="md:w-9/12 w-full mt-28 ">
        <LoginBox>
          <HeaderText text="Forgot Password" />
          <Subtitle text="Enter your email address to get a code to reset your password" />
          <div className="w-full mt-10">
            <NormalInput
              onChange={(e) => setEmail(e.target.value)}
              name="Email"
              placeholder="Email Address"
            />
          </div>
          <button
            className={`mt-[192px] ${
              active ? "bg-primaryBlue" : "bg-primaryBlue opacity-40"
            } text-white w-full h-[52px] flex justify-center items-center font-Circular rounded-[20px] cursor-pointer hover:opacity-70`}
            disabled={!active}
            onClick={() => setOpen(true)}
          >
            Send Code
          </button>
        </LoginBox>
        <ReUseModal open={open} setOpen={setOpen}>
          <div className="w-full  flex flex-col justify-center">
            <div className="flex justify-center w-full">
              <Message />
            </div>
            <ModalTitle text="Check your email" className="text-center" />
            <ModalSub
              text="We’ve sent an OTP code to your email to reset your password"
              className="text-center mt-1"
            />
            <Btn
              className="mt-10"
              active={true}
              name="Continue"
              onClick={() => navigate("/otp")}
            />
          </div>
        </ReUseModal>
      </div>
    </LoginContainer>
  );
};

export default ForgotPassword;
