import { useState } from "react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import PrivateEditProfile from "./Private/PrivateEditProfile";
import Settings from "./Settings";
import MyBriefs from "./lawyers/MyBrief";
import SharedBrief from "./lawyers/SharedBrief";
import { resetAll } from "../../features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import noPic from "../../assets/png/no-pic.png";
const styles = {
  active:
    "h-[58px] bg-primaryBlue text-white py-[18px] px-[14px] flex items-center font-[500] cursor-pointer w-full",
  inactive:
    "h-[58px] bg-white text-neurtal-500  py-[18px] px-[14px] flex items-center font-[500] cursor-pointer w-full",
};
const PrivateProfile = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector((state) => state.auth);
  const user = userData?.user;
  const [present, setPresent] = useState("profile");
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(resetAll());
    navigate("/login");
    
  }
    const image =
      user?.profile_image !== null || undefined ? user?.profile_image : noPic;
  

  return (
    <ContainerLayout>
      <div className="w-full flex space-x-4 mt-4">
        <div className="w-1/3 flex flex-col">
          <img
            src={image}
            alt=""
            className="rounded-t-[10px] h-[194px] object-cover "
          />
          <p className=" text-xl font-bold text-primaryBlue mt-4 capitalize">{user?.first_name + " " +user?.middle_name  + " " +   user?.last_name}</p>
         
          <div className="flex flex-col mt-4">
            <p
              className={
                present === "profile" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("profile")}
            >
              Profile
            </p>
            <p
              className={present === "brief" ? styles.active : styles.inactive}
              onClick={() => setPresent("brief")}
            >
              Shared Brief
            </p>
            <p
              className={
                present === "mybrief" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("mybrief")}
            >
              My Brief
            </p>
            <p
              className={
                present === "settings" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("settings")}
            >
              Settings
            </p>
            <p
              className={present === "help" ? styles.active : styles.inactive}
              onClick={() => setPresent("settings")}
            >
              Help
            </p>
            <p
              className={
                present === "freelance" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("settings")}
            >
              Become a Freelance
            </p>
            <p
              className={present === "log" ? styles.active : styles.inactive}
              onClick={handleLogout}
            >
              Logout
            </p>
          </div>
        </div>
        {/* right side */}

        <div className="w-2/3">
          {present === "profile" && <PrivateEditProfile />}
          {present === "brief" && <SharedBrief />}
          {present === "mybrief" && <MyBriefs />}
          {present === "settings" && <Settings />}
        </div>
      </div>
    </ContainerLayout>
  );
};

export default PrivateProfile;
