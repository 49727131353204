import React from "react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import BriefCard from "./BriefCard";
import { BriefData, EmploymentData } from "../../utils/datas";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import EmploymentCard from "./EmploymentCard";
import pic from "../../assets/png/profile.jpg";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
const Employments = () => {
  const lawyers = useAppSelector((state) => state.lawyer);

  let allLawyers = lawyers?.allLawyers ? lawyers?.allLawyers : [];
  const [showAll, setShowAll] = React.useState(false);

  return (
    <ContainerLayout>
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <p className="md:text-2xl text-xl font-bold text-blueText">
            Employment
          </p>
          <div className="flex space-x-6 items-center text-[#626262]">
            <h1
              className="text-neutral-600 md:text-xl text-base font-semibold cursor-pointer"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "See Few" : "See All"}
            </h1>
          </div>
        </div>
        <div className="w-full grid md:grid-cols-4 grid-cols-2 sm:grid-cols-3 md:gap-x-8 gap-x-4 md:gap-y-[50px] gap-y-[30px] md:my-6 my-4">
          {allLawyers
            ?.slice(0, showAll ? allLawyers.length : 8)
            ?.map((item: any, index: number) => {
              const {
                area,
                country,
                first_name,
                last_name,
                profile_image,
                year_of_call,
                _id,
                bio,
                state,
              } = item;
              return (
                <EmploymentCard
                  key={index}
                  name={first_name + " " + last_name}
                  sector={area}
                  price={"0"}
                  desc={bio}
                  rate={""}
                  location={
                    country && state ? state + " ," + " " + country : ""
                  }
                  image={profile_image ? profile_image : pic}
                  year={year_of_call}
                  id={_id}
                />
              );
            })}
        </div>
      </div>
    </ContainerLayout>
  );
};

export default Employments;
