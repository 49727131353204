import React from "react";

interface buttonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  active?: boolean;
  name: string;
  className?: string;
}
export const Btn = ({ active, onClick, name, className }: buttonProps) => {
  return (
    <button
      className={`${className} ${
        active ? "bg-primaryBlue" : "bg-primaryBlue opacity-40"
      } text-white w-full h-[52px] flex justify-center items-center font-Circular rounded-[10px] cursor-pointer hover:opacity-70`}
      disabled={!active}
      onClick={onClick}
    >
      {name}
    </button>
  );
};
export const OutlineBtn = ({
  active,
  onClick,
  name,
  className,
}: buttonProps) => {
  return (
    <button
      className={`${className} border  w-full h-[52px] flex justify-center items-center font-Circular rounded-[10px] cursor-pointer hover:bg-primaryBlue hover:text-white border-primaryBlue  text-primaryBlue`}
      disabled={!active}
      onClick={onClick}
    >
      {name}
    </button>
  );
};
