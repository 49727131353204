import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
export interface GenerateState {
  loading: boolean;
  success: boolean;
   
  allNotifications: any;
  
}

const initialState: GenerateState = {
  loading: false,
  success: false,
   
    allNotifications: [],
};

export const generalSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
   
    },
  },
  extraReducers: (builder) => {
    builder
   
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNotifications.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allNotifications = payload?.data;
      })
      .addCase(getAllNotifications.rejected, (state, { payload }) => {
        state.loading = false;
        state.allNotifications = [];
      })
        
        ;
  },
});


export const getAllNotifications = createAsyncThunk(
  "getAllNotifications",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allNotifications}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const generalSelector = (state: any) => state.general;

export const { restoreDefault } = generalSlice.actions;
export default generalSlice.reducer;
