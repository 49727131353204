import { toast } from "react-toastify";
import { getStorageValue } from "./localStorage";

// const getError = (error: any) => {
//   if (!Object.keys(error).length || typeof error === "string") return error;
//   let errors: any = [];
//   if (Array.isArray(error) && error.length) {
//     errors = [error[0].message, ...error];
//   } else {
//     Object.keys(error).forEach((e) => {
//       return (errors = [...errors, error[e]]);
//     });
//   }

//   return errors.filter((e: any) => e);
// };

// const getErrors = (errorResponse: any) => {
//   if (errorResponse?.message || errorResponse?.data?.message)
//     return errorResponse.message || errorResponse.data.message;
//   let errors: any = [];
//   Object.keys(errorResponse).forEach((error) => {
//     return (errors = [...errors, ...getError(errorResponse[error])]);
//   });
//   return errors;
// };

export const getSimplifiedError = (error: any) => {
  if (!error.response) {
    // window.alert(
    //   "Something went wrong, check your internet and please try again"
    // );
    toast.error(
      "Something went wrong, check your internet and please try again"
    );
    return "Something went wrong, check your internet and please try again";
  }
  if (error.response?.status === 500) {
    // window.alert("Sorry an unexpected error occurred.");
    toast.error("Sorry an unexpected error occurred.");
    return "Sorry an unexpected error occurred.";
  }
  if (error.response?.status === 400) {
    console.log(error.response);
    // window.alert(error.response.data.error);
    toast.error(error.response.data.error);

    // window.location.reload();
  }
  if (error.response.status === 404) {
    toast.error(error.response.error);
    if (error.response.data.error === "user not found") {
      setTimeout(() => {
        window.location.replace("/register");
      }, 1000);
    }
    return error.response.data.error;
  } else if (error.response.status === 401) {
    // window.alert("Token has expired, please log in");
    sessionStorage.clear();
    if (error.response.data.error === "Invalid credentials") {
      toast.error("Invalid credentials");
      return "Invalid credentials";
    }
    // window.location.replace("/login");

    toast.error("Token has expired, please log in");
    return "Token has expired, please log in";
  } else if (error.response.status === 409) {
    // window.alert(error.response.data.error);
    toast.error(error.response.data.error);
    // setTimeout(() => {
    //   window.location.replace("/login");
    // }, 500);
    return error.response.data.error;
  } else {
    // window.alert(error.response.data.error);
    toast.error(error.response.data.error);
    // window.location.reload();
    return error.response.data.error;
  }
};

export const getCurrency = (name: string) => {
  switch (name) {
    case "EUR":
      return "€";
    case "ZAR":
      return "R";
    case "USD":
      return "$";
    case "RWF":
      return "RF";
    case "XOF":
      return "CFA";
    case "NGN":
      return "₦";
    case "GHS":
      return "GH¢";
    case "KES":
      return "K";
  }
};

export function isPasswordValid(password: string): boolean {
  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return PASSWORD_REGEX.test(password);
}
export function hasLowerCaseLetter(password: string): boolean {
  const PASSWORD_REGEX = /[a-z]+/;
  return PASSWORD_REGEX.test(password);
}
export function hasUpperCaseLetter(password: string): boolean {
  const PASSWORD_REGEX = /[A-Z]+/;
  return PASSWORD_REGEX.test(password);
}
export function hasNumber(password: string): boolean {
  const PASSWORD_REGEX = /\d+/;
  return PASSWORD_REGEX.test(password);
}
export function hasLengthEight(password: string): boolean {
  const PASSWORD_REGEX = /[a-zA-Z\d]{8,}$/;
  return PASSWORD_REGEX.test(password);
}

export function isEmailValid(value: string): boolean {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  );
}

export function convertTimeStamp(timestamp: any) {
  const firebaseTime = new Date(
    timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
  );
  const date = firebaseTime.toDateString();
  // const atTime = firebaseTime.toLocaleTimeString();
  return date;
}

export const handleInviteRedirect = (timer?: number) => {
  const inviteUrl = getStorageValue("inviteUrl");
  if (inviteUrl) {
    if (timer) {
      window.setTimeout(() => {
        window.location.replace(inviteUrl);
      }, timer);
    } else {
      window.location.replace(inviteUrl);
    }
  }
};

export function convertDateTime(dateTimeStr: string) {
  if (!dateTimeStr) return { date: "", time: "" };
  const date = new Date(dateTimeStr);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const ampm = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12;

  const formattedDate = `${dayOfWeek}. ${day} ${month} ${year}`;
  const formattedTime = `${hour12}:${minute
    .toString()
    .padStart(2, "0")} ${ampm}`;
  const realDate = {
    date: formattedDate,
    time: formattedTime,
  };
  return realDate;
}

function getOrdinalSuffix(num: number): "th" | "st" | "nd" | "rd" {
  if (num > 3 && num < 21) return "th";
  switch (num % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function formatDate(inputDateStr: string): string {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateObj = new Date(inputDateStr);

  const day = dateObj.getUTCDate();
  const month = months[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();

  return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
}

export const getUserButton = (
  userType: "lawyer" | "firm" | "corporation" | "private"
) => {
  if (["lawyer", "firm"].includes(userType)) return "Send Brief";
  return "Invite for Interview";
};

// Helper function to check if two dates are the same
export function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

// Helper function to convert a string date into a JavaScript Date object
export function toDate(dateString: string) {
  return new Date(dateString);
}

// Helper function to get a displayable string for the date
export function getDisplayDate(date: Date) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (isSameDay(date, today)) return "Today";
  if (isSameDay(date, yesterday)) return "Yesterday";

  // Return the date in DD/MM/YYYY format, modify to your preference
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}
