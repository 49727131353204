// import { socket } from "../../utils/socket";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Btn } from "../../components/Button";
const SocketTesting = () => {
  // const authSlice = useAppSelector((state) => state.auth);
  // const auth = authSlice?.userData?.user?._auth;
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   if (auth) {
      
  //     const socketInfo = initSocket(auth);
  //   console.log(socketInfo)
  //   }
  // }, []);

  return (
    <div>
      <Btn name="socket" onClick={() => console.log(2)} active />
    </div>
  );
};

export default SocketTesting;
