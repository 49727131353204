import { useEffect, useState } from "react";
import {
  Beriefs,
  InterviewLogo,
  Jobs,
  Lawploy,
  Message,
  NotificationIcon,
} from "../../assets/svgs/homesvg";
import { Link, useNavigate } from "react-router-dom";

import Hamburger from "hamburger-react";
import SideModal from "../Modal/SideModal";
import { Subtitle, TopicText } from "../Text";
import { SearchIcon } from "@heroicons/react/outline";
import { LGAs, states } from "../../utils/selectDatas";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { locationType } from "../../pages/RegTypes/LawyerReg";
import { searchLawyer } from "../../features/auth/LawyerSlice";
import CustomProfile, { profileData } from "./CustomProfile";
import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { ProfileChat } from "../../assets/svgs";
import noPic from "../../assets/png/no-pic.png";
import { resetAll } from "../../features/auth/AuthSlice";

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const lawyer = useAppSelector((state) => state.lawyer);
  const general = useAppSelector((state) => state.general);
  const searchResult = lawyer?.allSearchResult;

  const [state, setState] = useState(states[0]);
  const [locals, setLocals] = useState<locationType[]>([]);
  const [local, setLocal] = useState(
    locals?.[0] || {
      name: "",
    }
  );

  const [industry, setIndustry] = useState<any>("");
  const [area, setArea] = useState<any>({});
  const [years, setYears] = useState<any>({});
  const [amount, setAmount] = useState("all");
  const filterYear = years?.name ? `&year=${years?.name}` : "";
  const filterState = state?.name ? `&state=${state?.name}` : "";
  const filterLga = local?.name ? `&lga=${local?.name}` : "";
  const filterArea = area?.name ? `&specialization=${area?.name}` : "";

  useEffect(() => {
    const localsForState = LGAs.filter(
      (lga) => lga.state_id === state.id
    ) as unknown as locationType[];
    setLocal(localsForState[0]);

    setLocals(localsForState);
  }, [state.id]);

  const searchTerms = `?country=Nigeria&keyword=${
    search && search
  }${filterYear}${filterState}${filterLga}${filterArea}`;
  const handleSearch = () => {
    dispatch(searchLawyer(searchTerms));
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
      //   if (isSearch !== true) {
      //     navigate("/search");
      //   }
    }
  };
  const handleLogout = () => {
    dispatch(resetAll());
    navigate("/login");
  };
  useEffect(() => {
    if (area || state || industry || years) {
      handleSearch();
    }
  }, [area, state, industry, years]);

  const user = useAppSelector((state) => state.auth);
  const userInfo = user?.userData?.user;
  const name =
    userInfo?.name || userInfo?.first_name + " " + userInfo?.last_name;
  const image = userInfo?.profile_image ? userInfo?.profile_image : noPic;
  return (
    <div className="block sm:hidden">
      <div className="px-8 py-4 w-full flex justify-between">
        <div className="w-2/12" onClick={() => navigate("/home")}>
          <Lawploy />
        </div>
        <div>
          <Hamburger toggled={isOpen} toggle={setIsOpen} />
        </div>
      </div>
      <SideModal open={isOpen} setOpen={setIsOpen}>
        <div className="flex flex-col relative h-screen py-4 pl-4">
          <div className="w-full hidden">
            <div className="border border-placeholder rounded-md h-[62px] flex items-center px-4 space-x-2">
              <SearchIcon />
              <input
                type="text"
                className="bg-inherit placeholder:text-placeholder text-lg flex-grow border-none outline-none "
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </div>
          </div>
          <Disclosure as="div" className="my-4">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between items-center w-full pr-4 py-4 text-sm font-medium text-left bg-transparent hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <div className="flex items-center space-x-2">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={image}
                      alt=""
                    />
                    <span className="text-placeholder text-lg ">{name}</span>
                  </div>
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5 text-placeholder" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-placeholder" />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel className=" ">
                  <div className="pl-4 space-y-4 my-2 ">
                    {profileData.map((data, index) => (
                      <Link
                        to={data.link}
                        className="text-customBlack text-lg hover:bg-slate-400 block"
                        key={index}
                      >
                        {data.name}
                      </Link>
                    ))}
                    <p
                      className="cursor-pointer text-customBlack"
                      onClick={handleLogout}
                    >
                      Logout
                    </p>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <div className="w-full  flex flex-col  space-y-12">
            <div
              className="flex  items-center space-x-2 cursor-pointer"
              onClick={() => navigate("/messages")}
            >
              <Message />
              <p className="text-placeholder">Message</p>
            </div>
            <div
              className="flex  items-center space-x-2"
              onClick={() => navigate("/profile?state=mybrief")}
            >
              <Beriefs />
              <p className="text-placeholder">Brief</p>
            </div>
            <div
              className="flex  items-center space-x-2"
              onClick={() => navigate("/job")}
            >
              <Jobs />
              <p className="text-placeholder">Jobs</p>
            </div>
            <div
              className="flex  items-center space-x-2"
              onClick={() => navigate("/notification")}
            >
              <NotificationIcon />
              <p className="text-placeholder">Notifications</p>
            </div>
            <div
              className="flex  items-center space-x-2 cursor-pointer"
              onClick={() => navigate("/interview")}
            >
              <InterviewLogo />
              <p className="text-placeholder">Interview</p>
            </div>
          </div>

          <div className="absolute bottom-8 left-0 right-0"></div>
        </div>
      </SideModal>
    </div>
  );
};

export default MobileNav;
