import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import CreateNewPassword from "./pages/auth/CreateNewPassword";
import OtpVerification from "./pages/auth/OtpVerfication";
import GeneralReg from "./pages/RegTypes/GeneralReg";
import CoporateReg from "./pages/RegTypes/CoporateReg";
import LawFirmReg from "./pages/RegTypes/LawFirmReg";
import LawyerReg from "./pages/RegTypes/LawyerReg";
import PriavteReg from "./pages/RegTypes/PrivateReg";
import DemoHome from "./pages/Home/DemoHome";
import CooporateHome from "./pages/Home/CooporateHome";
import PrivateHome from "./pages/Home/PrivateHome";
import FirmHome from "./pages/Home/FirmHome";
import LawyerHome from "./pages/Home/LawyerHome";
import LawyersProfile from "./pages/Lawyers/LawyersProfile";
import BriefProfilePage from "./pages/Lawyers/BriefProfilePage";
import EmploymentProfilePage from "./pages/Lawyers/EmploymentProfilePage";
import Profile from "./pages/Profile";
import SearchLawyer from "./pages/Search/SearchLawyer";
import InterviewsPage from "./pages/Interview/InterviewsPage";
import Chat from "./pages/Chat";
import MessagePage from "./pages/Message/MessagePage";
import Notification from "./pages/Notification/NotificationPage";
import JobPage from "./pages/Job/JobPage";
const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="new-password" element={<CreateNewPassword />} />
        <Route path="otp" element={<OtpVerification />} />
        <Route path="/register/general" element={<GeneralReg />} />
        <Route path="/register/private" element={<PriavteReg />} />
        <Route path="/register/lawyer" element={<LawyerReg />} />
        <Route path="/register/firm" element={<LawFirmReg />} />
        <Route path="/register/coporate" element={<CoporateReg />} />
        <Route path="/home" element={<DemoHome />} />
        <Route path="/home/lawyer" element={<LawyerHome />} />
        <Route path="/home/corporate" element={<CooporateHome />} />
        <Route path="/home/firm" element={<FirmHome />} />
        <Route path="/home/private" element={<PrivateHome />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/lawyer" element={<LawyersProfile />} />
        <Route path="/profile/brief/:id" element={<BriefProfilePage />} />
        <Route path="/profile/employment/:id" element={<BriefProfilePage />} />
        <Route path="/search" element={<SearchLawyer />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/interview" element={<InterviewsPage />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/job" element={<JobPage />} />
        <Route path="/messages" element={<MessagePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
