const ArrowUp = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/arrow-up">
        <g id="arrow-up">
          <path
            id="Vector"
            d="M20.0065 16.287L20.0065 9.13342L12.8529 9.13342"
            stroke={fill ?? "#E99D2B"}
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M9.98908 19.1508L19.9062 9.23364"
            stroke={fill ?? "#E99D2B"}
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowUp;
