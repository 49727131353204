import React, { useState, useEffect } from "react";
import LoginBox from "../../components/LoginBox";
import LoginContainer from "../../Layouts/LoginContainers";
import { HeaderText, Subtitle } from "../../components/Text";

import { useNavigate } from "react-router-dom";
const GeneralReg = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [active, setActive] = useState(false);
  useEffect(() => {
    selected ? setActive(true) : setActive(false);
  }, [selected]);

  return (
    <LoginContainer>
      <div className="md:w-9/12 w-full mt-28 ">
        <LoginBox>
          <HeaderText text="Sign Up As" />
          <Subtitle text="Choose your preferred mode of sign up" />
          <div className="flex flex-col border border-border rounded-[15px] p-6 mt-10 bg-white">
            {/* start */}
            <div
              className="relative flex items-start pb-4 border-b border-border cursor-pointer"
              onClick={() => setSelected("/register/lawyer")}
            >
              <div className="min-w-0 flex-1 text-sm">
                <label className="font-[500] text-base  text-[#0e0e0e]  select-none">
                  Lawyer
                </label>
              </div>
              <div className="ml-3 flex items-center h-5">
                <input
                  name="plan"
                  type="radio"
                  checked={selected === "/register/lawyer" ? true : false}
                  className=" h-6 w-6 bg-[#041C40] text-[#041C40] border-none accent-[#041C40]"
                />
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div
              className="relative flex items-start py-4 border-b border-border cursor-pointer"
              onClick={() => setSelected("/register/firm")}
            >
              <div className="min-w-0 flex-1 text-sm">
                <label className="font-[500] text-base  text-[#0e0e0e]  select-none">
                  Law firm
                </label>
              </div>
              <div className="ml-3 flex items-center h-5">
                <input
                  name="plan"
                  type="radio"
                  checked={selected === "/register/firm" ? true : false}
                  className=" h-6 w-6 text-[#041C40] bg-[#041C40] border-none accent-[#041C40]"
                />
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div
              className="relative flex items-start py-4 border-b border-border cursor-pointer"
              onClick={() => setSelected("/register/coporate")}
            >
              <div className="min-w-0 flex-1 text-sm">
                <label className="font-[500] text-base  text-[#0e0e0e]  select-none">
                  Corporation
                </label>
              </div>
              <div className="ml-3 flex items-center h-5">
                <input
                  name="plan"
                  type="radio"
                  checked={selected === "/register/coporate" ? true : false}
                  className=" h-6 w-6 text-[#041C40] border-none accent-[#041C40] bg-[#041C40]"
                />
              </div>
            </div>
            {/* end */}
            {/* start */}
            <div
              className="relative flex items-start pt-4  cursor-pointer"
              onClick={() => setSelected("/register/private")}
            >
              <div className="min-w-0 flex-1 text-sm">
                <label className="font-[500] text-base  text-[#0e0e0e]  select-none">
                  Private individual
                </label>
              </div>
              <div className="ml-3 flex items-center h-5">
                <input
                  name="plan"
                  type="radio"
                  checked={selected === "/register/private" ? true : false}
                  className=" h-6 w-6 text-[#041C40] border-none accent-[#041C40] bg-[#041C40]"
                />
              </div>
            </div>
            {/* end */}
          </div>

          <button
            className={`mt-10 ${
              active ? "bg-primaryBlue" : "bg-primaryBlue opacity-40"
            } text-white w-full h-[52px] flex justify-center items-center font-Circular rounded-[20px] cursor-pointer hover:opacity-70`}
            disabled={!active}
            onClick={() => navigate(selected)}
          >
            Select
          </button>
        </LoginBox>
      </div>
    </LoginContainer>
  );
};

export default GeneralReg;
