import React, { useState, useEffect } from "react";
import LoginBox from "../../components/LoginBox";
import LoginContainer from "../../Layouts/LoginContainers";
import {
  HeaderText,
  Subtitle,
  ModalSub,
  ModalTitle,
} from "../../components/Text";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { NormalInput, PasswordInput } from "../../components/Input";
import { Btn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import { Brief, Message, Security } from "../../assets/svgs";
import { useNavigate } from "react-router-dom";
import { registerPrivate } from "../../features/auth/AuthSlice";
import { toast } from "react-toastify";
const PrivateReg = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  
  const [email, setEmail] = useState("");
  const [passsword, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const[phone,setPhone] = useState("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    if (email &&   firstName && middleName && lastName && phone) {
      setActive(true);
    } else setActive(false);
  }, [email,  firstName, middleName, lastName, phone]);
  

  const handleClick = () => {
       const data = {
         email: email,
         first_name: firstName,
         last_name: lastName,
         middle_name: middleName,
         phone_number: phone,
         phone_number_country_code: "+234",
       };
    dispatch(registerPrivate(data));
    
  }

   useEffect(() => {
     if (auth.privateDetails?.success) {
       toast.success(" Successful");
       navigate("/login");
     }
   }, [auth.privateDetails]);
  
  
  if (auth.loading) {
    return (
      <div className="bg-black w-full h-screen flex flex-col items-center justify-center  ">
        <iframe
          src="https://giphy.com/embed/3o7bu3XilJ5BOiSGic"
          width="100"
          height="100"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></iframe>
        <p>
          <a href="https://giphy.com/gifs/youtube-loading-gif-3o7bu3XilJ5BOiSGic">
            via GIPHY
          </a>
        </p>
        <p className="text-white text-xl ">Please wait....</p>
      </div>
    );
  }
  return (
    <LoginContainer>
      <div className="md:w-9/12 w-full mt-28 ">
        <LoginBox>
          <HeaderText text="Personal Details" />
          <Subtitle text="Fill in the details below" />
          <div className="w-full mt-10 flex flex-col space-y-4">
            <NormalInput
              onChange={(e) => setFirstName(e.target.value)}
              name="First Name"
              placeholder="First Name"
              value={firstName}
            />
            <NormalInput
              onChange={(e) => setMiddleName(e.target.value)}
              name="Middle Name"
              placeholder="Middle Name"
              value={middleName}
            />
            <NormalInput
              onChange={(e) => setLastName(e.target.value)}
              name="Last Name"
              placeholder="Last Name"
              value={lastName}
            />
            <NormalInput
              onChange={(e) => setEmail(e.target.value)}
              name="Email"
              placeholder="Email Address"
              value={email}
            />
         
          </div>
          <div className="flex items-center space-x-4 mt-4">
            <div className="border-border border rounded-md h-[60px] flex   items-center w-2/12 justify-center ">
              <p className="text-base text-customBlack  font-[500]">+234</p>
            </div>
            <div className="w-10/12">

            <NormalInput placeholder="Phone Number " onChange={(e : any) => setPhone(e.target.value)} name="phone" value={phone} />
            </div>
          </div>

          <button
            className={`mt-[47px] ${
              active ? "bg-primaryBlue" : "bg-primaryBlue opacity-40"
            } text-white w-full h-[52px] flex justify-center items-center font-Circular rounded-[20px] cursor-pointer hover:opacity-70`}
            disabled={!active}
            onClick={handleClick}
          >
            Next
          </button>
          <div className="h-16 w-full"></div>
        
        </LoginBox>
        <ReUseModal open={open} setOpen={setOpen}>
          <div className="w-full  flex flex-col justify-center">
            <div className="flex justify-center w-full">
              <Message />
            </div>
            <ModalTitle text="Check your email" className="text-center" />
            <ModalSub
              text="We’ve sent an OTP code to your email to reset your password"
              className="text-center mt-1"
            />
            <Btn
              className="mt-10"
              active={true}
              name="Continue"
              onClick={handleClick}
            />
          </div>
        </ReUseModal>
      </div>
    </LoginContainer>
  );
};

export default PrivateReg;
