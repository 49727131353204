import React from 'react'
import ContainerLayout from '../../Layouts/ContainerLayout'
import HomeNav from '../../components/Navbars/HomeNav'

const SearchLawyer = () => {
  return (
    <div className='bg-greyBg'>
      <HomeNav isSearch={true} />
    </div>
  )
}

export default SearchLawyer
