import React from "react";

interface loginBox {
  children: any;
}
const LoginBox = ({ children }: loginBox) => {
  return (
    <div className="w-full login-box flex flex-col min-h-[635px] md:max-h-[670px] h-full flow-hide">
      <div className="cap w-full" />
      <div className="px-12 pt-12 flow-hide md:max-h-[620px] h-full ">
        {children}
      </div>
    </div>
  );
};

export default LoginBox;
