import HomeNav from "../../components/Navbars/HomeNav";
import Employments from "../../components/HomeComponents/Employments";
import { Helmet } from "react-helmet";
import JobsSection from "../../components/HomeComponents/Jobs";
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  getAllActiveJob,
  getAllMyApplications,
} from "../../features/auth/JobSlice";
import {
  getAllLawyersCorporate,
  getAllMyBrief,
  sharedBrief,
} from "../../features/auth/LawyerSlice";
import { getAllNotifications } from "../../features/auth/GeneralSlice";
const CooporateHome = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllLawyersCorporate());
    dispatch(getAllActiveJob());
    dispatch(getAllMyBrief());
    dispatch(sharedBrief());
    dispatch(getAllMyApplications());
    dispatch(getAllNotifications());
  }, []);
  return (
    <div className="w-full flex flex-col">
      <Helmet>
        <title>COOPORATE</title>
      </Helmet>
      <HomeNav isSearch={false} />
      <div className="w-full bg-greyBg flex flex-col">
        <Employments />
        <JobsSection />
      </div>
    </div>
  );
};

export default CooporateHome;
