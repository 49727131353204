import React,{useEffect, useState} from 'react'
import { MdOutlineCalendarMonth, MdOutlineTimer } from "react-icons/md";
import samp from "../../assets/png/samp.png"
import SideModal from '../Modal/SideModal';
import { ModalSub, ModalTitle, Subtitle, TopicText } from '../Text';
import { Btn, OutlineBtn } from '../Button';
import pic from "../../assets/png/no-pic.png"
import { convertDateTime } from '../../utils';
import { manageInterview, resetAllJobState } from '../../features/auth/JobSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Message } from '../../assets/svgs';
import ReUseModal from '../Modal/ReUseModal';
const InterviewCard = ({item} : {item:any}) => {
  const [open, setOpen] = useState(false);
  const [present, setPresent] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch(); 
  const job = useAppSelector((state) => state.job);
  const {
    position_type,
    name,
    createdAt,
    image,
    description,
    responsibilities,
    website,
    _id
  } = item;
  const time = convertDateTime(createdAt).time;
  const date = convertDateTime(createdAt).date;
  const resp = responsibilities?.split(".");
  
  const handleAccept = () => {
    const opt = {
      id: _id,
      data: {
        state: "Accepted",
      },
    };
    dispatch(manageInterview(opt));

  }

  useEffect(() => {
    if (job.updateInterviewSuccess) {
      setOpenModal(true);
      setOpen(false);
      setTimeout(() => {
        setOpenModal(false);
        dispatch(resetAllJobState());
      }, 3000);
    }
  }, [job.updateInterviewSuccess])
  
  
  
    return (
      <>
        <div
          className="w-full space-x-4 items-center flex bg-white shadow-2xl p-4 rounded-[20px] cursor-pointer z-1 "
          onClick={() => setOpen(true)}
        >
          <img
            src={image ? image : pic}
            alt=""
            className="w-[50px] h-[50px] rounded-full"
          />
          <div className="flex flex-col space-y-2">
            <h1 className="text-lg font-bold ">{name}</h1>
            <p className="text-base text-lightText">{position_type}</p>
            <div className="flex flex-col space-y-2 rounded-[20px] bg-gray px-4 py-2">
              <div className="flex items-center">
                <MdOutlineCalendarMonth className="text-primaryBlue text-xl" />
                <p className="text-primaryBlue text-base">{date}</p>
              </div>
              <div className="flex items-center">
                <MdOutlineTimer className="text-primaryBlue text-xl" />
                <p className="text-primaryBlue text-base">{time}</p>
              </div>
            </div>
          </div>
        </div>
        {present === 1 && (
          <SideModal title="Interview Details" open={open} setOpen={setOpen}>
            <div className="flex flex-col relative h-screen">
              <div className="flex items-center space-x-4">
                <img
                  src={image ? image : pic}
                  alt=""
                  className="w-[98px] h-[98px] rounded-full"
                />
                <div className="flex flex-col">
                  <p className="text-lg font-bold text-customBlack ">{name}</p>
                  <p className="text-lightText text-base mt-0">
                    {position_type}
                  </p>
                </div>
              </div>
              <div className="flex flex-col mt-7">
                <TopicText text="About" className="mb-[10px]" />
                <Subtitle text={description} />
              </div>
              <div className="flex flex-col mt-7">
                <TopicText text="Job Responsibilities" className="mb-[10px]" />
                <ul className="flex flex-col list-disc px-6">
                  {resp?.map((item: any, index: number) => {
                    return (
                      <li className="text-lightText text-base" key={index}>
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="flex flex-col mt-7">
                <TopicText text="Date and Time of Interview" className="mb-3" />
                <Subtitle text={`${date}  ${time}`} />
              </div>
              {/* LIFE */}
              <div className="mt-7 flex flex-col">
                <TopicText text="Company’s Website" className="mb-3" />
                <a
                  href={`${website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-[#4E8BD2]"
                >
                  {website}
                </a>
                <Subtitle
                  text="Click accept if you’ll be available for the interview. Once you’ve accepted, you’ll be scheduled for a video call on the interview day."
                  className="mt-8"
                />
              </div>
              <div className="absolute bottom-8 left-0 right-0">
                <Btn
                  name={job.loading ? "Loading...": "Accept Interview Invitation"}
                  onClick={handleAccept}
                  active={true}
                  
                />
              </div>
            </div>
          </SideModal>
        )}
        <ReUseModal open={openModal} setOpen={setOpenModal}>
          <div className="w-full  flex flex-col justify-center">
            <div className="flex justify-center w-full">
              <Message />
            </div>
            <ModalTitle
              text="Interview Invitation Accepted"
              className="text-center"
            />
            <ModalSub
              text="You’ve successfully accepted the interview. You’ll be notified a day before the interview."
              className="text-center mt-1"
            />
            <Btn
              className="mt-10"
              active={true}
              name="Continue"
              onClick={() => setOpenModal(false)}
            />
          </div>
        </ReUseModal>
      </>
    );
}

export default InterviewCard