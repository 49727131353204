/** this would wrap all the api endpoints and base urls */
import axios from "axios";
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const url = {
  login: "auth/login",
  register: "auth/register",
  lawyerOnboard: "lawyer/onboarding",
  firmOnboard: "firm/onboarding",
  coporateOnboard: "corporation/onboarding",
  privateOnboard: "private/onboarding",
  otpVerification: "/auth/verify/otp",
  otpVerificationPassword: "auth/verify/password",
  resendOtp: "auth/resend/signup/otp",
  forgotPassword: "auth/forgot-password",
  resetPassword: "auth/reset-password",
  onBoardingCoopate: "corporation/onboarding",
  allLawyers: "lawyer",
  allLawyersFirm: "firm",
  singleLawyersFirm: "firm/read",
  allLawyersCoop: "corporation",
  singleLawyersCoop: "corporation/read",
  allLawyersPrivate: "private",
  singleLawyersPrivate: "private/read",
  singleLawyer: "lawyer/read",
  myBrief: "brief",
  singleBrief: "brief/read",
  deleteBrief: "brief",
  sharedBrief: "brief/shared",
  searchLawyer: "search/lawyer",
  // JOB URL 🌦 BELOW
  createJob: "jobs/create",
  getJobsList: "jobs",
  myApplications: "jobs/applications",
  getSingleJob: "jobs/read",
  myJobsList: "jobs/manage",
  getSingleApplication: "jobs/application",
  applyJob: "jobs/apply",
  editLawyer: "lawyer/profile/update",
  editCorporate: "corporation/profile/update",
  editFirm: "firm/profile/update",
  editPrivate: "private/profile/update",
  // notifications session
  allNotifications: "notifications",
  sendInterview: "interview/send",
  allSentInterview: "interview/sent",
  allRecievedInterview: "interview/received",
  sendFeeeback: "interview/feedback",
};


