import React from 'react'
import { Helmet } from 'react-helmet'

import HomeNav from '../../components/Navbars/HomeNav'
import LawyerSingleProfile from '../../components/ProfileSection/LawyerSingleProfile'
const LawyersProfile = () => {
  return (
    <>
      <div className="w-full flex flex-col">
        <Helmet>
          <title>Lawyer</title>
        </Helmet>
        <HomeNav isSearch={false} />
        <div className="w-full bg-greyBg flex flex-col z-1">
          <LawyerSingleProfile />
        </div>
      </div>
    </>
  );
}

export default LawyersProfile