import { useState } from "react";
import useImage from "../../assets/png/club-t.png";
import SideModal from "../Modal/SideModal";
import { Btn } from "../Button";
import { useNavigate } from "react-router-dom";
import { TopicText, Subtitle, ModalSub, ModalTitle } from "../Text";
import { BriefCase } from "../../assets/svgs";
import ReUseModal from "../Modal/ReUseModal";
import { getUserButton } from "../../utils";
import { useAppSelector } from "../../app/hooks";
import { useMediaQuery } from "react-responsive";

interface briefBox {
  name: string;
  sector: string;
  price?: string;
  desc: string;
  rate?: string;
  image: any;
  year?: any;
  id: any;
  location?: string;
}
const EmploymentCard = ({
  name,
  sector,
  desc,
  image,
  year,
  id,
  location,
}: briefBox) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const userData = useAppSelector((state) => state.auth);
  const userType = userData.userData.user._type;
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

  return (
    <>
      <div
        className="w-full login-box flex flex-col  h-full flow-hide brief-card-bg "
        onClick={() => navigate(`/profile/employment/${id}`)}
      >
        <div className="border-t-[30px] border-[#CFCFCF] rounded-t-[10px]"></div>

        <div className="md:px-4 px-3 md:pt-4 mt-[-20px] md:mt-0 z-10 flow-hide h-full flex flex-col md:space-y-4 space-y-2">
          <img
            src={image}
            alt="name"
            className="md:w-[90px] md:h-[90px] w-[70px] h-[70px]  rounded-full mx-auto object-cover"
          />
          <p className="text-[#0e0e0e] md:text-lg text-base font-bold  text-center mt-2 ">
            {name}
          </p>
          <p className="text-[#5E5E5E] md:text-base text-sm  text-center mt-1 ">
            {location}
          </p>
          <p className="text-[#5E5E5E] md:text-base text-sm  text-center mt-1 ">
            {" "}
            Year of Call: {year}
          </p>

          {isDesktopOrLaptop && (
            <p className="line-clamp-3 md:text-base text-sm text-justify text-[#5e5e5e]">
              {desc ? desc : "No Bio Yet"}
            </p>
          )}
        </div>
        <div className="w-full my-4 mb:my-8">
          <div className="w-4/5 px-6 md:py-3 py-2 rounded-[10px] border border-sky-950 justify-center items-center gap-2 mx-auto flex ">
            <div className="text-sky-950 text-sm font-medium font-['Circular Std'] tracking-tight">
              Employ
            </div>
          </div>
        </div>
      </div>
      <SideModal title="Interview Details" open={open} setOpen={setOpen}>
        <div className="flex flex-col relative h-screen">
          <div className="flex items-center space-x-4">
            <img
              src={useImage}
              alt=""
              className="w-[98px] h-auto rounded-full"
            />
            <div className="flex flex-col">
              <p className="text-lg font-bold text-customBlack ">
                Quantum Incorporation
              </p>
              <p className="text-lightText md:text-base text-sm mt-0">
                Part Time
              </p>
            </div>
          </div>
          <div className="flex flex-col mt-7">
            <TopicText text="About" className="mb-[10px]" />
            <Subtitle text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo, hendrerit sapien ut enim semper pellue. Pellentesque facilisi ligula mattis arcu. Dictumst adicing sit rhoncus quisque senectus ut mi, nulla purus. Arcu venenatis i it proin sit tristique massa." />
          </div>
          <div className="flex flex-col mt-7">
            <TopicText text="Job Responsibilities" className="mb-[10px]" />
            <ul className="flex flex-col list-disc px-6">
              <li className="text-lightText text-base">
                Lorem ipsum dolor sit amet consectetur.
              </li>
              <li className="text-lightText text-base">
                Lorem ipsum dolor sit amet consectetur.
              </li>
              <li className="text-lightText text-base">
                Lorem ipsum dolor sit amet consectetur.
              </li>
            </ul>
          </div>
          <div className="mt-7 flex flex-col">
            <TopicText text="Company’s Website" className="mb-3" />
            <a
              href="http://"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-[#4E8BD2]"
            >
              www.quantuminc.com
            </a>
          </div>
          <div className="absolute bottom-8 left-0 right-0">
            <Btn
              name="Apply"
              onClick={() => setOpenModal(true)}
              active={true}
            />
          </div>
        </div>
      </SideModal>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full  flex flex-col justify-center">
          <div className="flex justify-center w-full">
            <BriefCase />
          </div>
          <ModalTitle text="Check your email" className="text-center" />
          <ModalSub
            text="We’ve sent an OTP code to your email to reset your password"
            className="text-center mt-1"
          />
          <Btn
            className="mt-10"
            active={true}
            name="Continue"
            onClick={() => {
              setOpenModal(false);
              setOpen(false);
            }}
          />
        </div>
      </ReUseModal>
    </>
  );
};

export default EmploymentCard;
