import React, { useState, useEffect } from "react";
import LoginContainer from "../../Layouts/LoginContainers";
import { BigText } from "../../components/Text";
import { NormalInput, PasswordInput } from "../../components/Input";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  loginUser,
  resetAll,
  restoreDefault,
} from "../../features/auth/AuthSlice";
import { Helmet } from "react-helmet";
const Login = () => {
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("Password");
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(resetAll());
  }, []);

  useEffect(() => {
    if (email && password) {
      setActive(true);
    } else setActive(false);
  }, [email, password]);
  useEffect(() => {
    if (auth.userData?.success) {
      // window.alert("Login successful");

      // navigate("/home");
      if (!auth.userData?.user?.account_verify)
        navigate(`/otp?email=${auth.userData.user?.email}`);
      else if (!auth.userData?.user?.account_setup_completed)
        navigate("/register/general");
      else navigate("/home");

      dispatch(restoreDefault());
    }
  }, [auth.loginSuccess]);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // prevent default form submission
    if (active) {
      const data = {
        email: email,
        password: password,
      };

      dispatch(loginUser(data));
    }
  };

  // if (auth.loading) {
  //   return (
  //     <div className="bg-black w-full h-screen flex flex-col items-center justify-center  ">
  //       <iframe
  //         src="https://giphy.com/embed/3o7bu3XilJ5BOiSGic"
  //         width="100"
  //         height="100"
  //         frameBorder="0"
  //         className="giphy-embed"
  //         allowFullScreen
  //       ></iframe>
  //       <p>
  //         <a href="https://giphy.com/gifs/youtube-loading-gif-3o7bu3XilJ5BOiSGic">
  //           via GIPHY
  //         </a>
  //       </p>
  //       <p className="text-white text-xl ">Please wait....</p>
  //     </div>
  //   );
  // }

  return (
    <div>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <LoginContainer>
        <div className="md:mt-32 mt-10 w-full">
          <BigText text="Welcome to our professional Community" />
          <div className="flex md:w-7/12 w-full flex-col  mt-10">
            <form onSubmit={handleSubmit}>
              <NormalInput
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
                classes="mb-[20px]"
                name="Email"
                value={email}
              />
              <PasswordInput
                placeholder="Password"
                name="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <div className="w-full flex justify-end">
                <Link
                  to="/forgot-password"
                  className="text-yellow text-sm font-bold mt-3"
                >
                  Forgot password
                </Link>
              </div>
              <button
                type="submit"
                className={`mt-[47px] ${
                  active ? "bg-primaryBlue" : "bg-primaryBlue opacity-40"
                } text-white w-full h-[52px] flex justify-center items-center font-Circular rounded-[10px] cursor-pointer hover:opacity-70`}
                // disabled={!active}
              >
                {auth.loading ? "loading" : "Log In"}
              </button>
              <div className="flex w-full items-center space-x-1 my-8">
                <div className="h-[1.5px] flex-grow w-full bg-line mt-1"></div>
                <p className="text-line font-Circular font-normal text-base">
                  or
                </p>
                <div className="h-[1.5px] flex-grow bg-line w-full mt-1"></div>
              </div>
              <button
                className={` border border-primaryBlue  text-primaryBlue w-full h-[52px] flex justify-center items-center hover:bg-primaryBlue hover:text-white font-Circular rounded-[10px] cursor-pointer`}
                onClick={() => navigate("/register")}
              >
                Create Account
              </button>
            </form>
          </div>
        </div>
      </LoginContainer>
    </div>
  );
};

export default Login;
