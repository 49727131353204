import React, { useState, useEffect } from "react";
import LoginContainer from "../../Layouts/LoginContainers";
import {
  BigText,
  HeaderText,
  ModalSub,
  ModalTitle,
  Subtitle,
} from "../../components/Text";
import OtpInput from "react-otp-input";
import { NormalInput, PasswordInput } from "../../components/Input";
import { Link, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LoginBox from "../../components/LoginBox";
import { Btn } from "../../components/Button";
import { Security } from "../../assets/svgs";
import ReUseModal from "../../components/Modal/ReUseModal";
import { useNavigate } from "react-router-dom";
import { otpVerfication, resetAll } from "../../features/auth/AuthSlice";
const OtpVerification = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [otp, setOtp] = useState("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetAll());
  }, []);
  useEffect(() => {
    if (otp) {
      setActive(true);
    } else setActive(false);
  }, [otp]);

  const handleSubmit = () => {
    const data = {
      email: email,
      otp: otp,
    };

    dispatch(otpVerfication(data));
  };
  useEffect(() => {
    if (auth?.otpVerificationSuccess) {
      setOpen(true);
    }
  }, [auth.otpVerificationSuccess]);
  if (auth.loading) {
    return (
      <div className="bg-black w-full h-screen flex flex-col items-center justify-center  ">
        <iframe
          src="https://giphy.com/embed/3o7bu3XilJ5BOiSGic"
          width="100"
          height="100"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></iframe>
        <p>
          <a href="https://giphy.com/gifs/youtube-loading-gif-3o7bu3XilJ5BOiSGic">
            via GIPHY
          </a>
        </p>
        <p className="text-white text-xl ">Please wait....</p>
      </div>
    );
  }

  return (
    <div>
      <LoginContainer>
        <div className="md:w-9/12 w-full mt-28">
          <LoginBox>
            <HeaderText text="OTP Verification" />
            <Subtitle text="Enter the 6 digit OTP code sent to your email" />
            <div className="flex w-full flex-col mt-10   ">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                containerStyle="w-8/12 mx-auto flex justify-between "
                renderInput={(props) => <input {...props} />}
                inputStyle="w-full min-w-[49px] min-h-[44px] rounded-[10px] border border-[#cfcfcf] "
              />
              <Btn
                name="Next"
                active={active}
                onClick={handleSubmit}
                className="mt-[140px]"
              />
            </div>
          </LoginBox>
          <ReUseModal open={open} setOpen={setOpen}>
            <div className="w-full  flex flex-col justify-center">
              <div className="flex justify-center w-full">
                <Security />
              </div>
              <ModalTitle
                text="Verification Successful "
                className="text-center"
              />
              <ModalSub
                text="Your email has been successfully verified."
                className="text-center mt-1"
              />
              <Btn
                className="mt-10"
                active={true}
                name="Continue"
                onClick={() => navigate("/register/general")}
              />
            </div>
          </ReUseModal>
        </div>
      </LoginContainer>
    </div>
  );
};

export default OtpVerification;
