import ContainerLayout from "../../Layouts/ContainerLayout";
import { useAppSelector } from "../../app/hooks";
import EmploymentCard from "../HomeComponents/EmploymentCard";
import SingleUser from "./SingleUser";
import pic from "../../assets/png/profile.jpg";
import { convertDateTime } from "../../utils";
import React, { useState } from "react";
import { NotificationIconNew } from "../../assets/svgs/homesvg";
import axios from "axios";
const JobHome = () => {
  const lawyers = useAppSelector((state) => state.lawyer);
  const job = useAppSelector((state) => state.job);

  let allLawyers = lawyers?.allLawyers ? lawyers?.allLawyers : [];
  let allSent = job?.allSentInterview ? job?.allSentInterview : [];
  const tabs = [
    { name: "Interviews", id: 1 },
    { name: "Feedbacks", id: 2 },
    { name: "Reference", id: 3 },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <ContainerLayout>
      <div className="w-full flex flex-col md:flex-row">
        <div className="md:w-1/2 w-full h-full md:min-h-[70vh] flow-hide md:border-r md:border-r-border flex flex-col ">
          <div className="border-b-[1px] border-[#CFCFCF] w-full">
            <h1 className="mt-4 text-primaryBlue text-[24px] font-bold tracking-[0.48px] mb-5">
              Job
            </h1>
            <div className="flex space-x-8">
              {tabs.map((tab, id) => (
                <div
                  key={id}
                  className={`${
                    selectedTab.name === tab.name
                      ? "border-b-2 border-[#041C40]"
                      : ""
                  }`}
                  onClick={() => setSelectedTab(tab)}
                >
                  <p
                    className={`text-base pb-3 ${
                      selectedTab.name === tab.name
                        ? "text-[#0E0E0E] font-bold"
                        : "font-medium text-[#5E5E5E]"
                    }`}
                  >
                    {tab.name}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-4 pr-4 mt-5 h-[calc(100vh-250px)] overflow-auto cursor-pointer">
            {selectedTab.name === "Interviews" ? (
              <InterViewTab />
            ) : selectedTab.name === "Feedbacks" ? (
              <FeedBackTab />
            ) : (
              <ReferenceTab />
            )}
          </div>
        </div>
        <div className="md:w-1/2 w-full flex flex-col md:pl-8 mt-8 md:mt-0 flow-hide h-[70vh] cursor-pointer">
          <h1 className="font-bold text-primaryBlue text-2xl">Employment</h1>
          <div className="w-full grid grid-cols-2 md:grid-cols-2 gap-x-8 gap-y-[50px] my-6 ">
            {allLawyers?.slice(0, 8)?.map((item: any, index: number) => {
              const {
                area,
                country,
                first_name,
                last_name,
                profile_image,
                year_of_call,
                _id,
                bio,
                state,
              } = item;
              return (
                <EmploymentCard
                  key={index}
                  name={first_name + " " + last_name}
                  sector={area}
                  price={"0"}
                  desc={bio}
                  rate={""}
                  location={
                    country && state ? state + " ," + " " + country : ""
                  }
                  image={profile_image ? profile_image : pic}
                  year={year_of_call}
                  id={_id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ContainerLayout>
  );
};
interface JobApplicationType {
  _id: string;
  _job: {
    _id: string;
    name: string;
    job_title: string;
    description: string;
    responsibilities: string;
    position_type: string;
    _creator: string;
    _type: string;
    opened: number;
    website: string;
  };
  _user: string;
  state: string;
  file: string;
  feedback_state: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
const InterViewTab = () => {
  const { token } = useAppSelector((state) => state.auth);
  const [jobs, setJobs] = React.useState<JobApplicationType[]>([]);

  React.useEffect(() => {
    if (token)
      (async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/jobs/applications`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.data) setJobs(data.data);
      })();
  }, [token]);

  return (
    <>
      <p className="text-[#0E0E0E]">
        View your pending and scheduled interviews here
      </p>

      {jobs.map((job, id) => (
        <InterViewCard id={id} job={job} />
      ))}
      {!jobs.length && <div>No Job found</div>}
    </>
  );
};

const FeedBackTab = () => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 67, 8, 9, 99, 78, 56, 43, 21].map((m, id) => (
        <FeedBackCard id={id} />
      ))}
    </>
  );
};

const ReferenceTab = () => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 67, 8, 9, 99, 78, 56, 43, 21].map((m, id) => (
        <ReferenceCard id={id} />
      ))}
    </>
  );
};
const InterViewCard = ({
  id,
  job,
}: {
  id: number;
  job: JobApplicationType;
}) => {
  return (
    <div
      key={id}
      className={`flex items-center  justify-between w-full border-[#CFCFCF] ${
        id > 0 ? "border-t-[1px] pt-3" : ""
      }`}
    >
      <div className="flex items-center space-x-5">
        <img src={pic} className="w-[42px] h-[42px] rounded-full" alt="" />
        <div className="space-y-2">
          <p className="text-[#041C40] text-[20px] flex space-x-4 items-center">
            <span>Quantum Incorporation</span>
            <span className="text-[#5E5E5E] text-sm font-normal">
              (Full-Time Role)
            </span>
          </p>
          <p className="text-[#5E5E5E] flex items-center space-x-2">
            <span>15th Mar, 2023</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#D3A518" />
            </svg>
            <span>10:00 AM</span>
          </p>
        </div>
      </div>
      <div>
        <div className="text-[#D3A518] border-b-[1px] border-[#D3A518]">
          View Details
        </div>
      </div>
    </div>
  );
};

const FeedBackCard = ({ id }: { id: number }) => {
  return (
    <div
      key={id}
      className={`flex items-center  justify-between w-full border-[#CFCFCF] ${
        id > 0 ? "border-t-[1px] pt-3" : ""
      }`}
    >
      <div className="flex items-center space-x-5">
        <img src={pic} className="w-[42px] h-[42px] rounded-full" alt="" />
        <div className="space-y-2">
          <p className="text-[#041C40] text-[20px] flex space-x-4 items-center">
            <span>Quantum Incorporation</span>
          </p>
        </div>
      </div>
      <div>
        <div className="text-[#D3A518] border-b-[1px] border-[#D3A518]">
          View Feedback
        </div>
      </div>
    </div>
  );
};

const ReferenceCard = ({ id }: { id: number }) => {
  return (
    <div
      key={id}
      className={`flex items-center  justify-between w-full border-[#CFCFCF] ${
        id > 0 ? "border-t-[1px] pt-3" : ""
      }`}
    >
      <div className="flex items-center space-x-5">
        <img src={pic} className="w-[42px] h-[42px] rounded-full" alt="" />
        <div className="space-y-2">
          <p className="text-[#041C40] text-[20px] flex space-x-4 items-center">
            <span>Quantum Incorporation</span>
          </p>
        </div>
      </div>
      <div>
        <div className="text-[#D3A518] border-b-[1px] border-[#D3A518]">
          View Reference
        </div>
      </div>
    </div>
  );
};

export default JobHome;
