import React from "react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import BriefCard from "./BriefCard";
import { BriefData, EmploymentData } from "../../utils/datas";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import EmploymentCard from "./EmploymentCard";
import pic from "../../assets/png/profile.jpg";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
const SearchEmployments = ({ number }: any) => {
  const lawyers = useAppSelector((state) => state.lawyer);

  const allLawyers = lawyers?.allSearchResult ? lawyers?.allSearchResult : [];
  return (
    <ContainerLayout>
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          {allLawyers?.length !== 0 ? (
                      <p className="text-lg  text-neutral-500"> {`${allLawyers?.length} lawyers available` }</p>
          ) : (
            <p className="text-2xl font-bold text-blueText"> No Match Found</p>
          )}
        </div>
        <div className="w-full grid grid-cols-4 gap-x-8 gap-y-[50px] my-6">
          {allLawyers?.length !== 0 && allLawyers?.map((item: any, index: number) => {
            const {
              area,
              country,
              first_name,
              last_name,
              profile_image,
              year_of_call,
              _id,
              state,
              bio,
            } = item;
            return (
              <EmploymentCard
                key={index}
                name={first_name + " " + last_name}
                sector={area}
                price={"0"}
                location={country && state ? state + " ," + " " + country : ""}
                desc={bio}
                rate={""}
                image={profile_image ? profile_image : pic}
                year={year_of_call}
                id={_id}
              />
            );
          })}
        </div>
      </div>
    </ContainerLayout>
  );
};

export default SearchEmployments;
