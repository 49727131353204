import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";

export interface AuthState {
  loading: boolean;
  token: string;
  data: any;
  allClubs?: any;
  clubsLoading: boolean;
  error: any;
  userData: any;
  registerSuccess: boolean;
  loginSuccess: boolean;
  otpSuccess: boolean;
  lawyersDetails: any;
  corporateDetails: any;
  firmDetails: any;
  privateDetails: any;
  registerEmail: string;
  success: boolean;
  otpVerificationData: any;
  otpVerificationSuccess: boolean;
  otpVerificationPasswordData: any;
  otpVerificationPasswordSuccess: boolean;
  forgotPasswordData: any;
  forgotPasswordSuccess: boolean;
  resetPasswordData: any;
  resetPasswordSuccess: boolean;
  onBoardingData: any;
  onBoardingSuccess: boolean;
  resendOtpSuccess: boolean;
  resendOtpData: any;
  finalToken: string;
  otpToken: "";
  socketData: any;
}

const initialState: AuthState = {
  loading: false,
  token: "",
  data: [],
  allClubs: [],
  clubsLoading: false,
  error: {},
  userData: [],
  registerSuccess: false,
  otpSuccess: false,
  registerEmail: "",
  success: false,
  otpVerificationData: [],
  otpVerificationPasswordData: [],
  forgotPasswordData: [],
  resetPasswordData: [],
  onBoardingData: [],
  otpVerificationSuccess: false,
  resetPasswordSuccess: false,
  onBoardingSuccess: false,
  forgotPasswordSuccess: false,
  otpVerificationPasswordSuccess: false,
  resendOtpSuccess: false,
  resendOtpData: [],
  loginSuccess: false,
  lawyersDetails: [],
  corporateDetails: [],
  firmDetails: [],
  privateDetails: [],
  finalToken: "",
  otpToken: "",
  socketData: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.registerSuccess = false;
      state.clubsLoading = false;
      state.loginSuccess = false;
    },
    resetAll: (state) => {
      state.loading = false;
      state.registerSuccess = false;
      state.clubsLoading = false;
      state.userData = [];
      // state.userData = [];
      state.token = "";
      state.registerSuccess = false;
      state.loginSuccess = false;
      state.registerEmail = "";
    },

    saveSocket: (state, action: PayloadAction<unknown>) => {
      state.socketData = action.payload;
    },
    saveEmail: (state, action: PayloadAction<string>) => {
      state.registerEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.registerSuccess = true;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.registerSuccess = false;
      })
      // start off
      .addCase(registerLawyers.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerLawyers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.lawyersDetails = payload;
        state.onBoardingSuccess = true;
        state.finalToken = payload?.token;
      })
      .addCase(registerLawyers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.onBoardingSuccess = false;
      })
      // ####################################
      // start off
      .addCase(registerFirms.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerFirms.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.firmDetails = payload;
        state.onBoardingSuccess = true;
        state.finalToken = payload?.token;
      })
      .addCase(registerFirms.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.onBoardingSuccess = false;
      })
      // ####################################
      // start off
      .addCase(registerCorporate.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerCorporate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.corporateDetails = payload;
        state.onBoardingSuccess = true;
        state.finalToken = payload?.token;
      })
      .addCase(registerCorporate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.onBoardingSuccess = false;
      })
      // ####################################
      // start off
      .addCase(registerPrivate.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerPrivate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.privateDetails = payload;
        state.onBoardingSuccess = true;
        state.finalToken = payload?.token;
      })
      .addCase(registerPrivate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.onBoardingSuccess = false;
      })
      // ####################################
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userData = payload;
        state.token = payload.token;
        state.loginSuccess = true;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.loginSuccess = false;
      })
      // end of session
      .addCase(otpVerfication.pending, (state) => {
        state.loading = true;
      })
      .addCase(otpVerfication.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.otpVerificationData = payload;
        state.otpVerificationSuccess = true;
        state.token = payload?.token;
        state.otpToken = payload?.token;
      })
      .addCase(otpVerfication.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.otpVerificationSuccess = false;
        state.otpToken = "";
      })
      // end of session
      // end of session
      .addCase(resendOtpVerfication.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendOtpVerfication.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.resendOtpData = payload;
        state.resendOtpSuccess = true;
      })
      .addCase(resendOtpVerfication.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.resendOtpSuccess = false;
      })
      // end of session
      // end of session
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.forgotPasswordData = payload;
        state.forgotPasswordSuccess = true;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.forgotPasswordSuccess = false;
      })
      // end of session
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.resetPasswordData = payload;
        state.resetPasswordSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.resetPasswordSuccess = false;
      });
    // end of session
  },
});

export const registerUser = createAsyncThunk(
  "registerUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.register}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.otpVerification}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const loginUser = createAsyncThunk(
  "loginUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.login}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const otpVerfication = createAsyncThunk(
  "otpVerfication",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.otpVerification}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const resendOtpVerfication = createAsyncThunk(
  "resendOtpVerfication",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.resendOtp}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.post(`${url.forgotPassword}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await APIService.put(`${url.resetPassword}`, payload);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        getSimplifiedError(error.response ? error : error)
      );
    }
  }
);

export const registerLawyers = createAsyncThunk(
  "register/lawyer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.lawyerOnboard}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const socketConnection = createAsyncThunk(
  "register/lawyer",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.lawyerOnboard}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const registerFirms = createAsyncThunk(
  "register/firm",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.firmOnboard}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const registerCorporate = createAsyncThunk(
  "register/cooprate",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(
        `${url.coporateOnboard}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const registerPrivate = createAsyncThunk(
  "register/private",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      console.log(auth.token);
      const { data } = await APIService.post(`${url.privateOnboard}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const authSelector = (state: any) => state.auth;

export const { restoreDefault, resetAll, saveEmail, saveSocket } =
  authSlice.actions;
export default authSlice.reducer;
