import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import { Btn } from "../../Button";
import { NormalInput } from "../../Input";

import { toast } from "react-toastify";
import { restoreDefault } from "../../../features/auth/PrivateSlice";
import { editPrivate } from "../../../features/auth/PrivateSlice";
const PrivateEditProfile = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const privateprofile = useAppSelector((state) => state.private);
  const { userData } = auth;
  const item = userData?.user;
  const {
    first_name,
    last_name,

    middle_name,
  } = item;

  const [firstName, setFirstName] = useState(first_name ? first_name : "");
  const [middleName, setMiddleName] = useState(middle_name ? middle_name : "");
  const [lastName, setLastName] = useState(last_name ? last_name : "");
  const { phone_number } = item;
  const [email, setEmail] = useState(item?.email || "");

  const [phone, setPhone] = useState(phone_number);

  useEffect(() => {
    dispatch(restoreDefault());
  }, []);

  const handleUpdate = () => {
    const data = {
      last_name: lastName,
      first_name: firstName,
      middle_name: middleName,

      phone_number_country_code: "+234",
      phone_number: phone ? phone : phone_number,
    };

    dispatch(editPrivate(data));
  };
  useEffect(() => {
    if (privateprofile.editPrivateSuccess) {
      toast.success("Profile Update successful");
      setTimeout(() => {
        dispatch(restoreDefault());
      }, 500);
    }
  }, [privateprofile.editPrivateSuccess]);

  return (
    <div className="w-full flex flex-col bg-white rounded-[10px]">
      <div className="w-full px-4 2xl:px-6 pt-7 flex flex-col pb-1 border-b border-b-border">
        <h1 className="text-blueText text-2xl font-bold">Profile</h1>
      </div>
      <div className="w-11/12 grid grid-cols-2 gap-8 px-4 2xl:px-6 mt-4 pb-10">
        <NormalInput
          onChange={(e) => setFirstName(e.target.value)}
          name="First Name"
          placeholder="First Name"
          value={firstName}
        />
        <NormalInput
          onChange={(e) => setMiddleName(e.target.value)}
          name="Middle Name"
          placeholder="MiddleName"
          value={middleName}
        />

        <NormalInput
          onChange={(e) => setLastName(e.target.value)}
          name="Last name"
          placeholder="Last name"
          value={lastName}
        />
        <NormalInput
          onChange={(e) => setEmail(e.target.value)}
          name="Email"
          placeholder="Email Address"
          value={email}
        />
      </div>
      <div className="flex items-center space-x-4 pl-5  mb-4 w-1/2">
        <div className="border-border border rounded-md h-[60px] flex   items-center w-2/12 justify-center ">
          <p className="text-base text-customBlack  font-[500]">+234</p>
        </div>
        <div className="w-10/12">
          <NormalInput
            placeholder="Phone Number "
            onChange={(e: any) => setPhone(e.target.value)}
            name="phone"
            value={phone}
          />
        </div>
      </div>

      <div className="w-3/12 mx-auto mt-8 mb-8 ">
        <Btn
          onClick={handleUpdate}
          active={true}
          name={privateprofile?.loading ? "loading" : "Save"}
        />
      </div>
    </div>
  );
};

export default PrivateEditProfile;
