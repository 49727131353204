import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
export interface JobsState {
  loading: boolean;
  editCorporateSuccess: boolean;
 
}

const initialState: JobsState = {
  loading: false,
  editCorporateSuccess :false,
 
};

export const corporateSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.editCorporateSuccess = false;
    },
  
  },
  extraReducers: (builder) => {
    builder

      .addCase(editCorporate.pending, (state) => {
        state.loading = true;
      })
      .addCase(editCorporate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.editCorporateSuccess = true;
      })
      .addCase(editCorporate.rejected, (state, { payload }) => {
        state.loading = false;
        state.editCorporateSuccess = false;
      });
  },
});



export const editCorporate = createAsyncThunk(
  "editCorporate",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.editCorporate}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const corporateSelector = (state: any) => state.corporate;

export const { restoreDefault } = corporateSlice.actions;
export default corporateSlice.reducer;
