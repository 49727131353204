import React, { ChangeEvent, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
interface inputProps {
  name?: string;
  placeholder?: string;
  classes?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
}

export const NormalInput = ({
  name,
  placeholder,
  classes,
  onChange,
  value,
}: inputProps) => {
  return (
    <div
      className={`${classes} border border-border rounded-[10px] px-3 py-2 shadow-sm  h-[52px] flex flex-col justify-center bg-white ${
        value ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
      } `}
    >
      <label
        htmlFor="name"
        className="block text-xs font-medium text-[#363636]"
      >
        {value ? name : ""}
      </label>
      <input
        type="text"
        name="name"
        autoComplete="off"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        id="name"
        className={`block w-full border-0 outline-none focus:outline-none p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base bg-inherit  `}
      />
    </div>
  );
};

export const PasswordInput = ({
  name,
  placeholder,
  classes,
  onChange,
  value,
}: inputProps) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  return (
    <div
      className={`flex ${classes} items-center border bg-white   rounded-[10px] py-[18px] px-[16px] h-[52px] w-full  ${
        value ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
      }
`}
      onMouseEnter={() => setActive(!active)}
    >
      <div className="flex flex-col w-11/12 justify-center">
        <label
          htmlFor="name"
          className="block text-xs font-medium text-[#363636]"
        >
          {value ? name : ""}
        </label>
        <input
          className={` w-full pr-2  placeholder:text-placeholder  outline-none bg-inherit  `}
          type={show ? "text" : "password"}
          name="nam"
          onChange={onChange}
          autoComplete="off"
          placeholder={placeholder}
        />
      </div>
      {show ? (
        <AiFillEye
          className="text-customBlack text-3xl cursor-pointer"
          onClick={() => setShow(false)}
        />
      ) : (
        <AiFillEyeInvisible
          className="text-customBlack text-3xl cursor-pointer"
          onClick={() => setShow(true)}
        />
      )}
    </div>
  );
};
