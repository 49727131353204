import React, { useState, useEffect } from "react";
import LoginContainer from "../../Layouts/LoginContainers";
import {
  BigText,
  HeaderText,
  ModalSub,
  ModalTitle,
  Subtitle,
} from "../../components/Text";
import { NormalInput, PasswordInput } from "../../components/Input";
import { Link } from "react-router-dom";
import LoginBox from "../../components/LoginBox";
import { Btn } from "../../components/Button";
import { Security } from "../../assets/svgs";
import ReUseModal from "../../components/Modal/ReUseModal";
const CreateNewPassword = () => {
  const [passwordB, setPasswordB] = useState("");
  const [password, setPassword] = useState("Password");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (passwordB && password) {
      setActive(true);
    } else setActive(false);
  }, [passwordB, password]);

  return (
    <div>
      <LoginContainer>
        <div className="md:w-9/12 w-full mt-28">
          <LoginBox>
            <HeaderText text="Create New Password" />
            <Subtitle text="Fill in the details to create a new password" />
            <div className="flex w-full flex-col mt-10   ">
              <PasswordInput
                placeholder=" New Password"
                classes="mb-[20px]"
                onChange={(e) => setPassword(e.target.value)}
              />
              <PasswordInput
                placeholder="New Confirm Password"
                onChange={(e) => setPasswordB(e.target.value)}
              />
              <Btn
                name="Reset"
                active={active}
                onClick={() => setOpen(true)}
                className="mt-[140px]"
              />
            </div>
          </LoginBox>
          <ReUseModal open={open} setOpen={setOpen}>
            <div className="w-full  flex flex-col justify-center">
              <div className="flex justify-center w-full">
                <Security />
              </div>
              <ModalTitle text="Password Updated" className="text-center" />
              <ModalSub
                text="Your password was successfully updated"
                className="text-center mt-1"
              />
              <Btn
                className="mt-10"
                active={true}
                name="Continue"
                onClick={() => setOpen(false)}
              />
            </div>
          </ReUseModal>
        </div>
      </LoginContainer>
    </div>
  );
};

export default CreateNewPassword;
