import { Helmet } from "react-helmet";
import HomeNav from "../../components/Navbars/HomeNav";
import ContainerLayout from "../../Layouts/ContainerLayout";
import {
  Message,
  NoMessageIcon,
  ReadMessageIcon,
} from "../../assets/svgs/homesvg";

import NoPic from "../../assets/png/no-pic.png";
import React, { useState } from "react";
import { CallIcon } from "../../assets/svgs";
import { Btn } from "../../components/Button";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import axios from "axios";
import { convertDateTime, getDisplayDate, toDate } from "../../utils";
import ChatService from "../../utils/apis/chats.service";
import { initSocket } from "../../utils/socket";

export interface ChatType {
  _id: string;
  newChat: boolean;
  unread: number;
  lastMessage: {
    _id: string;
    _sender: string;
    _to: string;
    _type: string;
    _chat: string;
    body: string;
    read: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  lastUpdate: string;
  user: {
    _id: string;
    _auth: string;
    first_name: string;
    last_name: string;
    email: string;
    profile_image: string;
  };
  type: string;
}
export interface MessageType {
  type: string;
  body: string;
  read: boolean;
  lastUpdate: string;
  sent: string;
  SentByMe: boolean;
}

const MessagePage = () => {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  // const isMobile = useMediaQuery({ maxDeviceWidth: 1224 });

  const [openChatMobile, setOpenChatMobile] = useState(false);

  const location = useLocation();
  React.useEffect(() => {
    if (!location.search) setOpenChatMobile(false);
  }, [location]);

  const { token } = useAppSelector((state) => state.auth);
  const [chats, setChats] = React.useState<ChatType[]>([]);
  const [selectedChat, setSelectedChat] = useState<ChatType>();

  React.useEffect(() => {
    if (token)
      (async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/chats`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.data) {
          setChats(data.data);
          setSelectedChat(data.data[0]);
        }
      })();
  }, [token]);

  return (
    <div className="w-full flex flex-col">
      <Helmet>
        <title>Message Page</title>
      </Helmet>
      <HomeNav isSearch={false} hidePractice={true} />
      <div className="w-full bg-greyBg flex flex-col z-1 [font-family:'Cabinet_Grotesk-Medium',Helvetica]">
        {/* <InterviewHome /> */}
        <ContainerLayout>
          <div className="rounded-[10px] border-[1px] border-[#D9D9D9] w-full h-full grid-cols-2 grid md:grid-cols-2 min-h-[calc(100vh-150px)] overflow-y-hidden">
            {/* Left Side */}
            {!openChatMobile && (
              <div className="w-full flex flex-col">
                {" "}
                {/* Added flex flex-col */}
                <div className="flex items-center min-h-[82px] w-full border-b-2 border-[#CFCFCF] py-4 px-2 space-x-3 [font-family:'Cabinet_Grotesk-Medium',Helvetica] font-medium">
                  <Message />
                  <div className="font-medium text-neutral neutral-700 text-[18px] tracking-[0.36px] leading-[20px] whitespace-nowrap">
                    Messages ({chats.length})
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto">
                  {/* Added flex-1 and overflow-y-auto */}
                  {/* Body */}
                  {chats.length < 1 ? (
                    <div className="w-full h-[400px] items-center flex flex-col justify-center">
                      <NoMessageIcon />
                      <p>No messages</p>
                    </div>
                  ) : (
                    <div className="px-4 py-4 space-y-4 flex-1 overflow-y-auto h-[calc(100vh-150px)]">
                      {chats.map((chat, id) => (
                        <ConversationUI
                          {...{
                            id,
                            chat,
                            setOpenChatMobile,
                            setSelectedChat,
                          }}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            {isDesktopOrLaptop ? (
              <ChatMessages selectedChat={selectedChat as ChatType} />
            ) : (
              openChatMobile && (
                <ChatMessages
                  selectedChat={selectedChat as ChatType}
                  className="!flex"
                />
              )
            )}
          </div>
        </ContainerLayout>
      </div>
    </div>
  );
};
const ConversationUI = ({
  chat,
  setOpenChatMobile,
  setSelectedChat,
  id,
}: {
  chat: ChatType;
  setSelectedChat: any;
  setOpenChatMobile: any;
  id: number;
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const navigate = useNavigate();

  return (
    <div
      key={id}
      className={`flex items-center justify-between ${
        id !== 0 ? "border-t-[1px] border-[#CFCFCF] pt-4" : ""
      }`}
      onClick={() => {
        !isDesktopOrLaptop && setOpenChatMobile(true);
        navigate(`/messages?name=${chat?.user._id}`);
        setSelectedChat(chat);
      }}
    >
      <div className=" flex items-center ">
        <img
          src={chat?.user?.profile_image || NoPic}
          alt=""
          className="w-[42px] h-[42px] rounded-full mr-3"
        />
        <div className="flex flex-col space-y-2">
          <p>{`${chat.user.first_name} ${chat.user.last_name}`}</p>

          <p className="flex items-center space-x-2">
            {chat?.lastMessage?.read ? (
              <span>
                <ReadMessageIcon />
              </span>
            ) : (
              ""
            )}
            <span className="line-clamp-1 text-[#5E5E5E] w-3/4 break-words">
              {chat?.lastMessage?.body}
            </span>
          </p>
        </div>
      </div>

      <div className="flex flex-col space-y-[10px] item-center">
        <p>{convertDateTime(chat.lastUpdate).time}</p>
        {chat.unread > 0 && (
          <div className="flex items-center justify-center w-[18px] h-[18px]  rounded-full bg-[#D3A518]">
            <span className="text-[12px] leading-[18px] text-white font-medium">
              {chat.unread}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
type ChatMessagesProps = {
  selectedChat: ChatType;
  className?: string;
  receiverAuthId?: string;
};

export const ChatMessages = ({
  selectedChat,
  className,
  receiverAuthId,
}: ChatMessagesProps) => {
  const { token, userData } = useAppSelector((state) => state.auth);
  const [messages, setMessages] = React.useState<MessageType[]>([]);

  React.useEffect(() => {
    if (token && selectedChat?._id)
      (async () => {
        const chatMessages = await ChatService.getChatMessages({
          id: selectedChat._id,
          token,
        });

        setMessages(chatMessages);
      })();
  }, [selectedChat?._id, token]);

  const [onlineData, setOnlineData] = useState<{
    online: boolean;
    lastSeen: string;
  }>({ online: false, lastSeen: "" });
  React.useEffect(() => {
    if (userData.user._auth && receiverAuthId && token)
      (async () => {
        const d = await ChatService.getOnlineStatus({
          id: receiverAuthId,
          token,
        });

        setOnlineData(d);
      })();
  }, [receiverAuthId, token, userData.user._auth]);
  const lastSeen = convertDateTime(onlineData.lastSeen);

  // Sort messages by date
  const sortedMessages = [...messages].sort(
    (a, b) => new Date(a.sent).getTime() - new Date(b.sent).getTime()
  );

  // Group messages by date
  const groupedMessages = sortedMessages.reduce<{
    [key: string]: MessageType[];
  }>((acc, message) => {
    const dateKey = getDisplayDate(toDate(message.sent));
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(message);
    return acc;
  }, {});
  async function handleAddMessage() {
    if (!newText) return;
    setMessages((prev) => [
      ...prev,
      {
        body: newText,
        read: false,
        lastUpdate: new Date().toISOString(),
        sent: new Date().toISOString(),
        SentByMe: true,
        type: "text",
      },
    ]);
    await ChatService.sendMessage({
      id: selectedChat._id,
      token,
      body: newText,
    });
    setNewText("");
  }
  const [newText, setNewText] = useState("");
  // const [socket, setSocket] = useState<any>(null);
  // React.useEffect(() => {
  //   if (userData.user._auth) {
  //     const s = initSocket(userData.user._auth);
  //     setSocket(s);
  //   }
  // }, [userData.user._auth]);
  return (
    <div
      className={`hidden w-full h-full md:flex flex-col  border-l-2 border-[#CFCFCF] ${className}`}
    >
      <div className="flex justify-between min-h-[82px] items-center w-full border-b-2 border-[#CFCFCF] py-4 px-2 space-x-3 [font-family:'Cabinet_Grotesk-Medium',Helvetica] font-medium">
        <div className="flex items-center">
          <img
            src={selectedChat?.user?.profile_image || NoPic}
            alt=""
            className="w-[37px] h-[37px] rounded-full mr-3"
          />
          <div>
            <p>{`${selectedChat?.user?.first_name} ${selectedChat?.user?.last_name}`}</p>
            {onlineData.online ? (
              <p className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle cx="4" cy="4" r="4" fill="#3F9256" />
                </svg>
                <p className="text-[#5E5E5E]">online</p>
              </p>
            ) : (
              <p>
                Last Seen: {lastSeen.date} {lastSeen.time}
              </p>
            )}
          </div>
        </div>
        <div className="flex space-x-4 items-center">
          <div className="rounded-[8px] bg-[#E3E9F1] px-[10px] py-[5px] flex items-center space-x-2 text-[#041C40]">
            <CallIcon />
            <span>Call</span>
          </div>
          <div className=" rounded-[8px] bg-[#041C40] px-6 py-2 text-white text-sm">
            View Profile
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="flex flex-col gap-8 px-4 h-[calc(100vh-320px)] overflow-y-scroll py-3">
        {Object.entries(groupedMessages).map(([date, messagesForDate]) => (
          <div key={date} className="space-y-2">
            <div className="w-full flex justify-center">
              <p className="text-[#9F9F9F] text-sm">{date}</p>
            </div>
            {messagesForDate.map((message, i) => (
              <MessageBox
                message={message}
                isReceiver={!message.SentByMe}
                key={i}
              />
            ))}
          </div>
        ))}
      </div>

      {/* Bottom */}
      <div>
        <div className="my-4 px-4">
          <textarea
            className="w-full h-[100px] p-4 rounded-[10px]"
            placeholder="Type a message"
            value={newText}
            onChange={(e) => setNewText(e.target.value)}
          ></textarea>
          <div className="flex justify-between">
            <div className="flex space-x-6 mt-2">
              <button>
                <img src="/link-2.svg" alt="" />
              </button>
              <button>
                <img src="/camera.svg" alt="" />
              </button>
              <button>
                <img src="/microphone-2.svg" alt="" />
              </button>
            </div>
            <div>
              <Btn
                active={newText.length > 0}
                onClick={handleAddMessage}
                name={"Send"}
                className="px-6"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const MessageBox = ({
  isReceiver,
  message,
}: {
  isReceiver?: boolean;
  message: MessageType;
}) => {
  // ${isReceiver ? "justify-end flex" : ""}
  return (
    <div className={`w-full grid grid-cols-2`}>
      <div className={`w-full ${!isReceiver ? "col-start-2" : "col-start-1"} `}>
        <div className="rounded-[18px] bg-[#E3E9F1] px-4 py-3 text-[#0E0E0E] font-normal leading-[22px] break-words">
          <p>{message.body}</p>
        </div>
        {message?.read && (
          <p className="text-[#868686] text-sm">
            Read {convertDateTime(message?.lastUpdate).time}
          </p>
        )}
      </div>
    </div>
  );
};
export default MessagePage;
