import React, { useState, useEffect } from "react";
import LoginBox from "../../components/LoginBox";
import LoginContainer from "../../Layouts/LoginContainers";
import {
  HeaderText,
  Subtitle,
  ModalSub,
  ModalTitle,
} from "../../components/Text";
import { NormalInput, PasswordInput } from "../../components/Input";
import { Btn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import { Brief, Message, Security } from "../../assets/svgs";
import { useNavigate } from "react-router-dom";
import PrimarySelect from "../../components/Selects/PrimarySelect";
import {
  states,
  LGAs,
  companyType,
  practiceArea,
  yearsCall,
  countries,
} from "../../utils/selectDatas";
import { registerLawyers } from "../../features/auth/AuthSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
export type locationType = {
  id: number;
  name: string;
};
const LawyerReg = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [present, setPresent] = useState(1);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const [selected, setSelected] = useState("");

  const [phone, setPhone] = useState("");

  const [amount, setAmount] = useState("");

  const [active, setActive] = useState(true);

  const [state, setState] = useState(states[0]);
  const [locals, setLocals] = useState<locationType[]>([]);
  const [local, setLocal] = useState(
    locals?.[0] || {
      name: "",
    }
  );
  const [industry, setIndustry] = useState(companyType[0]);
  const [area, setArea] = useState(practiceArea[0]);
  const [years, setYears] = useState(yearsCall[0]);
  const [country, setCountry] = useState(countries[0]);
  const [brief, setBrief] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const localsForState = LGAs.filter(
      (lga) => lga.state_id === state.id
    ) as unknown as locationType[];
    setLocal(localsForState[0]);

    setLocals(localsForState);
  }, [state.id]);

  useEffect(() => {
    if (name && address && country && phone) {
      setActive(true);
    } else setActive(false);
  }, [name, address, country, phone]);
  useEffect(() => {
    if (auth.onBoardingSuccess) {
      navigate("/login");
    }
  }, [auth.onBoardingSuccess]);

  const handleClick = () => {
    const data = {
      last_name: lastName,
      first_name: firstName,
      middle_name: middleName,
      area: area.name ? area.name : "",
      bio: brief,
      phone_number_country_code: "+234",
      phone_number: phone,
      brief_min_amount: amount ? amount : "0",
      brief_min_Currency: "NGN",
      year_of_call: years?.name,
      address: address,
      country: "Nigeria",
      state: state.name,
      LGA: local.name,
      website: website ? website : "",
    };
    dispatch(registerLawyers(data));
  };

  if (auth.loading) {
    return (
      <div className="bg-black w-full h-screen flex flex-col items-center justify-center  ">
        <iframe
          src="https://giphy.com/embed/3o7bu3XilJ5BOiSGic"
          width="100"
          height="100"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></iframe>
        <p>
          <a href="https://giphy.com/gifs/youtube-loading-gif-3o7bu3XilJ5BOiSGic">
            via GIPHY
          </a>
        </p>
        <p className="text-white text-xl ">Please wait....</p>
      </div>
    );
  }
  return (
    <LoginContainer>
      <div className="md:w-9/12 w-full mt-28 ">
        {/* START OF ONE */}
        {present === 1 && (
          <LoginBox>
            <HeaderText text="Personal Details" />
            <Subtitle text="Fill in the details below" />
            <div className="w-full mt-10 flex flex-col space-y-4">
              <NormalInput
                onChange={(e) => setFirstName(e.target.value)}
                name="First Name"
                placeholder="First Name"
                value={firstName}
              />
              <NormalInput
                onChange={(e) => setMiddleName(e.target.value)}
                name="Middle Name"
                placeholder="Middle Name"
                value={middleName}
              />
              <NormalInput
                onChange={(e) => setLastName(e.target.value)}
                name="Last Name"
                placeholder="Last Name"
                value={lastName}
              />
              <NormalInput
                onChange={(e) => setEmail(e.target.value)}
                name="Email"
                placeholder="Email Address"
                value={email}
              />
            </div>
            <div className="flex items-center space-x-4 mt-4">
              <div className="border-border border rounded-md h-[60px] flex   items-center w-2/12 justify-center ">
                <p className="text-base text-customBlack  font-[500]">+234</p>
              </div>
              <div className="w-10/12">
                <NormalInput
                  placeholder="Phone Number "
                  onChange={(e: any) => setPhone(e.target.value)}
                  name="phone"
                  value={phone}
                />
              </div>
            </div>

            <Btn
              className="mt-10"
              active={true}
              name="Next"
              onClick={() => setPresent(2)}
            />
            <div className="h-16 w-full"></div>
          </LoginBox>
        )}
        {/* end ODF ONE */}
        {/* START OF ONE */}
        {present === 2 && (
          <LoginBox>
            <HeaderText text="Further Details" />
            <Subtitle text="Fill in the details below" />
            <div className="w-full mt-10 flex flex-col space-y-4">
              <textarea
                name=""
                id=""
                cols={30}
                rows={5}
                onChange={(e: any) => setBrief(e.target.value)}
                className="border border-border rounded-[10px] px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-customBlack focus-within:border-customBlack"
                placeholder="Brief Description"
              ></textarea>

              <NormalInput
                onChange={(e) => setAmount(e.target.value)}
                name="amount"
                placeholder="Minimum amount for a brief"
              />
              <NormalInput
                onChange={(e) => setWebsite(e.target.value)}
                name="website"
                placeholder="Website"
              />

              <div className="flex w-full">
                <PrimarySelect
                  selected={area}
                  setSelected={setArea}
                  label="Practice Area"
                  data={practiceArea}
                />
              </div>
              <div className="flex w-full">
                <PrimarySelect
                  selected={years}
                  setSelected={setYears}
                  label="Year of Call"
                  data={yearsCall}
                />
              </div>
              <div className="flex w-full">
                <PrimarySelect
                  selected={country}
                  setSelected={setCountry}
                  label="Country"
                  data={countries}
                />
              </div>

              <div className="flex space-x-6 w-full  ">
                <PrimarySelect
                  selected={state}
                  setSelected={setState}
                  label="States"
                  data={states}
                />
                <PrimarySelect
                  selected={local}
                  setSelected={setLocal}
                  label="LGA"
                  data={locals}
                />
              </div>
              <NormalInput
                onChange={(e) => setAddress(e.target.value)}
                name="Address"
                placeholder=" Address"
                value={address}
              />
            </div>

            <button
              className={`mt-[47px] ${
                active ? "bg-primaryBlue" : "bg-primaryBlue "
              } text-white w-full h-[52px] flex justify-center items-center font-Circular rounded-[20px] cursor-pointer hover:opacity-70`}
              onClick={() => setPresent(3)}
            >
              Next
            </button>
            <div className="h-16 w-full"></div>
          </LoginBox>
        )}
        {/* end ODF ONE */}
        {/* START OF ONE */}
        {present === 3 && (
          <LoginBox>
            <HeaderText text="Would you like to make your profile available for companies, law firm and recruiters using our platform to employ lawyers?" />

            <div className="flex flex-col border border-border rounded-[15px] p-6 mt-10">
              {/* start */}
              <div
                className="relative flex items-start pb-4 border-b border-border cursor-pointer"
                onClick={() => setSelected("Yes")}
              >
                <div className="min-w-0 flex-1 text-sm">
                  <label className="font-[500] text-base  text-[#0e0e0e]  select-none">
                    Yes
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    name="plan"
                    type="radio"
                    checked={selected === "Yes" ? true : false}
                    className=" h-6 w-6 text-[#D3A518] border-none accent-[#D3A518]"
                  />
                </div>
              </div>
              {/* end */}
              {/* start */}
              <div
                className="relative flex items-start py-4 border-b border-border cursor-pointer"
                onClick={() => setSelected("No")}
              >
                <div className="min-w-0 flex-1 text-sm">
                  <label className="font-[500] text-base  text-[#0e0e0e]  select-none">
                    No
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    name="plan"
                    type="radio"
                    checked={selected === "No" ? true : false}
                    className=" h-6 w-6 text-[#D3A518] border-none accent-[#D3A518]"
                  />
                </div>
              </div>
              {/* end */}
            </div>

            <Btn
              className="mt-10"
              active={true}
              name="Next"
              // onClick={() => setOpen(true)}
              onClick={handleClick}
            />
            <div className="h-16 w-full"></div>
          </LoginBox>
        )}
        {/* end ODF ONE */}

        <ReUseModal open={open} setOpen={setOpen}>
          <div className="w-full  flex flex-col justify-center">
            <div className="flex justify-center w-full">
              <Message />
            </div>
            <ModalTitle text="Successful" className="text-center" />
            <ModalSub text="Proceed to login" className="text-center mt-1" />
            <Btn
              className="mt-10"
              active={true}
              name="Continue"
              onClick={() => navigate("/login")}
            />
          </div>
        </ReUseModal>
      </div>
    </LoginContainer>
  );
};

export default LawyerReg;
