import {useEffect, useState} from 'react'
import pic from "../../assets/png/profile.jpg";
import SideModal from '../Modal/SideModal';
import ReUseModal from '../Modal/ReUseModal';
import { BriefCase } from '../../assets/svgs';
import { Btn } from '../Button';
import { ModalTitle, ModalSub } from '../Text';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { sendFeedback } from '../../features/auth/JobSlice';
 interface singleUserInterProps {
     name: string;
     img: string;
     date: string;
     time: string;
   link?: string;
   border?: boolean;
   item: any;
   accept: boolean;
   
}
const SingleUser = ({img,name,date,time,item,accept} : singleUserInterProps) => {
  const job = useAppSelector((state) => state.job);
    const [openSide, setOpenSide] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [active, setActive] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [refLetter, setRefLetter] = useState("");
  const dispatch = useAppDispatch();
  const id = item?._id !== null || undefined ? item?._id : "";

  useEffect(() => {
    if (refLetter || feedback) {
      setActive(true);
    }
  }, [feedback,refLetter])
  
  

  const handleSend = () => {
    const data = {
      data: {
        
        feedback: feedback,
        reference: refLetter,
      },
      id:id
    };
    dispatch(sendFeedback(data));
    
  }
 
  
  useEffect(() => {
    if (job.sendFeedbackSuccess) {
      setOpenSide(false);
      setOpenModal(true);
      setTimeout(() => {
        setOpenModal(false);
      }, 3000);
    }
  }, [job.sendFeedbackSuccess])
  
  return (
    <>
      <div className="w-full items-center justify-between flex">
        <div
          className="flex space-x-4 items-center w-auto cursor-pointer"
          onClick={() => setOpenSide(true)}
        >
          <img
            src={img ? img : pic}
            alt="name"
            className="w-[42px] h-[42px] rounded-full"
          />
          <div className="flex flex-col">
            <p className="text-primaryBlue font-bold text-xl ">{name}</p>
            <div className="flex space-x-4 items-center">
              <p className="text-base text-neutral-500">{date}</p>
              <p className="text-base text-neutral-500">{time}</p>
            </div>
          </div>
        </div>
        {accept === false && (
          <p className="text-white font-[500] text-sm bg-primaryBlue rounded-[20px] py-[7px] px-[8px] cursor-pointer w-fit ">
            Awaiting Approval
          </p>
        )}
        {accept === true && (
          <p className="text-[#D3A518] font-[500] text-lg  cursor-pointer w-fit ">
            Meeting Link
          </p>
        )}
      </div>
      <SideModal open={openSide} setOpen={setOpenSide} title="Send Feedback"> 
        <div className="flex flex-col relative h-[90vh] flow-hide">

        <p className="text-center text-lg text-grey-100 mt-5">
          Send your feedback to your applicant. You can also write them a letter
          of reference at the end of their contract.
        </p>
        <div className="w-full flex flex-col space-y-8 mt-8">
          <div
            className={` border border-border rounded-[10px] px-3 py-2 shadow-sm  h-[132px] flex flex-col justify-center bg-white ${
              feedback ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
            } `}
          >
            <label
              htmlFor="name"
              className="block text-xs font-medium text-[#363636]"
            >
              {feedback ? "Feedback" : ""}
            </label>
            <textarea
              name="name"
              autoComplete="off"
              onChange={(e: any) => setFeedback(e.target.value)}
              placeholder="Feedback"
              id="name"
              className={`block w-full border-0 outline-none focus:outline-none p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base bg-inherit h-full  `}
              />
          </div>
          <div
            className={` border border-border rounded-[10px] px-3 py-2 shadow-sm  h-[132px] flex flex-col justify-center bg-white ${
              refLetter ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
            } `}
            >
            <label
              htmlFor="name"
              className="block text-xs font-medium text-[#363636]"
              >
              {refLetter ? "Reference Letter" : ""}
            </label>
            <textarea
              name="name"
              autoComplete="off"
              onChange={(e: any) => setRefLetter(e.target.value)}
              placeholder="Reference Letter"
              id="name"
              className={`block w-full border-0 outline-none focus:outline-none p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base bg-inherit h-full  `}
              />
          </div>
        </div>
          <div className="absolute bottom-12 left-0 right-0">
            
        <Btn
          name={job.loading ? "Loading..." : "Send"}
          onClick={handleSend}
          active={active}
          className="mt-[128px] mb-6"
          />
          </div>
          </div>
      </SideModal>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full  flex flex-col justify-center">
          <div className="flex justify-center w-full">
            <BriefCase />
          </div>
          <ModalTitle
            text="Interview Sent Successfully"
            className="text-center"
          />
          <ModalSub
            text="Interview has been sent successfully you will be notified if there is a new update"
            className="text-center mt-1"
          />
          <Btn
            className="mt-10 cursor-pointer"
            active={true}
            name="Continue"
            onClick={() => setOpenModal(false)}
          />
        </div>
      </ReUseModal>
    </>
  );
}

export default SingleUser