import React, { useEffect, useState } from "react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import BriefCard from "./BriefCard";
import { BriefData, EmploymentData, JobsData } from "../../utils/datas";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import EmploymentCard from "./EmploymentCard";
import SideModal from "../Modal/SideModal";
import JobCard from "./JobCards";
import { NormalInput } from "../Input";
import PrimarySelect from "../Selects/PrimarySelect";
import { positions, countries, states } from "../../utils/selectDatas";
import { Btn } from "../Button";
import ReUseModal from "../Modal/ReUseModal";
import { ModalSub, ModalTitle } from "../Text";
import { BriefCase } from "../../assets/svgs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createJob,
  getAllActiveJob,
  resetAllJobState,
} from "../../features/auth/JobSlice";
import { stateArray } from "../../utils/nigeriaStates";

const JobsSection = () => {
  const [open, setOpen] = useState(false);
  const sampImage =
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const jobs = useAppSelector((state) => state.job);
  const userData = useAppSelector((state) => state.auth);

  const allJobs = jobs?.allActiveJobs;
  const userImage = userData?.userData?.user?.profile_image;

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(userImage ? userImage : sampImage);
  const [bio, setBio] = useState("");
  const [resp, setResp] = useState("");
  const [position, setPosition] = useState<any>({});
  const [website, setWebsite] = useState("");
  const [country, setCountry] = useState<any>({});
  const [city, setCity] = useState<any>({});
  const [openJobModal, setOpenJobModal] = useState(false);
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (name && bio && resp && country && city && position && title) {
      setActive(true);
    } else setActive(false);
  }, [name, bio, resp, website, country, city, position, title]);

  useEffect(() => {
    if (jobs.success) {
      setTimeout(() => {
        dispatch(resetAllJobState());
        dispatch(getAllActiveJob());

        setOpenJobModal(false);
        setOpen(false);
      }, 1000);
    }
  }, [jobs.success]);
  const handleCreate = () => {
    const data = {
      name: name,
      job_title: title,
      description: bio,
      position_type: position?.name,
      responsibilities: resp,
      website: website,
      country: country?.name,
      state: city?.name,
      image: image,
    };
    dispatch(createJob(data));
  };

  useEffect(() => {
    if (jobs.createJobSuccess) {
      setOpenModal(true);
      setTimeout(() => {
        dispatch(resetAllJobState());

        setOpenModal(false);
      }, 1000);
    }
  }, [jobs.createJobSuccess]);

  return (
    <>
      <ContainerLayout>
        <div className="flex flex-col">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-bold text-blueText">Job Feeds</p>
            <div
              className="flex space-x-6 items-center cursor-pointer"
              onClick={() => setOpen(true)}
            >
              <p>Post Job Offers</p>
            </div>
          </div>
          <div className="w-full grid md:grid-cols-4 grid-cols-2 sm:grid-cols-3 md:gap-x-8 gap-x-4 md:gap-y-[50px] gap-y-[30px] md:my-6 my-4">
            {allJobs.length !== 0 &&
              allJobs.slice(0, 8).map((item: any, index: number) => {
                const location = item?.state + "," + item?.country;
                return (
                  <JobCard
                    key={index}
                    name={item?.job_title}
                    jobType={item?.position_type}
                    location={location}
                    company={item.company}
                    desc={item?.description}
                    image={item?.image}
                    year={"2023"}
                    id={item?._id}
                    sector={""}
                  />
                );
              })}
          </div>
        </div>
      </ContainerLayout>

      <SideModal open={open} setOpen={setOpen} title={"Post Job Offer"}>
        <div className="w-full flex flex-col ">
          <p className="text-center text-lg text-grey-100">
            Share the details of the company and interview with the applicants
          </p>
          <div className="w-full flex justify-center mt-7">
            <span className="inline-block relative">
              <img
                className="h-[119px] w-[119px] rounded-full"
                src={image}
                alt=""
              />
              <span className="absolute bottom-0 right-0 block h-4 w-4 rounded-full ring-2 ring-white bg-[#D3A518]" />
            </span>
          </div>
          <div className="w-full flex flex-col px-4 mt-[30px] space-y-5">
            <NormalInput
              onChange={(e: any) => setName(e.target.value)}
              name="Name of the Company"
              placeholder="Name of the Company"
              value={name}
            />
            <NormalInput
              onChange={(e: any) => setTitle(e.target.value)}
              name="Job Title"
              placeholder="Job Title"
              value={title}
            />
            <div
              className={` border border-border rounded-[10px] px-3 py-2 shadow-sm  h-[132px] flex flex-col justify-center bg-white ${
                bio ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
              } `}
            >
              <label
                htmlFor="name"
                className="block text-xs font-medium text-[#363636]"
              >
                {bio ? "Bio of the Company" : ""}
              </label>
              <textarea
                name="name"
                autoComplete="off"
                onChange={(e: any) => setBio(e.target.value)}
                placeholder="Job Description"
                id="name"
                className={`block w-full border-0 outline-none focus:outline-none p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base bg-inherit h-full  `}
              />
            </div>
            <div
              className={` border border-border rounded-[10px] px-3 py-2 shadow-sm  h-[132px] flex flex-col justify-center bg-white ${
                resp ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
              } `}
            >
              <label
                htmlFor="name"
                className="block text-xs font-medium text-[#363636]"
              >
                {resp ? "Responsibilities" : ""}
              </label>
              <textarea
                name="name"
                autoComplete="off"
                onChange={(e: any) => setResp(e.target.value)}
                placeholder="Responsibilities"
                id="name"
                className={`block w-full border-0 outline-none focus:outline-none p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base bg-inherit h-full  `}
              />
            </div>
            <div className="flex w-full">
              <PrimarySelect
                selected={position}
                setSelected={setPosition}
                label="What type of position"
                data={positions}
              />
            </div>
            <NormalInput
              onChange={(e: any) => setWebsite(e.target.value)}
              name="Company’s website"
              placeholder="Company’s website"
              value={website}
            />
            <div className="flex space-x-6">
              <PrimarySelect
                selected={country}
                setSelected={setCountry}
                label="Country"
                data={countries}
              />
              <PrimarySelect
                selected={city}
                setSelected={setCity}
                label="State"
                data={stateArray}
              />
            </div>
          </div>
          <Btn
            name={jobs.loading ? "Loading..." : "Create"}
            onClick={handleCreate}
            active={active}
            className="mt-[128px] mb-6"
          />
        </div>
      </SideModal>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full  flex flex-col justify-center">
          <div className="flex justify-center w-full">
            <BriefCase />
          </div>
          <ModalTitle text="Job Created Successfull" className="text-center" />
          <ModalSub
            text="Your job creation was successfully created you will be notified once people starts to apply for the job."
            className="text-center mt-1"
          />
          <Btn
            className="mt-10"
            active={true}
            name="Close"
            onClick={() => setOpenModal(false)}
          />
        </div>
      </ReUseModal>
      <ReUseModal open={openJobModal} setOpen={setOpenJobModal}>
        <div className="w-full  flex flex-col justify-center">
          <div className="flex justify-center w-full">
            <BriefCase />
          </div>
          <ModalTitle text="Application Sent" className="text-center" />
          <ModalSub
            text="You’ve successfully applied for this job. You’ll be notified once the interview date has been set"
            className="text-center mt-1"
          />
          <Btn
            className="mt-10 cursor-pointer"
            active={true}
            name="Close"
            onClick={() => {
              setOpenModal(false);
              setOpen(false);
            }}
          />
        </div>
      </ReUseModal>
    </>
  );
};

export default JobsSection;
