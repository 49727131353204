import React, { useEffect, useState } from "react";
import stars from "../../assets/png/star-rate.png";
import { BriefCase } from "../../assets/svgs";
import { Btn } from "../Button";
import ReUseModal from "../Modal/ReUseModal";

import useImage from "../../assets/png/club-t.png";
import { TopicText, Subtitle, ModalTitle, ModalSub } from "../Text";
import Sidebar from "../SideModal";
import SideModal from "../Modal/SideModal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSingleJob, applyForJob } from "../../features/auth/JobSlice";
import UploadFile from "../Upload/UploadFile";
import { resetAllJobState } from "../../features/auth/JobSlice";
import { getUserButton } from "../../utils";
interface briefBox {
  name: string;
  sector: string;
  price?: string;
  desc: string;
  rate?: string;
  image: any;
  year?: any;
  location?: string;
  company?: string;
  jobType?: string;
  id: string;
}
const JobCard = ({
  desc,

  image,

  company,
  location,
  jobType,
  id,
  name,
}: briefBox) => {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const job = useAppSelector((state) => state.job);
  const [active, setActive] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");
  const userData = useAppSelector((state) => state.auth);
  const userType = userData.userData.user._type;

  const handleOpen = () => {
    dispatch(getSingleJob({ id: id }));
    setOpen(true);
  };

  useEffect(() => {
    dispatch(resetAllJobState());
  }, []);

  useEffect(() => {
    if (id && fileUrl) {
      setActive(true);
    } else setActive(false);
  }, [id, fileUrl]);

  let jobResp = job.singleJob?.responsibilities?.split(".");
  const handleApply = () => {
    const info = {
      id: id,
      data: {
        file: fileUrl,
      },
    };
    dispatch(applyForJob(info));
  };
  useEffect(() => {
    if (job.success) {
      setOpenModal(true);
      setTimeout(() => {
        setOpen(false);
      }, 500);
    }
  }, [job.success]);

  return (
    <>
      <div className="w-full login-box flex flex-col h-full flow-hide brief-card-bg">
        <div className="border-t-[30px] border-[#CFCFCF] rounded-t-[10px]"></div>

        <div
          className="md:px-4 px-3 md:pt-4 mt-[-20px] md:mt-0 z-10 flow-hide h-full flex flex-col md:space-y-4 space-y-2"
          onClick={handleOpen}
        >
          <img
            src={image}
            alt="name"
            className="w-[90px] h-[90px] rounded-full mx-auto object-cover"
          />
          <p className="text-[#0e0e0e] text-lg font-bold  text-center mt-2 ">
            {name}
          </p>
          <p className="text-[#5E5E5E] text-base  text-center mt-1 ">
            {jobType}
          </p>
          <p className="text-[#5E5E5E] text-base  text-center mt-1 ">
            {location}
          </p>

          <p className="text-base text-justify text-[#5e5e5e] h-[120px] overflow-y-hidden pb-3">
            {desc}
          </p>
        </div>
        <div className="w-full mt-auto my-8 pt-8">
          <div className="w-4/5 px-6 py-3 rounded-[10px] border border-sky-950 justify-center items-center gap-2 mx-auto flex ">
            <div className="text-sky-950 text-sm font-medium font-['Circular Std'] tracking-tight">
              Apply
            </div>
          </div>
        </div>
      </div>
      <SideModal title="Interview Details" open={open} setOpen={setOpen}>
        <div className="flex flex-col relative h-screen">
          <div className="flex items-center space-x-4">
            <img
              src={image}
              alt=""
              className="w-[98px] h-[98px] rounded-full object-cover "
            />
            <div className="flex flex-col">
              <p className="text-lg font-bold text-customBlack ">{name}</p>
              <p className="text-lightText text-base mt-0">{jobType}</p>
            </div>
          </div>
          <div className="flex flex-col mt-7">
            <TopicText text="About" className="mb-[10px]" />
            <Subtitle text={desc} />
          </div>
          <div className="flex flex-col mt-7">
            <ul className="flex flex-col list-disc px-6">
              <TopicText text="Job Responsibilities" className="mb-[10px]" />
              {jobResp?.length !== 0 &&
                jobResp?.map((item: any, index: number) => (
                  <li className="text-lightText text-base">{item}</li>
                ))}
            </ul>
          </div>
          <div className="mt-7 flex flex-col">
            <TopicText text="Company’s Website" className="mb-3" />
            <a
              href="http://"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-[#4E8BD2]"
            >
              www.quantuminc.com
            </a>
          </div>
          <div className="block py-6">
            <UploadFile
              getFileUrl={setFileUrl}
              className="mt-4 "
              name="Upload Resume"
            />
          </div>
          <div className="mt-10 pb-10">
            <Btn
              name={job.loading ? "Loading" : "Apply"}
              onClick={handleApply}
              active={active}
            />
          </div>
        </div>
      </SideModal>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full  flex flex-col justify-center">
          <div className="flex justify-center w-full">
            <BriefCase />
          </div>
          <ModalTitle text="Application Sent" className="text-center" />
          <ModalSub
            text="You’ve successfully applied for this job. You’ll be notified once the interview date has been set"
            className="text-center mt-1"
          />
          <Btn
            className="mt-10 cursor-pointer"
            active={true}
            name="Close"
            onClick={() => {
              setOpenModal(false);
              setOpen(false);
            }}
          />
        </div>
      </ReUseModal>
    </>
  );
};

export default JobCard;
