import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useAppDispatch } from "../../app/hooks";
import Briefs from "../../components/HomeComponents/Briefs";
import Employments from "../../components/HomeComponents/Employments";
import JobsSection from "../../components/HomeComponents/Jobs";
import AllPendingInterview from "../../components/Lawyer/AllPendingInterview";
import HomeNav from "../../components/Navbars/HomeNav";
import {
  getAllActiveJob,
  getAllMyApplications,
  getAllRecievedInterview,
} from "../../features/auth/JobSlice";
import {
  getAllLawyers,
  getAllMyBrief,
  sharedBrief,
} from "../../features/auth/LawyerSlice";
import { getAllNotifications } from "../../features/auth/GeneralSlice";
import { getAllSentInterview } from "../../features/auth/JobSlice";
const LawyerHome = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllLawyers());
    dispatch(getAllActiveJob());
    dispatch(getAllMyBrief());
    dispatch(sharedBrief());
    dispatch(getAllMyApplications());
    dispatch(getAllNotifications());
    dispatch(getAllSentInterview());
    dispatch(getAllRecievedInterview());
  }, []);

  return (
    <div className="w-full flex flex-col">
      <Helmet>
        <title>Lawyer</title>
      </Helmet>
      <HomeNav isSearch={false} />
      <div className="w-full bg-greyBg flex flex-col z-1 space-y-4 md:space-y-0">
        {/* <Industry /> */}
        <AllPendingInterview />
        <Briefs />
        <Employments />
        {/* <JobsSection /> */}
      </div>
    </div>
  );
};

export default LawyerHome;
