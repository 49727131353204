import React from "react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import InterviewCard from "./InterviewCard";
import { useAppSelector } from "../../app/hooks";
const data = [1, 2, 3, 4];
const AllPendingInterview = () => {
  const job = useAppSelector((state) => state.job);
  const allInterview = job?.allRecievedInterview;
  const actualInterviews = [
    ...allInterview?.filter((item: any) => item?.state === "Pending"),
    ...allInterview?.filter((item: any) => item?.state === "Pending"),
  ];

  return (
    <div className="w-full flex  ">
      <ContainerLayout>
        <div className="w-full flex flex-col ">
          {allInterview.length !== 0 && (
            <div className="flex flex-col my-8">
              <h1 className="text-xl text-primaryBlue font-bold mb-4">
                Pending Interview
              </h1>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 my-4 gap-4">
                {actualInterviews.map((item: any, index: number) => {
                  return <InterviewCard key={index} item={item} />;
                })}
              </div>
            </div>
          )}
        </div>
      </ContainerLayout>
    </div>
  );
};

export default AllPendingInterview;
