import axios from "axios";
class ChatService {
  static async getChats({ token }: { token: string }) {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.lawploy.com/api/v1/chats",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjRlZjI1ODRlNDk1NzJlYjE2ODhiZDhhIiwidHlwZSI6ImNvcnBvcmF0aW9uIiwiaWF0IjoxNjk1NzM0NDU2fQ.3YTowlJnlUm1nl7uCZcmtGkx8Hx66PLjMgh_m53IsfU",
      },
    };

    try {
      const response = await axios.request(config);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getChatMessages({ id, token }: { id: string; token: string }) {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.lawploy.com/api/v1/chats/${id}/message`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios
      .request(config)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static async getOnlineStatus({ id, token }: { id: string; token: string }) {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.lawploy.com/api/v1/chats/user/status/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios
      .request(config)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static async createChat({ id, token }: { id: string; token: string }) {
    let data = JSON.stringify({
      recevier: id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.lawploy.com/api/v1/chats",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    return axios
      .request(config)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static async sendMessage({
    id,
    token,
    body,
  }: {
    id: string;
    token: string;
    body: string;
  }) {
    let data = JSON.stringify({
      type: "text",
      body,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://api.lawploy.com/api/v1/chats/${id}/send`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Cookie: `token=${token}`,
      },
      data: data,
    };

    return axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
export default ChatService;
