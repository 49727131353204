import ContainerLayout from "../../Layouts/ContainerLayout";
import { useAppSelector } from "../../app/hooks";
import EmploymentCard from "../HomeComponents/EmploymentCard";
import SingleUser from "./SingleUser";
import pic from "../../assets/png/profile.jpg";
import { convertDateTime } from "../../utils";
import { NotificationIconNew } from "../../assets/svgs/homesvg";
import React from "react";
import axios from "axios";
interface NotificationType {
  _id: string;
  from: {
    _id: string;
    firmID: null;
    lawyerID: null;
    corporationID: {
      _id: string;
      name: string;
      profile_image: null;
    };
    privateID: null;
    _type: string;
  };
  title: string;
  type: string;
  seen: boolean;
  action: null;
  createdAt: string;
}
const NotificationHome = () => {
  const lawyers = useAppSelector((state) => state.lawyer);
  const job = useAppSelector((state) => state.job);

  let allLawyers = lawyers?.allLawyers ? lawyers?.allLawyers : [];
  let allSent = job?.allSentInterview ? job?.allSentInterview : [];

  const { token } = useAppSelector((state) => state.auth);
  const [notifications, setNotifications] = React.useState<NotificationType[]>(
    []
  );

  React.useEffect(() => {
    if (token)
      (async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/notifications`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data.data) setNotifications(data.data);
      })();
  }, [token]);
  return (
    <ContainerLayout>
      <div className="w-full flex flex-col md:flex-row">
        <div className="md:w-1/2 w-full h-full md:min-h-[70vh] flow-hide md:border-r md:border-r-border flex flex-col ">
          <h1 className="mt-4 text-primaryBlue text-2xl font-bold tracking-[0.48px]">
            Notifications
          </h1>
          <div className="flex flex-col space-y-4 pr-6 mt-8">
            {notifications.map((notification, id) => (
              <div
                key={id}
                className={`flex items-center justify-between w-full border-[#CFCFCF] ${
                  id > 0 ? "border-t-[1px] pt-3" : ""
                }`}
              >
                <div className="flex items-center space-x-5">
                  <div className="w-[42px] h-[42px] rounded-full bg-[#F6EDD1] flex justify-center items-center">
                    <NotificationIconNew />
                  </div>
                  <div className="space-y-2">
                    <p className="text-[#041C40] text-[20px]">
                      {notification.title}
                    </p>
                    <p className="text-[#5E5E5E]">
                      {convertDateTime(notification.createdAt).date}{" "}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[#5E5E5E]">
                    {convertDateTime(notification.createdAt).time}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="md:w-1/2 w-full flex flex-col md:pl-8 mt-8 md:mt-0 flow-hide h-[70vh]">
          <h1 className="font-bold text-primaryBlue text-2xl">Employment</h1>
          <div className="w-full grid grid-cols-2 md:grid-cols-2 md:gap-x-8 gap-x-3 md:gap-y-[50px] gap-y-5 my-6 ">
            {allLawyers?.slice(0, 8)?.map((item: any, index: number) => {
              const {
                area,
                country,
                first_name,
                last_name,
                profile_image,
                year_of_call,
                _id,
                bio,
                state,
              } = item;
              return (
                <EmploymentCard
                  key={index}
                  name={first_name + " " + last_name}
                  sector={area}
                  price={"0"}
                  desc={bio}
                  rate={""}
                  location={
                    country && state ? state + " ," + " " + country : ""
                  }
                  image={profile_image ? profile_image : pic}
                  year={year_of_call}
                  id={_id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ContainerLayout>
  );
};

export default NotificationHome;
