import React, { useState, useEffect } from "react";
import LoginBox from "../../components/LoginBox";
import LoginContainer from "../../Layouts/LoginContainers";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  HeaderText,
  Subtitle,
  ModalSub,
  ModalTitle,
} from "../../components/Text";
import { NormalInput, PasswordInput } from "../../components/Input";
import { Btn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import { Brief, Message, Security } from "../../assets/svgs";
import { useNavigate, useSearchParams } from "react-router-dom";
import PrimarySelect from "../../components/Selects/PrimarySelect";
import { states, LGAs, practiceArea } from "../../utils/selectDatas";
import { registerFirms } from "../../features/auth/AuthSlice";
import { locationType } from "./LawyerReg";

const LawFirmReg = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");

  const [state, setState] = useState(states[0]);
  const [locals, setLocals] = useState<locationType[]>([]);
  const [local, setLocal] = useState(
    locals?.[0] || {
      name: "",
    }
  );

  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [area, setArea] = useState<any>({});

  useEffect(() => {
    const localsForState = LGAs.filter(
      (lga) => lga.state_id === state.id
    ) as unknown as locationType[];
    setLocal(localsForState[0]);

    setLocals(localsForState);
  }, [state.id]);

  useEffect(() => {
    if (name && address && country && phone) {
      setActive(true);
    } else setActive(false);
  }, [name, address, country, phone]);
  const handleClick = () => {
    const data = {
      name: name,
      area: area?.name,
      phone_number_country_code: "+234",
      phone_number: phone,
      address: address,
      country: country,
      state: state?.name,
      LGA: local?.name,
      website: website,
    };

    dispatch(registerFirms(data));
  };

  useEffect(() => {
    if (auth.onBoardingSuccess) {
      navigate("/login");
    }
  }, [auth.onBoardingSuccess]);

  if (auth.loading) {
    return (
      <div className="bg-black w-full h-screen flex flex-col items-center justify-center  ">
        <iframe
          src="https://giphy.com/embed/3o7bu3XilJ5BOiSGic"
          width="100"
          height="100"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></iframe>
        <p>
          <a href="https://giphy.com/gifs/youtube-loading-gif-3o7bu3XilJ5BOiSGic">
            via GIPHY
          </a>
        </p>
        <p className="text-white text-xl ">Please wait....</p>
      </div>
    );
  }

  return (
    <LoginContainer>
      <div className="md:w-9/12 w-full mt-28 ">
        <LoginBox>
          <HeaderText text="Law Firm Details" />
          <Subtitle text="Fill in the details below" />
          <div className="w-full mt-10 flex flex-col space-y-4">
            <NormalInput
              onChange={(e) => setName(e.target.value)}
              name="Principal’s Name"
              placeholder="Principal’s Name"
            />
            <div className="flex items-center space-x-4 mt-4">
              <div className="border-border border rounded-md h-[60px] flex   items-center w-2/12 justify-center ">
                <p className="text-base text-customBlack  font-[500]">+234</p>
              </div>
              <div className="w-10/12">
                <NormalInput
                  placeholder="Phone Number"
                  onChange={(e: any) => setPhone(e.target.value)}
                  name="phone"
                  value={phone}
                />
              </div>
            </div>
            <NormalInput
              onChange={(e) => setAddress(e.target.value)}
              name="Law fim’s Address"
              placeholder="Law fim’s Address"
              value={address}
            />
            <NormalInput
              onChange={(e) => setCountry(e.target.value)}
              name="Country"
              placeholder="Country"
              value={country}
            />

            <div className="flex w-full">
              <PrimarySelect
                selected={area}
                setSelected={setArea}
                label="Practice Area"
                data={practiceArea}
              />
            </div>
            <div className="flex space-x-6 w-full  ">
              <PrimarySelect
                selected={state}
                setSelected={setState}
                label="States"
                data={states}
              />
              <PrimarySelect
                selected={local}
                setSelected={setLocal}
                label="Local"
                data={locals}
              />
              {/* <PrimarySelect  /> */}
            </div>
            <NormalInput
              onChange={(e) => setWebsite(e.target.value)}
              name="Website"
              placeholder="Law firm’s Website (Optional)"
              value={website}
            />
          </div>

          <button
            className={`mt-[47px] ${
              active ? "bg-primaryBlue" : "bg-primaryBlue opacity-40"
            } text-white w-full h-[52px] flex justify-center items-center font-Circular rounded-[20px] cursor-pointer hover:opacity-70`}
            disabled={!active}
            onClick={handleClick}
          >
            Next
          </button>
          <div className="h-16 w-full"></div>
        </LoginBox>
        <ReUseModal open={open} setOpen={setOpen}>
          <div className="w-full  flex flex-col justify-center">
            <div className="flex justify-center w-full">
              <Message />
            </div>
            <ModalTitle text="Check your email" className="text-center" />
            <ModalSub
              text="We’ve sent an OTP code to your email to reset your password"
              className="text-center mt-1"
            />
            <Btn
              className="mt-10"
              active={true}
              name="Continue"
              onClick={() => navigate("/otp")}
            />
          </div>
        </ReUseModal>
      </div>
    </LoginContainer>
  );
};

export default LawFirmReg;
