import { useEffect, useState } from "react";
import Toggler from "../Togglers";
import {
  EmailNot,
  InAPPNot,
  PasswordNot,
  PrivacyNot,
} from "../../assets/svgs/profile";
import { NormalInput } from "../Input";
import { Btn } from "../Button";
const Settings = () => {
  const [appNot, setAppNot] = useState<boolean>(false);
  const [emailNot, setEmailNot] = useState<boolean>(false);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(false);
  const handlePasswordChange = () => {};

  useEffect(() => {
    if (password.length > 8) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [password]);

  return (
    <div className="w-full flex flex-col bg-white rounded-[10px] min-h-[80vh]">
      <div className="w-full px-4 2xl:px-6 pt-7 flex flex-col pb-1 border-b border-b-border ">
        <h1 className="text-blueText md:text-2xl text-xl font-bold">
          Settings
        </h1>
      </div>
      <div className="flex flex-col mt-4 px-4 2xl:px-6">
        <h1 className="text-customBlack md:text-lg text-base font-bold">
          Notifications
        </h1>

        <div className="w-full md:w-9/12 2xl:w-7/12 flex flex-col">
          {/* start */}
          <div className="w-full flex justify-between items-center mt-5">
            <div className="w-auto flex space-x-4 items-center">
              <InAPPNot />
              <p className="text-neutral-700 font-[500] md:text-xl text-base">
                In-app Notification
              </p>
            </div>
            <div className="w-auto flex space-x-5">
              <Toggler enabled={appNot} setEnabled={setAppNot} />
            </div>
          </div>
          {/* end */}
          {/* start */}
          <div className="w-full flex justify-between items-center mt-5">
            <div className="w-auto flex space-x-4 items-center">
              <EmailNot />
              <p className="text-neutral-700 font-[500] md:text-xl text-base">
                Email Notification
              </p>
            </div>
            <div className="w-auto flex space-x-5">
              <Toggler enabled={emailNot} setEnabled={setEmailNot} />
            </div>
          </div>
          {/* end */}
          <h1 className="text-customBlack text-base md:text-lg font-bold mt-5 ">
            Security
          </h1>
          <div
            className="w-auto flex space-x-4 items-center mt-5 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            <PasswordNot />
            <p className="text-neutral-700 font-[500] text-xl">
              Change Password
            </p>
          </div>
          {showPassword && (
            <div className="w-full md:w-8/12 flex flex-col mt-6">
              <NormalInput
                onChange={(e: any) => setPassword(e.target.value)}
                placeholder="Enter new password"
                name="new password"
                classes="mb-4"
              />
              <Btn onClick={handlePasswordChange} active={active} name="Save" />
            </div>
          )}
          <h1 className="text-customBlack text-base md:text-lg font-bold mt-5 ">
            Privacy
          </h1>
          <div className="w-full flex justify-between items-center mt-5">
            <div className="w-auto flex space-x-4 items-center">
              <PrivacyNot />
              <p className="text-neutral-700 font-[500] text-xl">
                Keep your profile private
              </p>
            </div>
            <div className="w-auto flex space-x-5">
              <Toggler enabled={isPrivate} setEnabled={setIsPrivate} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
