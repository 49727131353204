import React, { useState } from "react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SharedBrief from "./lawyers/SharedBrief";
import MyBriefs from "./lawyers/MyBrief";
import Settings from "./Settings";
import noPic from "../../assets/png/no-pic.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetAll } from "../../features/auth/AuthSlice";
import ProfileTab from "./ProfileTab";
import Payment from "./lawyers/Payment";

const styles = {
  active:
    "h-[58px] bg-primaryBlue text-white py-[18px] px-[14px] flex items-center font-[500] cursor-pointer w-full",
  inactive:
    "h-[58px] bg-white text-neurtal-500  py-[18px] px-[14px] flex items-center font-[500] cursor-pointer w-full",
};

const ProfileComponent = ({
  EditComponent,
  getName,
}: {
  EditComponent: any;
  getName: any;
}) => {
  const { userData } = useAppSelector((state) => state.auth);
  const user = userData?.user;
  const [search] = useSearchParams();

  const urlState = search.get("state");
  const [present, setPresent] = useState(urlState ? urlState : "profile");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(resetAll());
    navigate("/login");
  };

  const image =
    user?.profile_image !== null || undefined ? user?.profile_image : noPic;

  return (
    <ContainerLayout>
      <div className="w-full flex md:space-x-4 mt-4">
        <div className="w-1/3 hidden md:flex flex-col">
          <img
            src={image}
            alt=""
            className="rounded-t-[10px] h-[194px] object-cover "
          />
          <p className=" text-xl font-bold text-primaryBlue">
            {user?.first_name + " " + user?.last_name}
          </p>
          <p className="text-lg mt-[10px] text-neutral-500">
            {user?.country + " " + user?.state}
          </p>
          <div className="flex flex-col mt-4">
            <p
              className={
                present === "profile" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("profile")}
            >
              Profile
            </p>
            <p
              className={present === "brief" ? styles.active : styles.inactive}
              onClick={() => setPresent("brief")}
            >
              Shared Brief
            </p>
            <p
              className={
                present === "payment" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("payment")}
            >
              Payment
            </p>
            <p
              className={
                present === "mybrief" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("mybrief")}
            >
              My Brief
            </p>
            <p
              className={
                present === "settings" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("settings")}
            >
              Settings
            </p>
            <p
              className={present === "help" ? styles.active : styles.inactive}
              onClick={() => setPresent("settings")}
            >
              Help
            </p>
            <p
              className={
                present === "freelance" ? styles.active : styles.inactive
              }
              onClick={() => setPresent("settings")}
            >
              Become a Freelance
            </p>
            <p
              className={present === "log" ? styles.active : styles.inactive}
              onClick={handleLogout}
            >
              Logout
            </p>
          </div>
        </div>
        {/* right side */}

        <div className="w-full md:w-2/3">
          <ProfileTab setPresent={setPresent} />
          {present === "profile" && <EditComponent />}
          {present === "brief" && <SharedBrief />}
          {present === "payment" && <Payment />}
          {present === "mybrief" && <MyBriefs />}
          {present === "settings" && <Settings />}
        </div>
      </div>
    </ContainerLayout>
  );
};

export default ProfileComponent;
