import ContainerLayout from "../../Layouts/ContainerLayout";
import { useAppSelector } from "../../app/hooks";
import EmploymentCard from "../HomeComponents/EmploymentCard";
import SingleUser from "./SingleUser";
import pic from "../../assets/png/profile.jpg";
import { convertDateTime } from "../../utils";
const InterviewHome = () => {
  const lawyers = useAppSelector((state) => state.lawyer);
  const job = useAppSelector((state) => state.job);

  let allLawyers = lawyers?.allLawyers ? lawyers?.allLawyers : [];
  let allSent = job?.allSentInterview ? job?.allSentInterview : [];
  return (
    <ContainerLayout>
      <div className="w-full flex flex-col md:flex-row">
        <div className="md:w-1/2 w-full h-full md:min-h-[70vh] flow-hide md:border-r md:border-r-border flex flex-col ">
          <h1 className="mt-4 text-primaryBlue text-2xl font-bold tracking-[0.48px]">
            Interview Invitation
          </h1>
          <p className="text-base text-neutral-500 mt-4 pr-8">
            All interview invitation will be posted view. Once an applicant
            accept the interview invitation, you can generate a meeting link for
            the interview on the interview date.
          </p>
          <div className="flex flex-col space-y-4 pr-4">
            {allSent.map((item: any, index: number) => {
              const { image, accept, createdAt, name } = item;
              return (
                <SingleUser
                  key={index}
                  name={name}
                  img={image}
                  accept={accept}
                  item={item}
                  date={convertDateTime(createdAt).date}
                  time={convertDateTime(createdAt).time}
                />
              );
            })}
          </div>
        </div>
        <div className="md:w-1/2 w-full flex flex-col md:pl-8 mt-8 md:mt-0 flow-hide h-[70vh]">
          <h1 className="font-bold text-primaryBlue text-2xl">Employment</h1>
          <div className="w-full grid grid-cols-2 md:grid-cols-2 gap-x-8 gap-y-[50px] my-6 ">
            {allLawyers?.slice(0, 8)?.map((item: any, index: number) => {
              const {
                area,
                country,
                first_name,
                last_name,
                profile_image,
                year_of_call,
                _id,
                bio,
                state,
              } = item;
              return (
                <EmploymentCard
                  key={index}
                  name={first_name + " " + last_name}
                  sector={area}
                  price={"0"}
                  desc={bio}
                  rate={""}
                  location={
                    country && state ? state + " ," + " " + country : ""
                  }
                  image={profile_image ? profile_image : pic}
                  year={year_of_call}
                  id={_id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ContainerLayout>
  );
};

export default InterviewHome;
