import React, { useState, useEffect } from "react";
import LoginBox from "../../components/LoginBox";
import LoginContainer from "../../Layouts/LoginContainers";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { registerUser } from "../../features/auth/AuthSlice";
import {
  HeaderText,
  Subtitle,
  ModalSub,
  ModalTitle,
} from "../../components/Text";
import { NormalInput, PasswordInput } from "../../components/Input";
import { Btn } from "../../components/Button";
import ReUseModal from "../../components/Modal/ReUseModal";
import { Brief, Message, Security } from "../../assets/svgs";
import { useNavigate } from "react-router-dom";
import { resetAll, saveEmail } from "../../features/auth/AuthSlice";
const Register = () => {
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(resetAll());
  }, []);

  useEffect(() => {
    if (email && password) {
      setActive(true);
    } else setActive(false);
  }, [email, password]);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (active) {
      const data = {
        email: email,
        password: password,
      };
      dispatch(saveEmail(email));

      dispatch(registerUser(data));
    }
  };
  useEffect(() => {
    if (auth?.registerSuccess) {
      // window.alert("Registration Successful ");
      setOpen(true);
      setTimeout(() => {
        navigate(`/otp?email=${email}`);
      }, 2000);
    }
  }, [auth.registerSuccess]);
  if (auth.loading) {
    return (
      <div className="bg-black w-full h-screen flex flex-col items-center justify-center  ">
        <iframe
          src="https://giphy.com/embed/3o7bu3XilJ5BOiSGic"
          width="100"
          height="100"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></iframe>
        <p>
          <a href="https://giphy.com/gifs/youtube-loading-gif-3o7bu3XilJ5BOiSGic">
            via GIPHY
          </a>
        </p>
        <p className="text-white text-xl ">Please wait....</p>
      </div>
    );
  }
  return (
    <LoginContainer>
      <div className="md:w-9/12 w-full mt-28 ">
        <LoginBox>
          <HeaderText text="Your Email" />
          <Subtitle text="Enter your email address for verification" />
          <form onSubmit={handleSubmit}>
            <div className="w-full mt-10 flex flex-col space-y-4">
              <NormalInput
                onChange={(e) => setEmail(e.target.value)}
                name="Email"
                placeholder="Email Address"
                value={email}
              />
              <PasswordInput
                placeholder="Password"
                name="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>

            <button
              className={`mt-[47px] ${
                active ? "bg-primaryBlue" : "bg-primaryBlue opacity-40"
              } text-white w-full h-[52px] flex justify-center items-center font-Circular rounded-[20px] cursor-pointer hover:opacity-70`}
              disabled={!active}
              type="submit"
            >
              Next
            </button>
            <div className="flex w-full items-center space-x-1 my-8">
              <div className="h-[1.5px] flex-grow w-full bg-line mt-1"></div>
              <p className="text-line font-Circular font-normal text-base">
                or
              </p>
              <div className="h-[1.5px] flex-grow bg-line w-full mt-1"></div>
            </div>
            <button
              className={` border border-primaryBlue  text-primaryBlue w-full h-[52px] flex justify-center items-center hover:bg-primaryBlue hover:text-white font-Circular rounded-[20px] cursor-pointer`}
              onClick={() => navigate("/login")}
            >
              Log In
            </button>
          </form>
        </LoginBox>
        <ReUseModal open={open} setOpen={setOpen}>
          <div className="w-full  flex flex-col justify-center">
            <div className="flex justify-center w-full">
              <Message />
            </div>
            <ModalTitle text="Check your email" className="text-center" />
            <ModalSub
              text="We’ve sent an OTP code to your email to reset your password"
              className="text-center mt-1"
            />
            <Btn
              className="mt-10"
              active={true}
              name="Continue"
              onClick={() => navigate(`/otp?email=${email}`)}
            />
          </div>
        </ReUseModal>
      </div>
    </LoginContainer>
  );
};

export default Register;
