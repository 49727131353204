import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { BriefLogo } from "../../../assets/svgs/homesvg";
const MyBriefs = () => {
  const dispatch = useAppDispatch();
  const lawyer = useAppSelector((state) => state.lawyer);
  const allBrief = lawyer?.myBriefs;

  return (
    <div className="w-full flex flex-col bg-white rounded-[10px]">
      <div className="w-full px-4 2xl:px-6 pt-7 flex flex-col pb-1 border-b border-b-border ">
        <h1 className="text-blueText md:text-2xl text-xl font-bold">
          Shared Briefs
        </h1>
      </div>
      <div className="w-full flex flex-col space-y-4 px-6 mt-6 pb-2">
        {allBrief?.length !== 0 &&
          allBrief?.map((item: any, index: number) => {
            return (
              <div className="w-full flex justify-between items-center">
                <div className="w-auto flex items-center space-x-3">
                  <BriefLogo />
                  <div className="flex flex-col">
                    <h1 className="text-primaryBlue md:text-xl text-base font-bold">
                      {item?.name}
                    </h1>
                    <p className="text-neutral-500 md:text-base text-sm">
                      15th March 2023
                    </p>
                  </div>
                </div>
                <div>
                  <a
                    className="text-customOrange underline whitespace-nowrap"
                    download
                    href={item?._path}
                  >
                    View Brief{" "}
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MyBriefs;
