export const InAPPNot = () => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="21" cy="21" r="21" fill="#E3E9F1"/>
<path d="M26.5747 20.6083C26.0997 20.4833 25.5414 20.4167 24.8747 20.4167C23.9497 20.4167 23.6081 20.6417 23.1331 21C23.1081 21.0167 23.0831 21.0417 23.0581 21.0667L22.2664 21.9083C21.5997 22.6083 20.3997 22.6167 19.7331 21.9L18.9414 21.0667C18.9164 21.0417 18.8914 21.0167 18.8664 21C18.3914 20.6417 18.0497 20.4167 17.1247 20.4167C16.4581 20.4167 15.8997 20.4833 15.4247 20.6083C13.4414 21.1417 13.4414 22.7167 13.4414 24.1V24.875C13.4414 26.9667 13.4414 29.3333 17.8997 29.3333H24.0997C27.0581 29.3333 28.5581 27.8333 28.5581 24.875V24.1C28.5581 22.7167 28.5581 21.1417 26.5747 20.6083ZM22.9414 26.3333H19.0581C18.7414 26.3333 18.4831 26.075 18.4831 25.75C18.4831 25.425 18.7414 25.1667 19.0581 25.1667H22.9414C23.2581 25.1667 23.5164 25.425 23.5164 25.75C23.5164 26.075 23.2581 26.3333 22.9414 26.3333Z" fill="#041C40"/>
<path d="M21.5581 13.225C21.5581 12.9167 21.3081 12.6667 20.9997 12.6667C20.6914 12.6667 20.4414 12.9167 20.4414 13.225V14.3333H21.5664V13.225H21.5581Z" fill="#041C40"/>
<path d="M27.0089 18.0167V19.4333C26.9755 19.4167 26.9339 19.4083 26.9005 19.4H26.8922C26.3005 19.2417 25.6422 19.1667 24.8755 19.1667C23.5922 19.1667 22.9839 19.5417 22.4339 19.9583C22.3172 20.0417 22.2339 20.125 22.1505 20.2083L21.3589 21.05C21.2755 21.1333 21.1422 21.1833 21.0005 21.1833C20.9339 21.1833 20.7672 21.175 20.6422 21.0417L19.8255 20.1833C19.7422 20.0917 19.6422 20.0167 19.6172 20C19.0172 19.5417 18.4089 19.1667 17.1255 19.1667C16.3589 19.1667 15.7005 19.2417 15.1005 19.4C15.0672 19.4083 15.0255 19.4167 14.9922 19.4333V18.0167C14.9922 16.2917 14.9922 14.3333 18.6755 14.3333H20.4422V17.2083L19.9005 16.6667C19.6755 16.4417 19.3255 16.4417 19.1005 16.6667C18.8839 16.8833 18.8839 17.2417 19.1005 17.4583L20.6005 18.9583C20.6089 18.9667 20.6172 18.9667 20.6172 18.975C20.6672 19.0167 20.7255 19.0583 20.7839 19.0833C20.8589 19.1083 20.9255 19.125 21.0005 19.125C21.0755 19.125 21.1422 19.1083 21.2172 19.0833C21.2839 19.0583 21.3422 19.0167 21.4005 18.9583L22.9005 17.4583C23.1172 17.2417 23.1172 16.8833 22.9005 16.6667C22.6755 16.4417 22.3255 16.4417 22.1005 16.6667L21.5589 17.2083V14.3333H23.3255C27.0089 14.3333 27.0089 16.2917 27.0089 18.0167Z" fill="#041C40"/>
</svg>

    )
}
export const EmailNot = () => {
    return (
<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="21" cy="21" r="21" fill="#E3E9F1"/>
<path d="M27.6667 16.625C28.9323 16.625 29.9583 15.599 29.9583 14.3333C29.9583 13.0677 28.9323 12.0417 27.6667 12.0417C26.401 12.0417 25.375 13.0677 25.375 14.3333C25.375 15.599 26.401 16.625 27.6667 16.625Z" fill="#041C40"/>
<path d="M26.868 17.7917C25.5596 17.5083 24.493 16.4417 24.2096 15.1333C24.1013 14.625 24.093 14.1333 24.1846 13.6667C24.293 13.15 23.9096 12.6667 23.3763 12.6667H16.8346C14.5346 12.6667 12.668 14.5333 12.668 16.8333V22.625C12.668 24.925 14.5346 26.7917 16.8346 26.7917H18.0846C18.318 26.7917 18.618 26.9417 18.7513 27.125L20.0013 28.7833C20.5513 29.5167 21.4513 29.5167 22.0013 28.7833L23.2513 27.125C23.4096 26.9167 23.6513 26.7917 23.918 26.7917H25.1763C27.4763 26.7917 29.3346 24.9333 29.3346 22.6333V18.625C29.3346 18.1 28.8513 17.7167 28.3346 17.8167C27.868 17.9 27.3763 17.9 26.868 17.7917ZM17.668 21C17.2013 21 16.8346 20.625 16.8346 20.1667C16.8346 19.7083 17.2013 19.3333 17.668 19.3333C18.1263 19.3333 18.5013 19.7083 18.5013 20.1667C18.5013 20.625 18.1346 21 17.668 21ZM21.0013 21C20.5346 21 20.168 20.625 20.168 20.1667C20.168 19.7083 20.5346 19.3333 21.0013 19.3333C21.4596 19.3333 21.8346 19.7083 21.8346 20.1667C21.8346 20.625 21.468 21 21.0013 21ZM24.3346 21C23.868 21 23.5013 20.625 23.5013 20.1667C23.5013 19.7083 23.868 19.3333 24.3346 19.3333C24.793 19.3333 25.168 19.7083 25.168 20.1667C25.168 20.625 24.8013 21 24.3346 21Z" fill="#041C40"/>
</svg>

    )
}
export const PasswordNot = () => {
    return (
<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="21" cy="21" r="21" fill="#E3E9F1"/>
<path d="M27.4912 14.5167C25.0246 12.0583 21.0246 12.0583 18.5746 14.5167C16.8496 16.225 16.3329 18.6833 16.9996 20.85L13.0829 24.7667C12.8079 25.05 12.6162 25.6083 12.6746 26.0083L12.9246 27.825C13.0162 28.425 13.5746 28.9917 14.1746 29.075L15.9912 29.325C16.3912 29.3833 16.9496 29.2 17.2329 28.9083L17.9162 28.225C18.0829 28.0667 18.0829 27.8 17.9162 27.6333L16.2996 26.0167C16.0579 25.775 16.0579 25.375 16.2996 25.1333C16.5412 24.8917 16.9412 24.8917 17.1829 25.1333L18.8079 26.7583C18.9662 26.9167 19.2329 26.9167 19.3912 26.7583L21.1579 25C23.3162 25.675 25.7746 25.15 27.4912 23.4417C29.9496 20.9833 29.9496 16.975 27.4912 14.5167ZM23.0829 21C21.9329 21 20.9996 20.0667 20.9996 18.9167C20.9996 17.7667 21.9329 16.8333 23.0829 16.8333C24.2329 16.8333 25.1662 17.7667 25.1662 18.9167C25.1662 20.0667 24.2329 21 23.0829 21Z" fill="#041C40"/>
</svg>

    )
}
export const PrivacyNot = () => {
    return (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="21" cy="21" r="21" fill="#E3E9F1" />
        <path
          d="M21 11C18.38 11 16.25 13.13 16.25 15.75C16.25 18.32 18.26 20.4 20.88 20.49C20.96 20.48 21.04 20.48 21.1 20.49C21.12 20.49 21.13 20.49 21.15 20.49C21.16 20.49 21.16 20.49 21.17 20.49C23.73 20.4 25.74 18.32 25.75 15.75C25.75 13.13 23.62 11 21 11Z"
          fill="#041C40"
        />
        <path
          d="M26.0809 23.15C23.2909 21.29 18.7409 21.29 15.9309 23.15C14.6609 24 13.9609 25.15 13.9609 26.38C13.9609 27.61 14.6609 28.75 15.9209 29.59C17.3209 30.53 19.1609 31 21.0009 31C22.8409 31 24.6809 30.53 26.0809 29.59C27.3409 28.74 28.0409 27.6 28.0409 26.36C28.0309 25.13 27.3409 23.99 26.0809 23.15Z"
          fill="#041C40"
        />
      </svg>
    );
}