import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
export interface JobsState {
  loading: boolean;
  success: boolean;
  editPrivateSuccess: boolean;
}

const initialState: JobsState = {
  loading: false,
  success: false,
  editPrivateSuccess: false,
};

export const privateSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
      state.editPrivateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPrivate.pending, (state) => {
        state.loading = true;
      })
      .addCase(editPrivate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.editPrivateSuccess = true;
        
      })
      .addCase(editPrivate.rejected, (state, { payload }) => {
        state.loading = false;
        state.editPrivateSuccess = false;
        
      });
   
  },
});

export const editPrivate = createAsyncThunk(
  "editPrivate",
  async (payload: any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.editPrivate}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const privateSelector = (state: any) => state.private;

export const { restoreDefault } = privateSlice.actions;
export default privateSlice.reducer;
