import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import noPic from "../../assets/png/no-pic.png";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileTab({
  setPresent,
}: {
  setPresent: React.Dispatch<React.SetStateAction<string>>;
}) {
  const tabs = [
    { id: "profile", name: "Profile" },
    { id: "brief", name: "Brief" },
    { id: "payment", name: "Payment" },
    { id: "mybrief", name: "My Brief" },
    { id: "settings", name: "Settings" },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].name);

  const { userData } = useAppSelector((state) => state.auth);
  const user = userData?.user || userData?.auth;
  const image =
    user?.profile_image !== null || undefined ? user?.profile_image : noPic;

  return (
    <div className="sm:hidden">
      <img
        src={image}
        alt=""
        className="rounded-t-[10px] h-[194px] object-cover w-full"
      />
      <p className="text-xl font-bold text-primaryBlue">
        {user?.name || user?.first_name + " " + user?.last_name}
      </p>
      <p className="text-lg my-[10px] text-neutral-500">
        {user?.country + " " + user?.state}
      </p>

      <div className="my-8">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <div
              key={tab.name}
              className={classNames(
                tab.name === currentTab
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 whitespace-nowrap"
              )}
              onClick={() => {
                setCurrentTab(tab.name);
                console.log("Changing ..");
                setPresent(tab.id);
              }}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.name === currentTab ? "bg-primaryBlue" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}
