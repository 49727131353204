import React,{useState} from 'react'
import { IFile } from './UploadImage';
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { FaTimesCircle } from "react-icons/fa";
import { storage } from "../../firebase";
import { toast } from "react-toastify";
import { AiOutlineCloudUpload } from 'react-icons/ai';
import clubt from "../../assets/png/club-t.png"
const UploadFile = ({ label, getFileUrl, name,className }: IFile) => {
     const [fileUrl, setFileUrl] = useState("");
     const [progress, setProgress] = useState(0);
     const [loading, setLoading] = useState(false);
     const [selectedFile, setSelectedFile] = useState<
       Blob | MediaSource | any
        >();
    const [filePicked, setFilePicked] = useState<boolean>(false);
     const [preview, setPreview] = useState("");
     const [success, setSuccess] = useState(false);
     const [popo, setPopo] = useState<any>();

     const getFiles = (files: any) => {
       if (!files) return;
       setSelectedFile(files[0]);
    //    const objectUrl = URL?.createObjectURL(files[0]);
       setFilePicked(true);
     };

     const uploadFile = (e: any) => {
       e.preventDefault();
       setLoading(true);
       const storageRef = ref(storage, `/files/${selectedFile.name}`);
       const uploadTask = uploadBytesResumable(storageRef, selectedFile);
       uploadTask.on(
         "state_changed",
         (snapshot) => {
           const prog = Math.round(
             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
           );
           setProgress(prog);
         },
         (err) => {
           setSuccess(false);
           setLoading(false);
         },
         () => {
           getDownloadURL(uploadTask.snapshot.ref).then((url) => {
             setFileUrl(url);
             getFileUrl(url); //whats this for?
               setSuccess(true);
               setFilePicked(false)
             setSelectedFile("");
             setLoading(false);

             toast.success("File Upload Successful");
             // setPreview("");
           });
         }
       );
     };

     const cancel = () => {
         setPreview("");
         setFilePicked(false);
       setLoading(false);
     };

  return (
    <div className={`flex flex-col ${className}`}>
      {filePicked ? (
        <div className="flex flex-col relative h-56 mt-2 border-dashed border-[1px] rounded-md">
          <img
            src={clubt}
            alt="preview Image"
            className="w-full h-full object-cover overflow-hidden"
          />

          {filePicked && success ? null : (
            <div className="flex absolute justify-end items-center space-x-3 top-2 right-6 w-full ">
              <button
                className="flex justify-center items-center bg-lemonGreen text-white px-4 py-2 rounded-md cursor-pointer"
                onClick={uploadFile}
                disabled={loading}
              >
                {loading ? "Uploading..." : "Upload"}
              </button>
              <div
                onClick={cancel}
                className="w-8 h-auto cursor-pointer bg-red-700"
              >
                <FaTimesCircle className="text-white text-3xl" />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex   relative items-center space-x-4  ">
          <div className="w-full  absolute flex justify-center z-10">
            <input
              type="file"
              className="opacity-0 inset-0 z-20 absolute"
              onChange={(e) => getFiles(e.target.files)}
            />
            <span className="flex justify-center space-x-3 items-center border border-primaryBlue  text-primaryBlue px-4 rounded-lg h-[52px] w-full">
                              <p className="text-black">{name ? name : "Upload File"}</p>{" "}
              <AiOutlineCloudUpload />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFile
