import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HomeNav from "../../components/Navbars/HomeNav";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LawyerProfile from "../../components/Profiles/LawyerProfile";
import FirmProfile from "../../components/Profiles/FirmProfile";
import PrivateProfile from "../../components/Profiles/PrivateProfile";
import CorporateProfile from "../../components/Profiles/CorporateProfile";
import LawyerEditProfile from "../../components/Profiles/LawyerEditProfile";
import FirmEditProfile from "../../components/Profiles/Firm/FirmEditProfile";
import CooporateEditProfile from "../../components/Profiles/Cooporate/CooporateEditProfile";
import ProfileComponent from "../../components/Profiles/ProfileComponent";
const Profile = () => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [present, setPresent] = useState<string>("");
  useEffect(() => {
    if (auth?.userData?.user?._type === "lawyer") {
      setPresent("lawyer");
    }
    if (auth?.userData?.user?._type === "firm") {
      setPresent("firm");
    }
    if (auth?.userData?.user?._type === "corporation") {
      setPresent("corporation");
    }
    if (auth?.userData?.user?._type === "private") {
      setPresent("private");
    }
  }, []);
  console.log({ present });
  return (
    <div className="w-full flex flex-col">
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <HomeNav isSearch={false} />
      <div className="w-full bg-greyBg flex flex-col">
        {/* {present === "lawyer" && <LawyerProfile />}
        {present === "firm" && <FirmProfile />}
        {present === "corporation" && <CorporateProfile />}
        {present === "private" && <PrivateProfile />} */}
        <ProfileComponent
          EditComponent={
            present === "lawyer"
              ? LawyerEditProfile
              : present === "firm"
              ? FirmEditProfile
              : present === "corporation"
              ? CooporateEditProfile
              : present === "private"
              ? PrivateProfile
              : () => <div></div>
          }
          getName={"na"}
        />
      </div>
    </div>
  );
};

export default Profile;
