import { useEffect, useState } from "react";
import {
  states,
  companyType,
  countries,
  LGAs,
  practiceArea,
  yearsCall,
} from "../../utils/selectDatas";
import { NormalInput } from "../Input";
import PrimarySelect from "../Selects/PrimarySelect";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Btn } from "../Button";
import { editLawyer } from "../../features/auth/LawyerSlice";
import { toast } from "react-toastify";
import { resetAll } from "../../features/auth/LawyerSlice";
import { locationType } from "../../pages/RegTypes/LawyerReg";

const LawyerEditProfile = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const lawyer = useAppSelector((state) => state.lawyer);
  const { userData } = auth;
  const item = userData?.user;

  const {
    first_name,
    last_name,

    year_of_call,

    middle_name,
    phone_number,
    LGA,
  } = item;
  const [email, setEmail] = useState(item?.email || "");
  const [firstName, setFirstName] = useState(first_name ? first_name : "");
  const [middleName, setMiddleName] = useState(middle_name ? middle_name : "");
  const [lastName, setLastName] = useState(last_name ? last_name : "");
  const [website, setWebsite] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [bio, setBio] = useState(item?.bio);

  const [phone, setPhone] = useState(phone_number);

  const [amount, setAmount] = useState(item?.brief_min_amount);

  const [state, setState] = useState(states[0]);
  const [locals, setLocals] = useState<locationType[]>([]);
  const [local, setLocal] = useState(
    locals?.[0] || {
      name: "",
    }
  );

  const [area, setArea] = useState<any>({});
  const [years, setYears] = useState<any>({});
  const [country, setCountry] = useState<any>({});
  const [brief, setBrief] = useState("");

  useEffect(() => {
    const localsForState = LGAs.filter(
      (lga) => lga.state_id === state.id
    ) as unknown as locationType[];
    setLocal(localsForState[0]);

    setLocals(localsForState);
  }, [state.id]);

  const handleUpdate = () => {
    const data = {
      last_name: lastName,
      first_name: firstName,
      middle_name: middleName,
      area: area.name ? area.name : item?.area,
      bio: bio ? bio : item?.bio,
      phone_number_country_code: "+234",
      phone_number: phone ? phone : phone_number,
      brief_min_amount: amount ? amount : "0",
      brief_min_Currency: "NGN",
      year_of_call: years?.name ? years?.name : year_of_call,

      country: "Nigeria",
      state: state.name ? state.name : item?.state,
      LGA: local?.name ? local.name : LGA,
    };

    dispatch(editLawyer(data));
  };
  useEffect(() => {
    if (lawyer.editLawyerSuccess) {
      toast.success("Profile Update successful");
      setTimeout(() => {
        dispatch(resetAll());
      }, 500);
    }
  }, [lawyer.editLawyerSuccess]);

  return (
    <div className="w-full flex flex-col bg-white rounded-[10px]">
      <div className="w-full px-4 2xl:px-6 pt-7 flex flex-col pb-1 border-b border-b-border">
        <h1 className="text-blueText text-2xl font-bold">Profile</h1>
        <p className="text-neutral-500 md:text-lg text-base  mt-">
          Personal Details
        </p>
      </div>
      <div className="md:w-11/12 grid md:grid-cols-2 grid-cols-2 gap-8 px-4 2xl:px-6 mt-4 pb-10">
        <NormalInput
          onChange={(e) => setFirstName(e.target.value)}
          name="First Name"
          placeholder="First Name"
          value={firstName}
        />
        <NormalInput
          onChange={(e) => setMiddleName(e.target.value)}
          name="Middle Name"
          placeholder="Middle Name"
          value={middleName}
        />
        <NormalInput
          onChange={(e) => setLastName(e.target.value)}
          name="Last Name"
          placeholder="Last Name"
          value={lastName}
        />
        <NormalInput
          onChange={(e) => setEmail(e.target.value)}
          name="Email"
          placeholder="Email Address"
          value={email}
        />

        <div className="flex items-center space-x-4 mt-4">
          <div className="border-border border rounded-md h-[60px] flex   items-center w-2/12 justify-center ">
            <p className="text-base text-customBlack  font-[500]">+234</p>
          </div>
          <div className="w-10/12">
            <NormalInput
              placeholder="Phone Number "
              onChange={(e: any) => setPhone(e.target.value)}
              name="phone"
              value={phone}
            />
          </div>
        </div>
        <div className="flex flex-col"></div>
      </div>
      <div className="w-full px-4 2xl:px-6  flex flex-col pb-1 border-b border-b-border">
        <p className="text-neutral-500 md:text-lg text-base  mt-">
          Work Details
        </p>
      </div>
      <div className="md:w-11/12 grid md:grid-cols-2 grid-cols-2 gap-8 px-4 2xl:px-6 mt-4 ">
        <div className="flex w-full">
          <PrimarySelect
            selected={area}
            setSelected={setArea}
            label="Practice Area"
            data={practiceArea}
            name={item?.area}
          />
        </div>
        <div className="flex w-full">
          <PrimarySelect
            selected={years}
            setSelected={setYears}
            label="Year of Call"
            data={yearsCall}
            name={year_of_call}
          />
        </div>
        <div className="flex w-full">
          <PrimarySelect
            selected={country}
            setSelected={setCountry}
            label="Country"
            data={countries}
            name={item?.country}
          />
        </div>
        <PrimarySelect
          selected={state}
          setSelected={setState}
          label="States"
          data={states}
          name={item?.state}
        />
        <PrimarySelect
          selected={local}
          setSelected={setLocal}
          label="LGA"
          data={LGAs}
          name={item?.LGA}
        />
        <NormalInput
          onChange={(e) => setAddress(e.target.value)}
          name="Address"
          placeholder=" Address"
          value={address}
        />
        <NormalInput
          onChange={(e) => setAmount(e.target.value)}
          name="amount"
          placeholder="Minimum amount for a brief"
          value={amount}
        />
        <NormalInput
          onChange={(e) => setWebsite(e.target.value)}
          name="website"
          placeholder="Website"
          value={website}
        />
      </div>
      <div className="mt-5 md:w-11/12 w-full px-4 2xl:px-6 ">
        <textarea
          name=""
          id=""
          className="w-full border border-border rounded-[10px] h-24 p-4 placeholder:text-placeholder"
          placeholder="Enter Bio"
          value={bio}
          onChange={(e: any) => setBio(e.target.value)}
        ></textarea>
      </div>
      <div className="w-3/12 mx-auto mt-8 mb-8 ">
        <Btn
          onClick={handleUpdate}
          active={true}
          name={lawyer?.loading ? "loading" : "Save"}
        />
      </div>
    </div>
  );
};

export default LawyerEditProfile;
