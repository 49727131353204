import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import APIService from "../../utils/APIServices";
import { url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
export interface JobsState {
  loading: boolean;
  token: string;
  allActiveJobs: any;
  singleJob: any;
    error: boolean;
    errorMsg: string;
    myApplications: any;
    singleApplication: any;
    singleMyJob: any;
    myJobs: any;
  successMsg: string;
  success: boolean;
  createJobSuccess: boolean;
  sendInterviewSuccess: boolean;
  allSentInterview: any;
  sendFeedbackSuccess: boolean;
  allRecievedInterview: any;
  updateInterviewSuccess: boolean;

  
}

const initialState: JobsState = {
  loading: false,
  token: "",
  allActiveJobs: [],
  error: false,
  errorMsg: "",
  myApplications: [],
  singleJob: {},
  singleApplication: {},
  myJobs: [],
  successMsg: "",
  singleMyJob: {},
  success: false,
  createJobSuccess: false,
  allSentInterview: [],
  sendInterviewSuccess: false,
  sendFeedbackSuccess: false,
  allRecievedInterview: [],
  updateInterviewSuccess: false
  

};

export const jobSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreDefault: (state) => {
      state.loading = false;
    },
    resetAllJobState: (state) => {
      state.loading = false;
      state.successMsg = '';
        state.error = false;
      state.errorMsg = '';
      state.success = false;
      state.createJobSuccess = false;
      state.sendInterviewSuccess = false;
      state.sendFeedbackSuccess = false;
      state.updateInterviewSuccess = false;
      state.updateInterviewSuccess = false;
      
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllActiveJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllActiveJob.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allActiveJobs = payload.data;
      })
      .addCase(getAllActiveJob.rejected, (state, { payload }) => {
        state.loading = false;

        state.allActiveJobs = [];
      })
      .addCase(getMyJobs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyJobs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.myJobs = payload.data;
      })
      .addCase(getMyJobs.rejected, (state, { payload }) => {
        state.loading = false;

        state.myJobs = [];
      })
      .addCase(createJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(createJob.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.createJobSuccess = true;
      })
      .addCase(createJob.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.createJobSuccess = false;
      })
      .addCase(deleteJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteJob.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(deleteJob.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.success = false;
      })
      .addCase(getAllMyApplications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMyApplications.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.myApplications = payload.data;
      })
      .addCase(getAllMyApplications.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.success = false;
        state.myApplications = [];
      })
      .addCase(getSingleApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleApplication.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleApplication = payload.data;
      })
      .addCase(getSingleApplication.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.success = false;
        state.singleApplication = {};
      })
      .addCase(getSingleMyJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleMyJob.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleMyJob = payload.data;
      })
      .addCase(getSingleMyJob.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.success = false;
        state.singleMyJob = {};
      })
      .addCase(getSingleJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleJob.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleJob = payload.data;
      })
      .addCase(getSingleJob.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.success = false;
        state.singleJob = {};
      })
      .addCase(manageApplicant.pending, (state) => {
        state.loading = true;
      })
      .addCase(manageApplicant.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(manageApplicant.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.success = false;
        
      })
      .addCase(editJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(editJob.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(editJob.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.success = false;
        
      })
      .addCase(applyForJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(applyForJob.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(applyForJob.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.errorMsg = payload.error
        state.success = false;
        
      })
      .addCase(sendInterview.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendInterview.fulfilled, (state) => {
        state.loading = false;
        state.sendInterviewSuccess = true;
      })
      .addCase(sendInterview.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.sendInterviewSuccess = false;
        
      })
            .addCase(getAllSentInterview.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSentInterview.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allSentInterview = payload?.data;
      })
      .addCase(getAllSentInterview.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.allSentInterview = [];
        
      })
            .addCase(getAllRecievedInterview.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRecievedInterview.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allRecievedInterview = payload?.data;
      })
      .addCase(getAllRecievedInterview.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.allRecievedInterview = [];
        
      })
            .addCase(sendFeedback.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendFeedback.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sendFeedbackSuccess = true;
      })
      .addCase(sendFeedback.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.sendFeedbackSuccess = false;
        
      })
            .addCase(manageInterview.pending, (state) => {
        state.loading = true;
      })
      .addCase(manageInterview.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.updateInterviewSuccess = true;
      })
      .addCase(manageInterview.rejected, (state, { payload }:any) => {
        state.loading = false;
        state.error = true;
        state.updateInterviewSuccess = false;
        
      })
      
      
      ;
    // start off
    // end of session
  },
});

export const getAllActiveJob = createAsyncThunk(
  "getAllActiveJobs",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getJobsList}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getMyJobs = createAsyncThunk(
  "getMyJobs",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.myJobsList}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const createJob = createAsyncThunk(
  "createJob",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.createJob}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const sendInterview = createAsyncThunk(
  "sendInterview",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.sendInterview}`, payload, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getAllSentInterview = createAsyncThunk(
  "getAllSentInterview",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allSentInterview}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const deleteJob = createAsyncThunk(
  "deleteJob",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getJobsList}/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getAllMyApplications = createAsyncThunk(
  "getAllMyApplication",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.myApplications}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getAllRecievedInterview = createAsyncThunk(
  "getAllRecievedInterview",
  async (_, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.allRecievedInterview}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getSingleApplication = createAsyncThunk(
  "getSingleMyApplication",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getSingleApplication}/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getSingleMyJob = createAsyncThunk(
  "getSingleMyJob",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.myJobsList}/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const getSingleJob = createAsyncThunk(
  "getSingleJob",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.get(`${url.getSingleJob}/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const manageApplicant = createAsyncThunk(
  "manageApplicant",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.myJobsList}/${payload.id}/applicant`, payload?.data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const sendFeedback = createAsyncThunk(
  "sendFeedback",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.sendFeeeback}/${payload.id}`, payload?.data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const manageInterview = createAsyncThunk(
  "manageIntereview",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.allRecievedInterview}/${payload.id}`, payload?.data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const editJob= createAsyncThunk(
  "editJob",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.put(`${url.getJobsList}/${payload.id}`, payload?.data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);
export const applyForJob= createAsyncThunk(
  "applyForJob",
  async (payload:any, { rejectWithValue, getState }) => {
    const { auth }: any = getState();

    try {
      const { data } = await APIService.post(`${url.applyJob}/${payload.id}`, payload?.data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      let newError = getSimplifiedError(error);
      return rejectWithValue(newError);
    }
  }
);

export const jobSelector = (state: any) => state.lawyer;

export const { restoreDefault, resetAllJobState } = jobSlice.actions;
export default jobSlice.reducer;
