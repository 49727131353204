import React,{useState} from 'react'
import ContainerLayout from '../../Layouts/ContainerLayout'
import star from "../../assets/svgs/star.svg"
import caller from "../../assets/svgs/caller.svg"
import { Btn, OutlineBtn } from '../Button'
import phone from "../../assets/svgs/phone.svg"
import SideModal from '../Modal/SideModal'
import { BriefCase } from '../../assets/svgs'
import ReUseModal from '../Modal/ReUseModal'
import { ModalTitle, ModalSub } from '../Text'
const LawyerSingleProfile = () => {
  const [present, setPresent] = useState(1);
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      <div className="bg-inherit">
        <ContainerLayout>
          <div className="w-full flex flex-col">
            {/* header session */}
            <div className="w-full  border-b-border  border-b-[1px]   ">
              <div className="w-7/12   flex items-end justify-between  ">
                <p className="text-base text-customBlack font-bold w-fit pb-[9px] border-b-[#041C40] border-b-[3px] ">
                  Overview
                </p>
                <p className="text-base text-neutral-500  w-fit pb-[9px]  ">
                  About/Skills
                </p>
                <p className="text-base text-neutral-500  w-fit pb-[9px]  ">
                  Practice Area
                </p>
                <p className="text-base text-neutral-500  w-fit pb-[9px]  ">
                  Review
                </p>
                <p className="text-base text-neutral-500  w-fit pb-[9px]  ">
                  Contact
                </p>
              </div>
            </div>
            {/* end of  header session */}

            {/* content session */}

            <div className="w-full flex ">
              {/* start of left side */}
              <div className="w-7/12 border-r-[1px] border-r-border h-full pr-[30px] mb-20">
                {/* image session */}
                <div className="w-full flex justify-between items-center mt-7">
                  <div className="flex space-x-4 items-center">
                    <img
                      className="h-24 w-24 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <div className="flex items-center">
                      <p className="text-[22px] font-bold text-[#03132B] mr-[3px]">
                        Richard Duke
                      </p>
                      <img src={star} alt="" />
                      <p className="text-base text-neutral-500">4.5(10)</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <p className="text-base text-neutral-500 mr-[4px]">From</p>
                    <p className="text-[22px] font-bold text-[#03132B]">
                      #150,0000
                    </p>
                  </div>
                </div>

                {/* about session */}
                <div className="flex flex-col mt-10">
                  <p className="text-[22px] font-bold text-[#03132B] mr-[3px]">
                    About/Skills
                  </p>
                  <p className="text-base text-neutral-500 mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi commodo, hendrerit sapien ut enim semper pellue.
                    Pellentesque facilisi ligula mattis arcu. Dictumst adicing
                    sit rhoncus quisque senectus ut mi, nulla purus. Arcu
                    venenatis it proin sit tristique massa.
                  </p>
                  <ul className="flex flex-col list-disc pl-5 accent-neutral-500">
                    <li className="text-base text-neutral-500">
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className="text-base text-neutral-500">
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className="text-base text-neutral-500">
                      Lorem ipsum dolor sit amet.
                    </li>
                  </ul>
                </div>

                {/* pratcice area */}
                <div className="flex flex-col mt-10">
                  <p className="text-[22px] font-bold text-[#03132B] mr-[3px]">
                    Practice Area
                  </p>
                  <p className="text-base text-neutral-500 mt-3">Oil and Gas</p>
                </div>

                {/* reviews session */}
                <div className="w-full flex flex-col mt-10">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <p className="text-[22px] font-bold text-[#03132B] mr-[3px]">
                        Reviews
                      </p>
                      <img src={star} alt="" />
                      <p className="text-base text-neutral-500">4.5(10)</p>
                    </div>
                    <p className="text-[22px] font-bold text-[#03132B] cursor-pointer">
                      See All
                    </p>
                  </div>
                  {/* start of single rating */}
                  <div className="flex space-x-2 items-start mt-4">
                    <img
                      className="h-12 w-12 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <div className="flex flex-col">
                      <div className="flex  ">
                        <p className="mr-1 text-base font-bold text-customBlack">
                          Lanre Samuel
                        </p>
                        <div className="flex ">
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                      </div>
                      <p className="text-base text-neutral-500 mt-1">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Laboriosam, velit? Porro temporibus, soluta fugit
                        ducimus veniam doloremque officia odio, amet harum
                        voluptate accusamus voluptatum ipsum commodi debitis et.
                      </p>
                    </div>
                  </div>
                  {/* end of single rating */}
                  {/* start of single rating */}
                  <div className="flex space-x-2 items-start mt-4">
                    <img
                      className="h-12 w-12 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <div className="flex flex-col">
                      <div className="flex  ">
                        <p className="mr-1 text-base font-bold text-customBlack">
                          Lanre Samuel
                        </p>
                        <div className="flex ">
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                      </div>
                      <p className="text-base text-neutral-500 mt-1">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Laboriosam, velit? Porro temporibus, soluta fugit
                        ducimus veniam doloremque officia odio, amet harum
                        voluptate accusamus voluptatum ipsum commodi debitis et.
                      </p>
                    </div>
                  </div>
                  {/* end of single rating */}
                </div>

                {/* contact session */}
                <div className="w-full flex flex-col mt-10">
                  <p className="text-[22px] font-bold text-[#03132B] mr-[3px]">
                    Contact
                  </p>
                  <div className="flex items-center space-x-3">
                    <img src={caller} alt="sds" className="w-fit h-auto" />
                    <div className="flex flex-col">
                      <p className="text-base font-bold text-[#03132B] mr-[3px]">
                        Richard Duke
                      </p>
                      <p className="text-base text-neutral-500 ">
                        +2348102975467
                      </p>
                    </div>
                  </div>
                </div>
                {present === 1 && (
                  <div className="w-7/12 mx-auto flex flex-col items-center space-y-4 mt-10">
                    <Btn
                      onClick={() => setPresent(2)}
                      active={true}
                      name={"Send Brief"}
                    />
                    <OutlineBtn
                      onClick={function (
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ): void {
                        throw new Error("Function not implemented.");
                      }}
                      active={true}
                      name={"Continue ₦100,00.00"}
                    />
                  </div>
                )}
                {present === 2 && (
                  <div className="w-7/12 mx-auto flex flex-col items-center space-y-4 mt-10">
                    <Btn
                      onClick={() => setOpen(true)}
                      active={true}
                      name={"Invite for Interview"}
                    />
                  </div>
                )}
              </div>
              {/* end of left side */}
              {/* start of right side */}
              <div className="w-5/12 border-b-border border-b-[1px] h-[80px] flex justify-between items-center">
                {/* left side */}
                <div className="flex items-center space-x-2 mt-4 px-5 pb-4">
                  <img
                    className="h-12 w-12 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <div className="flex flex-col">
                    <p className="text-lg text-customBlack font-bold">
                      Richard Duke
                    </p>
                    <div className="flex space-x-1 items-center">
                      <div className="h-[6px] w-[6px] rounded-full bg-[#3F9256]"></div>
                      <p className="text-sm text-neutral-500">Online</p>
                    </div>
                  </div>
                </div>
                {/* left side */}
                <img src={phone} alt="" />
              </div>
              {/* end of right side */}
            </div>
            {/* end of  content session */}
          </div>
        </ContainerLayout>
      </div>
      <SideModal title="Send Brief" open={open} setOpen={setOpen}>
        <div className="flex flex-col relative h-screen">
          <p className="text-grey-100 text-base mt-5">
            Give a brief overview of the brief
          </p>
          <textarea
            name=""
            id=""
            className="border-[#CFCFCF] h-[132px] placeholder:text-placeholder p-3 mt-[30px]"
            placeholder="Write your brief"
          />

          <div className="absolute bottom-8 left-0 right-0 flex flex-col space-y-8">
            <Btn
              name="Apply"
              onClick={() => setOpenModal(true)}
              active={true}
            />
            <div className="flex relative flex-col">
              <input
                type="file"
                name=""
                id=""
                className="opacity-0 cursor-pointer w-full absolute top-0 left-0 right-0 z-10"
              />
              <p className="border border-primaryBlue  text-primaryBlue w-full h-[52px] flex justify-center items-center hover:bg-primaryBlue hover:text-white font-Circular rounded-[10px] ">
                Upload Brief
              </p>
            </div>
          </div>
        </div>
      </SideModal>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full  flex flex-col justify-center">
          <div className="flex justify-center w-full">
            <BriefCase />
          </div>
          <ModalTitle text="Brief Sent" className="text-center" />
          <ModalSub
            text="You’ve successfully sent your brief to the lawyer"
            className="text-center mt-1"
          />
          <Btn
            className="mt-10 cursor-pointer"
            active={true}
            name="Close"
            onClick={() => {
              setOpenModal(false);
              setOpen(false);
            }}
            />
            <p className='opacity-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, eum.</p>
        </div>
      </ReUseModal>
    </>
  );
}

export default LawyerSingleProfile