import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { states, countries } from "../../../utils/selectDatas";
import { Btn } from "../../Button";
import { NormalInput } from "../../Input";
import PrimarySelect from "../../Selects/PrimarySelect";

import { toast } from "react-toastify";
import { editFirmData, restoreDefault } from "../../../features/auth/FirmSlice";
const FirmEditProfile = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const firm = useAppSelector((state) => state.firm);
  const { userData } = auth;
  const item = userData?.user;

  const { phone_number } = item;
  const [email, setEmail] = useState(item?.email || "");
  const [name, setName] = useState(item?.name ? item?.name : "");
  const [address, setAddress] = useState(item?.address ? item?.address : "");

  const [bio, setBio] = useState(item?.bio);

  const [phone, setPhone] = useState(phone_number);

  const [city, setCity] = useState<any>({});

  const [country, setCountry] = useState<any>({});
  useEffect(() => {
    dispatch(restoreDefault());
  }, []);

  const handleUpdate = () => {
    const data = {
      name: name,
      address: address,
      bio: bio ? bio : item?.bio,
      phone_number_country_code: "+234",
      phone_number: phone ? phone : phone_number,
      country: "Nigeria",
      state: city.name ? city.name : item?.state,
    };

    dispatch(editFirmData(data));
  };
  useEffect(() => {
    if (firm.editFirmSuccess) {
      toast.success("Profile Update successful");
      setTimeout(() => {
        dispatch(restoreDefault());
      }, 500);
    }
  }, [firm.editFirmSuccess]);

  return (
    <div className="w-full flex flex-col bg-white rounded-[10px]">
      <div className="w-full px-4 2xl:px-6 pt-7 flex flex-col pb-1 border-b border-b-border">
        <h1 className="text-blueText text-2xl font-bold">Profile</h1>
      </div>
      <div className="w-11/12 grid grid-cols-2 gap-8 px-4 2xl:px-6 mt-4 pb-10">
        <NormalInput
          onChange={(e) => setName(e.target.value)}
          name="First Name"
          placeholder="First Name"
          value={name}
        />
        <NormalInput
          onChange={(e) => setAddress(e.target.value)}
          name="Address"
          placeholder="Address"
          value={address}
        />

        <NormalInput
          onChange={(e) => setEmail(e.target.value)}
          name="Email"
          placeholder="Email Address"
          value={email}
        />
      </div>
      <div className="flex items-center space-x-4 pl-5  mb-4 w-1/2">
        <div className="border-border border rounded-md h-[60px] flex   items-center w-2/12 justify-center ">
          <p className="text-base text-customBlack  font-[500]">+234</p>
        </div>
        <div className="w-10/12">
          <NormalInput
            placeholder="Phone Number "
            onChange={(e: any) => setPhone(e.target.value)}
            name="phone"
            value={phone}
          />
        </div>
      </div>
      <div className="w-full px-4 2xl:px-6  flex flex-col pb-1 border-b border-b-border">
        <p className="text-neutral-500 text-lg  mt-">Work Details</p>
      </div>
      <div className="w-11/12 grid grid-cols-2 gap-8 px-4 2xl:px-6 mt-4 ">
        <div className="flex w-full">
          <PrimarySelect
            selected={country}
            setSelected={setCountry}
            label="Country"
            data={countries}
            name={item?.country}
          />
        </div>
        <PrimarySelect
          selected={city}
          setSelected={setCity}
          label="States"
          data={states}
          name={item?.state}
        />
      </div>
      <div className="mt-5 w-11/12 px-4 2xl:px-6 ">
        <textarea
          name=""
          id=""
          className="w-full border border-border rounded-[10px] h-24 p-4 placeholder:text-placeholder"
          placeholder="Enter Bio"
          value={bio}
          onChange={(e: any) => setBio(e.target.value)}
        ></textarea>
      </div>
      <div className="w-3/12 mx-auto mt-8 mb-8 ">
        <Btn
          onClick={handleUpdate}
          active={true}
          name={firm?.loading ? "loading" : "Save"}
        />
      </div>
    </div>
  );
};

export default FirmEditProfile;
