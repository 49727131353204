import React, { useEffect } from "react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import { Jobs, Lawploy } from "../../assets/svgs/homesvg";
import HomeNav from "../../components/Navbars/HomeNav";
import Industry from "../../components/HomeComponents/Industry";
import profiles from "../../assets/png/profile.jpg";
import { BriefData } from "../../utils/datas";
import Briefs from "../../components/HomeComponents/Briefs";
import Employments from "../../components/HomeComponents/Employments";
import JobsSection from "../../components/HomeComponents/Jobs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
const DemoHome = () => {
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const { userData } = auth;
  useEffect(() => {
    if (userData?.user?._type === "firm") {
      navigate("/home/firm");
    } else if (userData?.user?._type === "lawyer") {
      navigate("/home/lawyer");
    } else if (userData?.user?._type === "corporation") {
      navigate("/home/corporate");
    } else if (userData?.user?._type === "private") {
      navigate("/home/private");
    }
  }, [userData]);

  return (
    <div className="w-full flex flex-col">
      <HomeNav isSearch={false} />
      <div className="w-full bg-greyBg flex flex-col">
        <Briefs />
        <Employments />
        <JobsSection />
      </div>
    </div>
  );
};

export default DemoHome;
