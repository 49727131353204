import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContainerLayout from "../../Layouts/ContainerLayout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import noPic from "../../assets/png/no-pic.png";
import { BriefCase } from "../../assets/svgs";
import caller from "../../assets/svgs/caller.svg";
import phone from "../../assets/svgs/phone.svg";
import star from "../../assets/svgs/star.svg";
import { Btn, OutlineBtn } from "../Button";
import ReUseModal from "../Modal/ReUseModal";
import SideModal from "../Modal/SideModal";
import { ModalSub, ModalTitle, TopicText } from "../Text";
import UploadFile from "../Upload/UploadFile";
import { NormalInput } from "../Input";
import { createBrief, resetAll } from "../../features/auth/LawyerSlice";
import UploadPhoto from "../Upload/UploadImage";
import PrimarySelect from "../Selects/PrimarySelect";
import { countries, positions } from "../../utils/selectDatas";
import { stateArray } from "../../utils/nigeriaStates";
import { resetAllJobState, sendInterview } from "../../features/auth/JobSlice";
import { toast } from "react-toastify";
import {
  FlutterWaveButton,
  closePaymentModal,
  useFlutterwave,
} from "flutterwave-react-v3";
import {
  FlutterWaveResponse,
  FlutterwaveConfig,
} from "flutterwave-react-v3/dist/types";
import ChatService from "../../utils/apis/chats.service";
import { convertDateTime } from "../../utils";
import {
  ChatMessages,
  ChatType,
  MessageType,
} from "../../pages/Message/MessagePage";
const BriefProfilePage = () => {
  const newId = useParams();
  const id = newId?.id;

  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [brief, setBrief] = useState("");

  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();
  const job = useAppSelector((state) => state.job);
  const lawyer = useAppSelector((state) => state.lawyer);
  const [fileUrl, setFileUrl] = useState<string>("");
  const auth = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (id && fileUrl && brief && name) {
      setActive(true);
    } else setActive(false);
  }, [id, fileUrl, name, brief]);
  const userInfo = lawyer?.singleLawyer;
  const image =
    userInfo?.profile_image !== null || undefined
      ? userInfo?.profile_image
      : noPic;
  let userType = auth?.userData?.user?._type?.toUpperCase();

  const {
    LGA,
    area,
    brief_min_Currency,
    brief_min_amount,
    email,
    year_of_call,
    _auth,
    first_name,
    phone_number_country_code,
    phone_number,
    state,
    last_name,
  } = userInfo;
  const [amount, setAmount] = useState<number>(brief_min_amount);

  const handleSendBrief = () => {
    const data = {
      name: name, // If Provide then URL is Required (Not required)
      _path: fileUrl, // If Provide then name Is Required (Not Required)
      brief: brief, // Not required
      _receiver: _auth,
    };
    dispatch(createBrief(data));
  };
  useEffect(() => {
    if (lawyer?.success) {
      setOpen(false);
      setOpenModal(true);

      setTimeout(() => {
        dispatch(resetAll());
      }, 3000);
    } else setOpenModal(false);
  }, [lawyer?.success]);

  const [desc, setDesc] = useState("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [resp, setResp] = useState("");
  const [position, setPosition] = useState<any>({});
  const [website, setWebsite] = useState("");
  const [country, setCountry] = useState<any>({});
  const [city, setCity] = useState<any>({});
  const [date, setDate] = useState("");

  const [title, setTitle] = useState("");
  const jobs = useAppSelector((state) => state.job);
  const handleCreate = () => {
    const data = {
      name: name,
      job_title: title,
      description: desc,
      position_type: position?.name ? position?.name : "",
      responsibilities: resp,
      website: website,
      country: "Nigeria",
      state: city?.name ? city?.name : "Lagos",
      image: imageUrl ? imageUrl : image,
      recevier: _auth,
      date: date,
    };
    if (name && desc && date) {
      dispatch(sendInterview(data));
    } else {
      toast.error("Please fill in required items");
    }
  };
  const [openChat, setOpenChat] = useState(false);

  useEffect(() => {
    if (jobs?.sendInterviewSuccess) {
      dispatch(resetAllJobState());
      setOpen(false);
      setOpenModal(true);
      setTimeout(() => {
        setOpenModal(false);
      }, 3000);
    }
  }, [jobs?.sendInterviewSuccess]);
  useEffect(() => {
    if (title && desc && name && date) {
      setActive(true);
    } else setActive(false);
  }, [name, date, desc, title]);
  const config: FlutterwaveConfig = {
    public_key: "FLWPUBK_TEST-ae9bdd6c2623314033efbfee042d90d5-X",
    tx_ref: Date.now().toString(),
    amount: amount,
    currency: brief_min_Currency,
    payment_options: "card,mobilemoney",
    customer: {
      email: email,
      phone_number: phone_number,
      name: first_name + " " + last_name,
    },

    customizations: {
      title: brief,
      description: "Payment for brief",
      logo: "https://assets.piedpiper.com/logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);
  function handlePayment() {
    handleFlutterPayment({
      callback: (response: FlutterWaveResponse) => {
        console.log(response);
        setOpenPaymentModal(false);
        toast.success("Payment Successful");
      },
      onClose: () => {},
    });
  }
  const [chat, setChat] = useState({} as ChatType);

  React.useEffect(() => {
    if (_auth && auth.token)
      (async () => {
        const chats = await ChatService.getChats({
          token: auth.token,
        });
        let chat = chats.find((c: any) => c.user._auth === _auth);
        if (!chat)
          chat = await ChatService.createChat({
            id: _auth,
            token: auth.token,
          });
        setChat(chat);
      })();
  }, [auth.token, _auth]);

  return (
    <>
      <div className="bg-inherit">
        <ContainerLayout>
          <div className="w-full flex flex-col">
            {/* header session */}
            <div className="w-full  border-b-border  border-b-[1px]   ">
              <div className="w-full my-4 md:my-0 md:w-7/12   flex items-end justify-between  ">
                <p className="text-base text-customBlack font-bold w-fit pb-[9px] border-b-[#041C40] border-b-[3px] ">
                  Overview
                </p>
                <p className="text-base text-neutral-500  w-fit pb-[9px]  ">
                  About/Skills
                </p>
                <p className="text-base text-neutral-500  w-fit pb-[9px]  ">
                  Practice Area
                </p>
                <p className="text-base text-neutral-500  w-fit pb-[9px]  ">
                  Review
                </p>
                <p className="text-base text-neutral-500  w-fit pb-[9px]  ">
                  Contact
                </p>
              </div>
            </div>
            {/* end of  header session */}

            {/* content session */}

            <div className="w-full grid grid-cols-2 ">
              {/* start of left side */}
              <div className="w-full md:border-r-[1px] h-[calc(100vh-50px)] overflow-scroll md:border-r-border  md:pr-[30px] flex-1">
                {/* image session */}
                <div className="w-full flex flex-col md:flex-row md:justify-between md:items-center mt-7">
                  <div className="flex space-x-4 items-center">
                    <img
                      className="h-24 w-24 rounded-full"
                      src={image}
                      alt={first_name}
                    />
                    <div className="flex items-center w-full md:flex-shrink-0 md:space-x-2">
                      <p className="text-[22px] font-bold text-[#03132B] mr-[3px] capitalize">
                        {first_name + " " + last_name}
                      </p>
                      <img src={star} alt="" />
                      <p className="text-base text-neutral-500">4.5(10)</p>
                    </div>
                  </div>
                  <div className="flex items-center w-full justify-end ">
                    <p className="text-base text-neutral-500 mr-[4px] pr-1">
                      From
                    </p>
                    <p className="text-[22px] font-bold text-[#03132B]">
                      {brief_min_Currency + " " + brief_min_amount}
                    </p>
                  </div>
                </div>
                {/* about session */}
                <div className="flex flex-col mt-10">
                  <div className="w-full flex flex-col space-y-3 mt-4">
                    {/* start */}
                    <div className="flex items-center justify-between">
                      <div className="w-4/12 text-neutral-500 capitalize ">
                        Full Name
                      </div>
                      <div className="w-7/12 text-neutral-600 font-semibold capitalize ">
                        {last_name + " " + first_name}
                      </div>
                    </div>
                    {/* end */}
                    {/* start */}
                    <div className="flex items-center justify-between">
                      <div className="w-4/12 text-neutral-500 capitalize ">
                        Email
                      </div>
                      <div className="w-7/12 text-neutral-600 font-semibold capitalize ">
                        {email}
                      </div>
                    </div>
                    {/* end */}
                    {/* start */}
                    <div className="flex items-center justify-between">
                      <div className="w-4/12 text-neutral-500 capitalize ">
                        Year of Call
                      </div>
                      <div className="w-7/12 text-neutral-600 font-semibold capitalize ">
                        {year_of_call}
                      </div>
                    </div>
                    {/* end */}
                    {/* start */}
                    <div className="flex items-center justify-between">
                      <div className="w-4/12 text-neutral-500 capitalize ">
                        Phone number
                      </div>
                      <div className="w-7/12 text-neutral-600 font-semibold capitalize ">
                        {phone_number_country_code + "" + phone_number}
                      </div>
                    </div>
                    {/* end */}
                    {/* start */}
                    <div className="flex items-center justify-between">
                      <div className="w-4/12 text-neutral-500 capitalize ">
                        Country
                      </div>
                      <div className="w-7/12 text-neutral-600 font-semibold capitalize ">
                        {userInfo?.country}
                      </div>
                    </div>
                    {/* end */}
                    {/* start */}
                    <div className="flex items-center justify-between">
                      <div className="w-4/12 text-neutral-500 capitalize ">
                        State
                      </div>
                      <div className="w-7/12 text-neutral-600 font-semibold capitalize ">
                        {state}
                      </div>
                    </div>
                    {/* end */}
                  </div>
                </div>
                {/* About/Skill */}
                <div className="flex flex-col mt-10">
                  <p className="text-[22px] font-bold text-[#03132B] mr-[3px]">
                    About/Skills
                  </p>
                  <p className="text-base text-neutral-500 mt-3 capitalize">
                    {userInfo?.bio}
                  </p>
                </div>
                {/* pratcice area */}
                <div className="flex flex-col mt-10">
                  <p className="text-[22px] font-bold text-[#03132B] mr-[3px]">
                    Practice Area
                  </p>
                  <p className="text-base text-neutral-500 mt-3 capitalize">
                    {area}
                  </p>
                </div>
                <div className="mt-8 sm:hidden  flex justify-end cursor-pointer">
                  <div
                    className="flex items-center space-x-2 w-fit px-4 py-2"
                    style={{
                      borderRadius: "20px",
                      background: "#FFF",
                      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.06)",
                    }}
                    onClick={() => setOpenChat(true)}
                  >
                    <img src="/messages-3.svg" />
                    <p className="text-[22px] font-bold text-[#03132B] cursor-pointer">
                      Chat
                    </p>
                  </div>
                </div>
                {/* reviews session */}

                {/* contact session */}
                <div className="w-full flex flex-col mt-10">
                  <p className="text-[22px] font-bold text-[#03132B] mr-[3px]">
                    Contact
                  </p>
                  <div className="flex items-center space-x-3">
                    <img src={caller} alt="sds" className="w-fit h-auto" />
                    <div className="flex flex-col">
                      <p className="text-base font-bold text-[#03132B] mr-[3px] capitalize">
                        {first_name + " " + last_name}
                      </p>
                      <p className="text-base text-neutral-500 ">
                        {phone_number_country_code + "" + phone_number}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-7/12 mx-auto flex flex-col items-center space-y-4 mt-10">
                  <Btn
                    onClick={() => setOpen(true)}
                    active={true}
                    name={
                      userType !== "CORPORATION"
                        ? "Send Brief"
                        : "Invite for Interview"
                    }
                  />
                  <OutlineBtn
                    onClick={() => {
                      setOpenPaymentModal(true);
                      setAmount(brief_min_amount);
                    }}
                    active={true}
                    className="bg-transparent border-[1px] border-[#041C40] text-[#041C40]"
                    name={"Continue To Make Payment"}
                  />
                </div>
              </div>
              {/* end of left side */}
              {/* start of right side */}
              {/* CHat Section */}
              <ChatMessages selectedChat={chat} receiverAuthId={_auth} />

              {/* end of right side */}
            </div>
            {/* end of  content session */}
          </div>
        </ContainerLayout>
      </div>
      {userType !== "CORPORATION" ? (
        <SideModal title="Send Brief" open={open} setOpen={setOpen}>
          <div className="flex flex-col relative h-screen">
            <div className="flex items-center  justify-center">
              <img
                src={image}
                alt="brief"
                className="w-[98px] h-[98px] rounded-full object-cover "
              />
            </div>
            <div className="flex flex-col mt-7 px-2">
              <TopicText text=" Brief Details" className="mb-[30px]" />
              <NormalInput
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Name of Brief"
                name="Name"
              />
              <div className="mt-8">
                <textarea
                  onChange={(e: any) => setBrief(e.target.value)}
                  className="h-32 w-full p-4 rounded-[10px] border-border border placeholder:text-placeholder text-base placeholder:text-base "
                  placeholder="write brief"
                />
              </div>
            </div>
            <div className="flex flex-col mt-7"></div>

            <div className="block py-6">
              <UploadFile
                getFileUrl={setFileUrl}
                className="mt-4 "
                name="Upload Brief"
              />
            </div>
            <div className="mt-10 pb-10">
              <Btn
                name={lawyer.loading ? "Loading" : "Share Brief"}
                onClick={handleSendBrief}
                active={active && lawyer?.loading === false ? true : false}
              />
            </div>
          </div>
        </SideModal>
      ) : (
        <SideModal open={open} setOpen={setOpen} title={"Interview Invitation"}>
          <div className="w-full flex flex-col ">
            <p className="text-center text-lg text-grey-100">
              Share the details of the company and interview with the applicants
            </p>
            <div className="w-full flex justify-center mt-7">
              <span className="inline-block relative">
                <img
                  className="h-[119px] w-[119px] rounded-full cursor-pointer"
                  src={imageUrl ? imageUrl : image}
                  onClick={() => setOpenUpload(true)}
                  alt=""
                />
                <span className="absolute bottom-0 right-0 block h-4 w-4 rounded-full ring-2 ring-white bg-[#D3A518]" />
              </span>
            </div>
            <div className="w-full flex flex-col px-4 mt-[30px] space-y-5">
              <NormalInput
                onChange={(e: any) => setName(e.target.value)}
                name="Name of the Company"
                placeholder="Name of the Company"
                value={name}
              />
              <NormalInput
                onChange={(e: any) => setTitle(e.target.value)}
                name="Job Title"
                placeholder="Job Title"
                value={title}
              />
              <div
                className={` border border-border rounded-[10px] px-3 py-2 shadow-sm  h-[132px] flex flex-col justify-center bg-white ${
                  desc ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
                } `}
              >
                <label
                  htmlFor="name"
                  className="block text-xs font-medium text-[#363636]"
                >
                  {desc ? "Description" : ""}
                </label>
                <textarea
                  name="name"
                  autoComplete="off"
                  onChange={(e: any) => setDesc(e.target.value)}
                  placeholder="Job Description"
                  id="name"
                  className={`block w-full border-0 outline-none focus:outline-none p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base bg-inherit h-full  `}
                />
              </div>
              <div
                className={` border border-border rounded-[10px] px-3 py-2 shadow-sm  h-[132px] flex flex-col justify-center bg-white ${
                  resp ? "border-[#041C40] border-[2px]" : "border-[#cfcfcf]"
                } `}
              >
                <label
                  htmlFor="name"
                  className="block text-xs font-medium text-[#363636]"
                >
                  {resp ? "Responsibilities" : ""}
                </label>
                <textarea
                  name="name"
                  autoComplete="off"
                  onChange={(e: any) => setResp(e.target.value)}
                  placeholder="Responsibilities"
                  id="name"
                  className={`block w-full border-0 outline-none focus:outline-none p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base bg-inherit h-full  `}
                />
              </div>
              <div className="flex w-full">
                <PrimarySelect
                  selected={position}
                  setSelected={setPosition}
                  label="What type of position"
                  data={positions}
                />
              </div>
              <NormalInput
                onChange={(e: any) => setWebsite(e.target.value)}
                name="Company’s website"
                placeholder="Company’s website"
                value={website}
              />
              <div className="flex space-x-6">
                <PrimarySelect
                  selected={country}
                  setSelected={setCountry}
                  label="Country"
                  data={countries}
                />
                <PrimarySelect
                  selected={city}
                  setSelected={setCity}
                  label="State"
                  data={stateArray}
                />
              </div>
              <div className="flex space-x-6 item-center">
                <input
                  type="datetime-local"
                  onChange={(e: any) => setDate(e.target.value)}
                  className="outline-none h-[30px] rounded-[5px] border border-border w-[230px] px-2 "
                />
              </div>
            </div>
            <Btn
              name={jobs.loading ? "Loading..." : "Create"}
              onClick={handleCreate}
              active={active}
              className="mt-[128px] mb-6"
            />
            <ReUseModal open={openUpload} setOpen={setOpenUpload}>
              <div className="w-full  flex flex-col justify-center">
                <ModalTitle text="Upload Image " className="text-center" />
                <div className="flex  w-full mt-6 ">
                  <UploadPhoto getFileUrl={setImageUrl} label="upload" />
                </div>
                <div className="flex mt-5 w-5/12">
                  <Btn
                    name="Cancel Upload"
                    onClick={() => setOpenUpload(false)}
                    active
                  />
                </div>
              </div>
            </ReUseModal>
          </div>
        </SideModal>
      )}
      {/* Chat Modal */}
      <SideModal title="Chat" open={openChat} setOpen={setOpenChat}>
        <div className="md:hidden flex flex-col h-full  flex-1 justify-between">
          {/* left side */}
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2 mt-4 px-5 pb-4">
              <img className="h-12 w-12 rounded-full" src={image} alt="" />
              <div className="flex flex-col">
                <p className="text-lg text-customBlack font-bold">
                  {" "}
                  {first_name + " " + last_name}
                </p>
                <div className="flex space-x-1 items-center">
                  <div className="h-[6px] w-[6px] rounded-full bg-[#3F9256]"></div>
                  <p className="text-sm text-neutral-500">Online</p>
                </div>
              </div>
            </div>
            {/* left side */}
            <img src={phone} alt="" />
          </div>
          <div className="my-4 px-4">
            <textarea
              className="w-full h-[100px] p-4 rounded-[10px]"
              placeholder="Type a message"
            ></textarea>
            <div className="flex justify-between">
              <div className="flex space-x-6 mt-2">
                <button>
                  <img src="/link-2.svg" alt="" />
                </button>
                <button>
                  <img src="/camera.svg" alt="" />
                </button>
                <button>
                  <img src="/microphone-2.svg" alt="" />
                </button>
              </div>
              <div>
                <Btn
                  // onClick={() => setOpenChat(true)}
                  active={true}
                  name={"Send"}
                  className="px-6"
                />
              </div>
            </div>
          </div>
        </div>
      </SideModal>
      <ReUseModal open={openModal} setOpen={setOpenModal}>
        <div className="w-full  flex flex-col justify-center">
          <div className="flex justify-center w-full">
            <BriefCase />
          </div>
          <ModalTitle text="Brief Sent Successfully" className="text-center" />
          <ModalSub
            text="Brief has been sent successfully you will be notified if there is a new update"
            className="text-center mt-1"
          />
          <Btn
            className="mt-10 cursor-pointer"
            active={true}
            name="Continue"
            onClick={() => setOpenModal(false)}
          />
        </div>
      </ReUseModal>
      <ReUseModal open={openPaymentModal} setOpen={setOpenPaymentModal}>
        <div className="space-y-10 py-5">
          <div>
            <NormalInput
              onChange={(e: any) => setAmount(e.target.value)}
              name="amount"
              placeholder="Amount"
              value={amount}
            />
            {Number(amount) < brief_min_amount && (
              <small className="text-red-600">
                You can't input amount below {brief_min_amount}
              </small>
            )}
          </div>
          <Btn
            name="Proceed to Payment"
            onClick={() => {
              if (Number(amount) >= brief_min_amount) handlePayment();
            }}
            active={Number(amount) >= brief_min_amount}
          />
        </div>
      </ReUseModal>
    </>
  );
};

export default BriefProfilePage;
