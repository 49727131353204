import { useEffect, useState } from "react";
import NavLayout from "../../Layouts/NavLayout";
import {
  Beriefs,
  Jobs,
  Lawploy,
  Message,
  NoMessageIcon,
  NotificationIcon,
  ReadMessageIcon,
  SearchIcon,
} from "../../assets/svgs/homesvg";
import NoPic from "../../assets/png/no-pic.png";
import { InterviewLogo } from "../../assets/svgs/homesvg";
import CustomProfile from "./CustomProfile";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { searchLawyer } from "../../features/auth/LawyerSlice";
import ContainerLayout from "../../Layouts/ContainerLayout";

import {
  states,
  LGAs,
  companyType,
  practiceArea,
  yearsCall,
  countries,
} from "../../utils/selectDatas";
import { stateArray } from "../../utils/nigeriaStates";
import PrimarySelect from "../Selects/PrimarySelect";
import SearchEmployments from "../HomeComponents/SearchEmployment";
import { useNavigate } from "react-router-dom";
import { locationType } from "../../pages/RegTypes/LawyerReg";
import MobileNav from "./MobileNav";
import ReUseModal from "../Modal/ReUseModal";
const datas = [
  "Oil and Gas",
  "Finance",
  "Alternative Dispute Resoultion (ARD)",
  "Litigation",
  "Cooperate",
  "Oil and Gas",
];
const HomeNav = ({
  isSearch,
  hidePractice,
}: {
  isSearch: boolean;
  hidePractice?: boolean;
}) => {
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const lawyer = useAppSelector((state) => state.lawyer);
  const general = useAppSelector((state) => state.general);
  const searchResult = lawyer?.allSearchResult;

  const [state, setState] = useState(states[0]);
  const [locals, setLocals] = useState<locationType[]>([]);
  const [local, setLocal] = useState(
    locals?.[0] || {
      name: "",
    }
  );

  const [industry, setIndustry] = useState<any>("");
  const [area, setArea] = useState<any>({});
  const [years, setYears] = useState<any>({});
  const [amount, setAmount] = useState("all");
  const filterYear = years?.name ? `&year=${years?.name}` : "";
  const filterState = state?.name ? `&state=${state?.name}` : "";
  const filterLga = local?.name ? `&lga=${local?.name}` : "";
  const filterArea = area?.name ? `&specialization=${area?.name}` : "";

  useEffect(() => {
    const localsForState = LGAs.filter(
      (lga) => lga.state_id === state.id
    ) as unknown as locationType[];
    setLocal(localsForState[0]);

    setLocals(localsForState);
  }, [state.id]);

  const searchTerms = `?country=Nigeria&keyword=${
    search && search
  }${filterYear}${filterState}${filterLga}${filterArea}`;
  const handleSearch = () => {
    dispatch(searchLawyer(searchTerms));
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
      if (isSearch !== true) {
        navigate("/search");
      }
    }
  };

  useEffect(() => {
    if (area || state || industry || years) {
      handleSearch();
    }
  }, [area, state, industry, years]);

  return (
    <div className="flex flex-col">
      <div className="w-full h-full relative ">
        {/* Mobile Navigation */}
        <MobileNav />
        <NavLayout>
          <div className="w-full flex items-center min-h-[122px] h-full cursor-pointer  ">
            <div className="w-2/12" onClick={() => navigate("/home")}>
              <Lawploy />
            </div>
            <div className="w-4/12">
              <div className="border border-placeholder rounded-md h-[62px] flex items-center px-4 space-x-2">
                <SearchIcon />
                <input
                  type="text"
                  className="bg-inherit placeholder:text-placeholder text-lg flex-grow border-none outline-none "
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
              </div>
            </div>
            <div className="w-5/12  flex justify-center items-center space-x-12">
              <div
                className="flex flex-col items-center cursor-pointer relative"
                onClick={() => navigate("/messages")}
              >
                <Message />
                <p className="text-placeholder">Message</p>
              </div>
              <div
                className="flex flex-col items-center"
                onClick={() => navigate("/profile?state=mybrief")}
              >
                <Beriefs />
                <p className="text-placeholder">Brief</p>
              </div>
              <div
                className="flex flex-col items-center"
                onClick={() => navigate("/job")}
              >
                <Jobs />
                <p className="text-placeholder">Jobs</p>
              </div>
              <div
                className="flex flex-col items-center"
                onClick={() => navigate("/notification")}
              >
                <NotificationIcon />
                <p className="text-placeholder">Notifications</p>
              </div>
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={() => navigate("/interview")}
              >
                <InterviewLogo />
                <p className="text-placeholder">Interview</p>
              </div>
            </div>
            <div className="w-1/12 flex justify-center ">
              <CustomProfile />
            </div>
          </div>
        </NavLayout>
      </div>
      {/* Practice Type */}
      {!hidePractice && (
        <div className="w-full bg-greyBg hidden md:block">
          <ContainerLayout>
            <div className="w-full flex justify-between items-center h-[122px] border-[#cfcfcf] border-b-[2px] mb-[27px] bg-greyBg  ">
              {datas.map((item: string, index: number) => {
                return (
                  <div
                    className="flex items-center space-x-[49px] "
                    key={index}
                  >
                    <p className="text-xl text-blueText ">{item}</p>
                    <div className="w-[8px] h-[8px] bg-customOrange rounded-full" />
                  </div>
                );
              })}
            </div>
            {isSearch && searchResult?.length !== 0 && (
              <div className="flex flex-col mt-4 min-h-[70vh]">
                {search && (
                  <h1 className=" text-neutral-700 text-[32px] font-bold ">
                    Result for {`"${search}"`}
                  </h1>
                )}

                <div className="w-11/12 2xl:w-10/12 grid grid-cols-4 gap-x-8 ">
                  <PrimarySelect
                    selected={area}
                    setSelected={setArea}
                    label="Practice Area"
                    name="Practice Area"
                    data={practiceArea}
                  />
                  <PrimarySelect
                    selected={years}
                    setSelected={setYears}
                    label="Year of Call"
                    name="Year of Call"
                    data={yearsCall}
                  />
                  <PrimarySelect
                    selected={state}
                    setSelected={setState}
                    label="States"
                    name="States"
                    data={stateArray}
                  />
                  <PrimarySelect
                    selected={local}
                    setSelected={setLocal}
                    label="LGA"
                    name="LGA"
                    data={LGAs}
                  />
                </div>
                <div className="flex items-center space-x-8 mt-8">
                  <div
                    className="flex space-x-4 items-center "
                    onClick={() => setAmount("all")}
                  >
                    <input
                      type="radio"
                      checked={amount === "all"}
                      className="w-4 h-4"
                    />
                    <p className="text-neutral-700 text-xl font-bold">All</p>
                  </div>
                  <div
                    className="flex space-x-4 items-center "
                    onClick={() => setAmount("brief")}
                  >
                    <input
                      type="radio"
                      checked={amount === "brief"}
                      className="w-4 h-4"
                    />
                    <p className="text-neutral-700 text-xl font-bold">
                      Holding Brief
                    </p>
                  </div>
                  <div
                    className="flex space-x-4 items-center "
                    onClick={() => setAmount("employment")}
                  >
                    <input
                      type="radio"
                      checked={amount === "employment"}
                      className="w-4 h-4"
                    />
                    <p className="text-neutral-700 text-xl font-bold">
                      Employment
                    </p>
                  </div>
                </div>
                <div className="w-full mt-4">
                  <SearchEmployments />
                </div>
              </div>
            )}
            {isSearch && searchResult?.length === 0 && (
              <div>
                <h1>No match found</h1>
              </div>
            )}
          </ContainerLayout>
        </div>
      )}
    </div>
  );
};

export default HomeNav;
// const MessageDropDown = ({
//   isMessageDropdownOpen,
//   messageData,
// }: {
//   isMessageDropdownOpen: boolean;
//   messageData: {
//     name: string;
//     text: string;
//     isDelivered: boolean;
//     unReadCount: number;
//   }[];
// }) => {
//   const navigate = useNavigate();

//   return (
//     <Dropdown isOpen={isMessageDropdownOpen}>
//       <div
//         className="w-96  bg-white rounded-lg  border border-[#CFCFCF] relative"
//         style={{
//           boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
//         }}
//       >
//         <div className="flex items-center w-full border-b-2 border-[#CFCFCF] py-3 px-2 space-x-3 [font-family:'Cabinet_Grotesk-Medium',Helvetica] font-medium">
//           <Message />

//           <div className="[font-family:'Cabinet_Grotesk-Medium',Helvetica] font-medium text-neutral neutral-700 text-[18px] tracking-[0.36px] leading-[20px] whitespace-nowrap">
//             Messages ({messageData.length})
//           </div>
//         </div>
//         {/* Body */}
//         <div className="min-h-[400px]">
//           {messageData.length < 1 ? (
//             <div className="w-full h-[400px] items-center flex flex-col justify-center">
//               <NoMessageIcon />
//               <p>No messages</p>
//             </div>
//           ) : (
//             <div className="px-2 py-4 space-y-4">
//               {messageData.map((message, id) => (
//                 <div key={id} className="flex items-center justify-between ">
//                   <div className=" flex items-center ">
//                     <img
//                       src={NoPic}
//                       alt=""
//                       className="w-[42px] h-[42px] mr-3"
//                     />
//                     <div className="flex flex-col space-y-2">
//                       <p>{message.name}</p>

//                       <p className="flex items-center space-x-2">
//                         {message.isDelivered ? (
//                           <span>
//                             <ReadMessageIcon />
//                           </span>
//                         ) : (
//                           ""
//                         )}
//                         <span className="line-clamp-1">{message.text}</span>
//                       </p>
//                     </div>
//                   </div>

//                   <div className="flex flex-col space-y-[10px] item-center">
//                     <p>Today</p>
//                     {message.unReadCount > 0 && (
//                       <div className="flex items-center justify-center w-[18px] h-[18px]  rounded-full bg-[#D3A518]">
//                         <span className="text-[12px] leading-[18px] text-white font-medium">
//                           {message.unReadCount}
//                         </span>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//         <div className="w-full border-t-2 border-[#CFCFCF] py-6 flex justify-end px-4">
//           {messageData.length ? (
//             <p onClick={() => navigate("/messages")}>See All in Message</p>
//           ) : (
//             ""
//           )}
//         </div>
//       </div>
//     </Dropdown>
//   );
// };

export const Dropdown = ({
  children,
  isOpen,
}: {
  children: React.ReactNode;
  isOpen: boolean;
}) => {
  if (!isOpen) return null;

  return (
    <div className="absolute top-full left-0 mt-2 w-56 rounded-md shadow-lg bg-white z-10">
      <div
        className="py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        {children}
      </div>
    </div>
  );
};
