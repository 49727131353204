import React from "react";
import Caller from "./caller.svg";
interface svgProps {
  width?: string;
  height?: string;
}
export const Brief = ({ width, height }: svgProps) => {
  return (
    <svg
      width={width ? width : "142"}
      height={height ? height : "142"}
      viewBox="0 0 142 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_409_3596)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.3107 42.0787V42.0682C48.3107 32.7153 55.8928 25.1332 65.2457 25.1332H77.5621C86.9151 25.1332 94.4972 32.7153 94.4972 42.0682V42.0787C103.1 42.1414 107.811 42.582 110.904 45.6756C114.512 49.283 114.512 55.089 114.512 66.701V79.0174H28.2969V66.701C28.2969 55.089 28.2969 49.283 31.9043 45.6756C34.9978 42.5821 39.7083 42.1414 48.3107 42.0787ZM57.548 42.0682C57.548 37.8169 60.9944 34.3705 65.2457 34.3705H77.5621C81.8135 34.3705 85.2599 37.8169 85.2599 42.0682L57.548 42.0682Z"
          fill="url(#paint0_linear_409_3596)"
        />
      </g>
      <g filter="url(#filter1_b_409_3596)">
        <path
          d="M28.2969 79.0175C28.2969 67.4055 28.2969 61.5995 31.9043 57.9921C31.9043 57.9921 31.9043 57.9921 31.9043 57.9921C35.5117 54.3847 41.3177 54.3847 52.9296 54.3847H89.8789C101.491 54.3847 107.297 54.3847 110.904 57.9921C110.904 57.9921 110.904 57.9921 110.904 57.9921C114.512 61.5995 114.512 67.4055 114.512 79.0175C114.512 96.4355 114.512 105.144 109.101 110.556C109.101 110.556 109.101 110.556 109.101 110.556C103.689 115.967 94.9805 115.967 77.5625 115.967H65.246C47.828 115.967 39.119 115.967 33.708 110.556C33.708 110.556 33.708 110.556 33.708 110.556C28.2969 105.144 28.2969 96.4355 28.2969 79.0175Z"
          fill="#F0E1B2"
          fill-opacity="0.7"
        />
        <path
          d="M33.708 110.556L33.7081 110.555L33.708 110.556ZM33.708 110.556L33.708 110.556L33.708 110.556ZM110.694 58.2004C110.695 58.2007 110.695 58.201 110.695 58.2013L108.889 110.349C108.889 110.349 108.889 110.349 108.889 110.349C108.887 110.35 108.886 110.351 108.885 110.353C106.245 112.99 102.789 114.326 97.7947 114.997C92.7881 115.67 86.2798 115.671 77.5625 115.671H65.246C56.5287 115.671 50.0204 115.67 45.0138 114.997C40.0157 114.325 36.5586 112.988 33.9171 110.346C31.2757 107.705 29.9384 104.248 29.2665 99.2497C28.5933 94.2431 28.5927 87.7348 28.5927 79.0175C28.5927 73.2031 28.5933 68.8721 29.041 65.5425C29.4875 62.2213 30.3739 59.9408 32.1134 58.2013L32.1134 58.2013L32.1134 58.2013L32.1134 58.2013L32.1135 58.2013L31.9043 57.9921L32.1135 58.2013C33.853 56.4617 36.1335 55.5753 39.4546 55.1288C42.7843 54.6812 47.1153 54.6805 52.9296 54.6805H89.8789C95.6932 54.6805 100.024 54.6812 103.354 55.1288C106.674 55.5753 108.955 56.4614 110.694 58.2004ZM108.925 110.312L108.894 110.344C108.921 110.317 108.949 110.301 108.968 110.291L108.925 110.312ZM108.805 110.556C108.805 110.558 108.804 110.553 108.805 110.544V110.556Z"
          stroke="url(#paint1_linear_409_3596)"
          stroke-width="0.591667"
        />
      </g>
      <g filter="url(#filter2_b_409_3596)">
        <rect
          x="84.0156"
          y="66.2664"
          width="5.91667"
          height="24.85"
          rx="2.95833"
          transform="rotate(90 84.0156 66.2664)"
          fill="url(#paint2_linear_409_3596)"
          fill-opacity="0.9"
        />
        <rect
          x="83.779"
          y="66.5031"
          width="5.44333"
          height="24.3767"
          rx="2.72167"
          transform="rotate(90 83.779 66.5031)"
          stroke="url(#paint3_linear_409_3596)"
          stroke-width="0.473333"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_409_3596"
          x="15.9805"
          y="12.8168"
          width="110.848"
          height="78.517"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.1582" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_409_3596"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_409_3596"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_409_3596"
          x="15.9805"
          y="42.0683"
          width="110.848"
          height="86.2147"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.1582" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_409_3596"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_409_3596"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_b_409_3596"
          x="46.2987"
          y="53.401"
          width="50.5824"
          height="31.6475"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.4327" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_409_3596"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_409_3596"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_409_3596"
          x1="53.1665"
          y1="36.9204"
          x2="109.95"
          y2="68.1643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDE79" />
          <stop offset="1" stop-color="#D3A518" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_409_3596"
          x1="22.1387"
          y1="59.983"
          x2="123.488"
          y2="133.244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_409_3596"
          x1="94.3678"
          y1="63.3334"
          x2="90.5399"
          y2="87.551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.979167" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_409_3596"
          x1="86.974"
          y1="66.2664"
          x2="86.974"
          y2="91.1164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Message = ({ width, height }: svgProps) => {
  return (
    <svg
      width={width ? width : "142"}
      height={height ? height : "142"}
      viewBox="0 0 142 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_409_2146)">
        <path
          d="M115.624 65.2564C115.624 55.9147 115.624 51.2439 113.03 47.8045C110.435 44.3651 105.944 43.0819 96.962 40.5156L81.1983 36.0117C75.9313 34.5068 73.2979 33.7544 70.5951 33.7544C67.8923 33.7544 65.2588 34.5068 59.9919 36.0117L44.2282 40.5156C35.2459 43.0819 30.7548 44.3651 28.1605 47.8045C25.5661 51.2439 25.5661 55.9147 25.5661 65.2564V78.0111C25.5661 96.2055 25.5661 105.303 31.2184 110.955C36.8707 116.607 45.9679 116.607 64.1624 116.607H77.0278C95.2222 116.607 104.319 116.607 109.972 110.955C115.624 105.303 115.624 96.2055 115.624 78.0111V65.2564Z"
          fill="url(#paint0_linear_409_2146)"
        />
      </g>
      <g filter="url(#filter1_b_409_2146)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.5423 20.1166C104.871 20.1166 109.191 26.078 109.191 33.4318V62.5723C90.2941 76.9078 80.3477 90.8049 70.5952 90.876C60.6361 90.9487 50.8792 76.8948 31.9989 62.5723V33.4318C31.9989 26.078 36.319 20.1166 41.648 20.1166H99.5423Z"
          fill="#F6EDD1"
        />
        <path
          d="M108.896 33.4318C108.896 29.8104 107.831 26.5439 106.126 24.1901C104.42 21.836 102.088 20.4124 99.5423 20.4124H41.648C39.1019 20.4124 36.7706 21.836 35.0646 24.1901C33.3589 26.5439 32.2948 29.8104 32.2948 33.4318V62.4254C39.0527 67.5618 44.6481 72.666 49.491 77.0838C51.3795 78.8064 53.1535 80.4247 54.8374 81.8998C57.8566 84.5446 60.5827 86.7268 63.1677 88.2432C65.7513 89.7587 68.1753 90.5979 70.593 90.5802C72.9598 90.563 75.3581 89.7069 77.9322 88.1849C80.5072 86.6623 83.2388 84.4852 86.2699 81.8501C87.9708 80.3714 89.7649 78.7493 91.6756 77.0218C96.5348 72.6285 102.148 67.5538 108.896 62.4254V33.4318Z"
          stroke="url(#paint1_linear_409_2146)"
          stroke-width="0.591667"
        />
      </g>
      <rect
        x="0.236667"
        y="0.236667"
        width="5.95937"
        height="38.1229"
        rx="2.97969"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 51.2969 39.415)"
        fill="url(#paint2_linear_409_2146)"
        fill-opacity="0.9"
        stroke="url(#paint3_linear_409_2146)"
        stroke-width="0.473333"
      />
      <rect
        x="0.236667"
        y="0.236667"
        width="5.95937"
        height="38.1229"
        rx="2.97969"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 51.2969 58.7134)"
        fill="url(#paint4_linear_409_2146)"
        fill-opacity="0.9"
        stroke="url(#paint5_linear_409_2146)"
        stroke-width="0.473333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.098 53.794C25.7362 55.9019 25.6205 58.4563 25.5834 61.6642L68.5667 96.5882C69.7484 97.5483 71.4414 97.5483 72.6231 96.5882L115.606 61.6642C115.569 58.4563 115.454 55.9019 115.092 53.7939L70.5949 89.9477L26.098 53.794Z"
        fill="url(#paint6_linear_409_2146)"
      />
      <defs>
        <filter
          id="filter0_b_409_2146"
          x="12.701"
          y="20.889"
          width="115.788"
          height="108.584"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.4327" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_409_2146"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_409_2146"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_409_2146"
          x="19.1336"
          y="7.25117"
          width="102.923"
          height="96.4906"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.4327" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_409_2146"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_409_2146"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_409_2146"
          x1="89.6458"
          y1="51.2752"
          x2="21.6933"
          y2="76.441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDE79" />
          <stop offset="1" stop-color="#D3A518" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_409_2146"
          x1="67.3788"
          y1="9.50266"
          x2="68.7289"
          y2="76.697"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.996024" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_409_2146"
          x1="3.58255"
          y1="44.6809"
          x2="3.58255"
          y2="-7.1288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_409_2146"
          x1="-12.7898"
          y1="51.6527"
          x2="15.0185"
          y2="-7.51399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_409_2146"
          x1="-2.28339"
          y1="38.9243"
          x2="3.47324"
          y2="-7.76843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_409_2146"
          x1="-9.01291"
          y1="41.0027"
          x2="13.4704"
          y2="0.769338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_409_2146"
          x1="89.6383"
          y1="63.3127"
          x2="38.3535"
          y2="99.7989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D3A518" />
          <stop offset="1" stop-color="#D3A518" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Security = ({ width, height }: svgProps) => {
  return (
    <svg
      width={width ? width : "142"}
      height={height ? height : "142"}
      viewBox="0 0 142 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_409_2617)">
        <path
          d="M74.4493 94.0722C82.8804 90.4307 105.978 76.8504 107.066 37.0552C107.115 35.2449 106.171 33.534 104.633 32.5772C84.2885 19.9182 58.5184 19.9182 38.1736 32.5772C36.636 33.534 35.6915 35.2449 35.741 37.0552C36.8292 76.8504 59.9265 90.4307 68.3576 94.0722C69.5681 94.595 70.1733 94.8565 71.4034 94.8565C72.6336 94.8565 73.2388 94.595 74.4493 94.0722Z"
          fill="url(#paint0_linear_409_2617)"
        />
      </g>
      <g filter="url(#filter1_b_409_2617)">
        <path
          d="M35.9781 47.4605L32.7501 49.3051C30.8961 50.3645 29.7384 52.3639 29.8088 54.4981C31.3288 100.562 62.8933 113.672 69.9944 116.043C70.9284 116.355 71.8808 116.355 72.8148 116.043C79.9159 113.672 111.48 100.562 113 54.4981C113.071 52.3639 111.913 50.3645 110.059 49.3051L106.831 47.4605C84.8791 34.9165 57.9301 34.9165 35.9781 47.4605Z"
          fill="#F0E1B2"
        />
        <path
          d="M32.8969 49.5619L36.1248 47.7174C57.986 35.2253 84.8233 35.2253 106.684 47.7174L109.912 49.5619C111.675 50.5689 112.771 52.4677 112.705 54.4883C111.947 77.4464 103.706 92.1705 94.7873 101.45C85.8628 110.736 76.2444 114.586 72.7211 115.762C71.8479 116.054 70.9613 116.054 70.0881 115.762C66.5648 114.586 56.9465 110.736 48.022 101.45C39.1033 92.1705 30.862 77.4464 30.1044 54.4883C30.0378 52.4677 31.1346 50.5689 32.8969 49.5619Z"
          stroke="url(#paint1_linear_409_2617)"
          stroke-width="0.591667"
        />
      </g>
      <path
        d="M68.2626 80.5765C68.307 80.6209 68.3672 80.6458 68.4299 80.6458C68.4927 80.6458 68.5529 80.6209 68.5973 80.5765L86.268 62.9059C87.9184 61.2555 90.5942 61.2555 92.2446 62.9059C93.895 64.5563 93.895 67.2321 92.2446 68.8825L71.4183 89.7088C70.6257 90.5014 69.5508 90.9466 68.4299 90.9466C67.3091 90.9466 66.2342 90.5014 65.4416 89.7088L53.5409 77.8081C51.8905 76.1577 51.8905 73.4818 53.5409 71.8314C55.1913 70.181 57.8671 70.181 59.5175 71.8314L68.2626 80.5765Z"
        fill="url(#paint2_linear_409_2617)"
        fill-opacity="0.9"
        stroke="url(#paint3_linear_409_2617)"
        stroke-width="0.473333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_b_409_2617"
          x="22.8729"
          y="10.2176"
          width="97.0609"
          height="97.5043"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.4327" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_409_2617"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_409_2617"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_409_2617"
          x="17.9059"
          y="26.1517"
          width="106.999"
          height="102.026"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.95038" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_409_2617"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_409_2617"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_409_2617"
          x1="56.2986"
          y1="30.1239"
          x2="112.668"
          y2="46.7397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDE79" />
          <stop offset="1" stop-color="#D3A518" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_409_2617"
          x1="31.3584"
          y1="46.1501"
          x2="134.633"
          y2="122.309"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.734375" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_409_2617"
          x1="58.5756"
          y1="72.775"
          x2="123.659"
          y2="80.4667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_409_2617"
          x1="53.2506"
          y1="65.675"
          x2="79.8756"
          y2="74.55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ProfileChat = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0H5C2.24 0 0 2.23 0 4.98V10.96V11.96C0 14.71 2.24 16.94 5 16.94H6.5C6.77 16.94 7.13 17.12 7.3 17.34L8.8 19.33C9.46 20.21 10.54 20.21 11.2 19.33L12.7 17.34C12.89 17.09 13.19 16.94 13.5 16.94H15C17.76 16.94 20 14.71 20 11.96V4.98C20 2.23 17.76 0 15 0ZM6 10C5.44 10 5 9.55 5 9C5 8.45 5.45 8 6 8C6.55 8 7 8.45 7 9C7 9.55 6.56 10 6 10ZM10 10C9.44 10 9 9.55 9 9C9 8.45 9.45 8 10 8C10.55 8 11 8.45 11 9C11 9.55 10.56 10 10 10ZM14 10C13.44 10 13 9.55 13 9C13 8.45 13.45 8 14 8C14.55 8 15 8.45 15 9C15 9.55 14.56 10 14 10Z"
        fill="#D3A518"
      />
    </svg>
  );
};

export const BriefCase = () => {
  return (
    <svg
      width="142"
      height="142"
      viewBox="0 0 142 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_918_4467)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.3107 42.0787V42.0682C48.3107 32.7153 55.8928 25.1332 65.2457 25.1332H77.5621C86.9151 25.1332 94.4972 32.7153 94.4972 42.0682V42.0787C103.1 42.1414 107.811 42.582 110.904 45.6756C114.512 49.283 114.512 55.089 114.512 66.701V79.0174H28.2969V66.701C28.2969 55.089 28.2969 49.283 31.9043 45.6756C34.9978 42.5821 39.7083 42.1414 48.3107 42.0787ZM57.548 42.0682C57.548 37.8169 60.9944 34.3705 65.2457 34.3705H77.5621C81.8135 34.3705 85.2599 37.8169 85.2599 42.0682L57.548 42.0682Z"
          fill="url(#paint0_linear_918_4467)"
        />
      </g>
      <g filter="url(#filter1_b_918_4467)">
        <path
          d="M28.2969 79.0175C28.2969 67.4055 28.2969 61.5995 31.9043 57.9921C31.9043 57.9921 31.9043 57.9921 31.9043 57.9921C35.5117 54.3847 41.3177 54.3847 52.9296 54.3847H89.8789C101.491 54.3847 107.297 54.3847 110.904 57.9921C110.904 57.9921 110.904 57.9921 110.904 57.9921C114.512 61.5995 114.512 67.4055 114.512 79.0175C114.512 96.4355 114.512 105.144 109.101 110.556C109.101 110.556 109.101 110.556 109.101 110.556C103.689 115.967 94.9805 115.967 77.5625 115.967H65.246C47.828 115.967 39.119 115.967 33.708 110.556C33.708 110.556 33.708 110.556 33.708 110.556C28.2969 105.144 28.2969 96.4355 28.2969 79.0175Z"
          fill="#F0E1B2"
          fill-opacity="0.7"
        />
        <path
          d="M33.708 110.556L33.7081 110.555L33.708 110.556ZM33.708 110.556L33.708 110.556L33.708 110.556ZM110.694 58.2004C110.695 58.2007 110.695 58.201 110.695 58.2013L108.889 110.349C108.889 110.349 108.889 110.349 108.889 110.349C108.887 110.35 108.886 110.351 108.885 110.353C106.245 112.99 102.789 114.326 97.7947 114.997C92.7881 115.67 86.2798 115.671 77.5625 115.671H65.246C56.5287 115.671 50.0204 115.67 45.0138 114.997C40.0157 114.325 36.5586 112.988 33.9171 110.346C31.2757 107.705 29.9384 104.248 29.2665 99.2497C28.5933 94.2431 28.5927 87.7348 28.5927 79.0175C28.5927 73.2031 28.5933 68.8721 29.041 65.5425C29.4875 62.2213 30.3739 59.9408 32.1134 58.2013L32.1134 58.2013L32.1134 58.2013L32.1134 58.2013L32.1135 58.2013L31.9043 57.9921L32.1135 58.2013C33.853 56.4617 36.1335 55.5753 39.4546 55.1288C42.7843 54.6812 47.1153 54.6805 52.9296 54.6805H89.8789C95.6932 54.6805 100.024 54.6812 103.354 55.1288C106.674 55.5753 108.955 56.4614 110.694 58.2004ZM108.925 110.312L108.894 110.344C108.921 110.317 108.949 110.301 108.968 110.291L108.925 110.312ZM108.805 110.556C108.805 110.558 108.804 110.553 108.805 110.544V110.556Z"
          stroke="url(#paint1_linear_918_4467)"
          stroke-width="0.591667"
        />
      </g>
      <g filter="url(#filter2_b_918_4467)">
        <rect
          x="84.0156"
          y="66.2664"
          width="5.91667"
          height="24.85"
          rx="2.95833"
          transform="rotate(90 84.0156 66.2664)"
          fill="url(#paint2_linear_918_4467)"
          fill-opacity="0.9"
        />
        <rect
          x="83.779"
          y="66.5031"
          width="5.44333"
          height="24.3767"
          rx="2.72167"
          transform="rotate(90 83.779 66.5031)"
          stroke="url(#paint3_linear_918_4467)"
          stroke-width="0.473333"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_918_4467"
          x="15.9805"
          y="12.8168"
          width="110.848"
          height="78.517"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.1582" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_918_4467"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_918_4467"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_918_4467"
          x="15.9805"
          y="42.0683"
          width="110.848"
          height="86.2147"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.1582" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_918_4467"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_918_4467"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_b_918_4467"
          x="46.2987"
          y="53.401"
          width="50.5824"
          height="31.6475"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.4327" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_918_4467"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_918_4467"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_918_4467"
          x1="53.1665"
          y1="36.9204"
          x2="109.95"
          y2="68.1643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDE79" />
          <stop offset="1" stop-color="#D3A518" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_918_4467"
          x1="22.1387"
          y1="59.983"
          x2="123.488"
          y2="133.244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_918_4467"
          x1="94.3678"
          y1="63.3334"
          x2="90.5399"
          y2="87.551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.979167" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_918_4467"
          x1="86.974"
          y1="66.2664"
          x2="86.974"
          y2="91.1164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CallIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/linear/call">
      <g id="call">
        <g id="call_2">
          <path
            id="Vector"
            d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
            stroke="#041C40"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
        </g>
      </g>
    </g>
  </svg>
);
