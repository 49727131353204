import stateDate from "./sans_state.json";
import lgaDate from "./sans_lga.json";
export const states = stateDate;

export const LGAs = lgaDate;

export const companyType = [
  { id: 1, name: "Consulting, IT, or business services" },
  { id: 2, name: "Design, marketing or communication" },
  { id: 3, name: "Travel, accommodation, or transport" },
  { id: 4, name: "Health, sports or personal care" },
  { id: 5, name: "Food, beverages, or tobacco" },
  { id: 6, name: "Retail, wholesale or manufacturing " },
  { id: 7, name: "Financial services, or holding companies" },
  { id: 8, name: "Real estate or construction" },
  { id: 9, name: "Education" },
  { id: 10, name: "Utilities and public or  government services" },
];
export const practiceArea = [
  { id: 1, name: "Administrative law" },
  { id: 2, name: "Admiralty law or maritime law" },
  { id: 3, name: "Adoption law" },
  { id: 4, name: "Alternative dispute resolution" },
  { id: 5, name: "Animal law" },
  { id: 6, name: "Antitrust law (or competition law)" },
  { id: 7, name: "Art law (or art and culture law)" },
  { id: 8, name: "Aviation law" },
  { id: 9, name: "Banking law" },
  {
    id: 10,
    name: "Bankruptcy law (creditor debtor rights law or insolvency and reorganization law)",
  },
  { id: 11, name: "Business law (or commercial law); commercial litigation" },
  { id: 12, name: "Business organizations law (or companies law)" },
  { id: 13, name: "Civil law or common law" },
  { id: 14, name: "Class action litigation/Mass tort litigation" },
  { id: 15, name: "Communications law" },
  { id: 16, name: "Computer law" },
  { id: 17, name: "Competition law" },
  { id: 18, name: "Constitutional law" },
  { id: 19, name: "Construction law" },
  { id: 20, name: "Consumer law" },
  { id: 21, name: "Contract law" },
  { id: 22, name: "Copyright law" },
  { id: 23, name: "Corporate compliance law corporate governance law" },
  { id: 24, name: "Criminal law" },
  { id: 25, name: "Custom (law)" },
  { id: 26, name: "Cyber law" },
  { id: 27, name: "Defamation law" },
  { id: 28, name: "Drug control law" },
  { id: 29, name: "Education law" },
  { id: 30, name: "Employment law" },
  { id: 31, name: "Energy law" },
  { id: 32, name: "Entertainment law" },
  { id: 33, name: "Environmental law" },
  { id: 34, name: "Family law" },
  { id: 35, name: "Financial services regulation law" },
  { id: 36, name: "Firearm law" },
  { id: 37, name: "Food law" },
  { id: 38, name: "Gaming law" },
  { id: 39, name: "Health and safety law" },
  { id: 40, name: "Housing law" },
  { id: 41, name: "Immigration law" },
  { id: 42, name: "Insurance law" },
  { id: 43, name: "Intellectual property law" },
  { id: 44, name: "International law" },
  { id: 45, name: "International human rights law" },
  { id: 46, name: "Finance law" },
  { id: 47, name: "Labour law" },
  { id: 48, name: "Landlord–tenant law" },
  { id: 49, name: "Litigation" },
  { id: 50, name: "Media law" },
  { id: 51, name: "Medical law" },
  { id: 52, name: "Military law" },
  { id: 53, name: "Mining law" },
  { id: 54, name: "Mortgage law" },
  { id: 55, name: "Nationality law" },
  { id: 56, name: "Patent law" },
  { id: 57, name: "Property law" },
  { id: 58, name: "Public health law" },
  { id: 59, name: "Securities law / Capital markets law" },
  { id: 60, name: "Space law" },
  { id: 61, name: "Sports law" },
  { id: 62, name: "Tax law" },
  { id: 63, name: "Trademark law" },
  { id: 64, name: "Transport law / Transportation law" },
  { id: 65, name: "Water law" },
];

export const countries = [
  { id: 1, name: "Nigeria" },
  { id: 2, name: "Ghana" },
  { id: 3, name: "Togo" },
  { id: 4, name: "Chad" },
];
export const positions = [
  { id: 1, name: "Part-Time Role" },
  { id: 2, name: "Contract Role" },
  { id: 3, name: "Full-Time Role" },
];
function generateYears() {
  const currentYear = new Date().getFullYear();
  const years = [];
  let id = 1;

  for (let year = currentYear; year >= 1970; year--) {
    years.push({ id: id++, name: year.toString() });
  }

  return years;
}

export const yearsCall = generateYears();
