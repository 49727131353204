import React, { useEffect } from "react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import { Jobs, Lawploy } from "../../assets/svgs/homesvg";
import HomeNav from "../../components/Navbars/HomeNav";
import Industry from "../../components/HomeComponents/Industry";
import profiles from "../../assets/png/profile.jpg";
import { BriefData } from "../../utils/datas";
import Briefs from "../../components/HomeComponents/Briefs";
import Employments from "../../components/HomeComponents/Employments";
import JobsSection from "../../components/HomeComponents/Jobs";
import { Helmet } from "react-helmet";
import { useAppDispatch } from "../../app/hooks";
import {
  getAllActiveJob,
  getAllMyApplications,
} from "../../features/auth/JobSlice";
import {
  getAllLawyers,
  getAllLawyersFirm,
  getAllMyBrief,
  sharedBrief,
} from "../../features/auth/LawyerSlice";
import { getAllNotifications } from "../../features/auth/GeneralSlice";
const FirmHome = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllLawyersFirm());
    dispatch(getAllActiveJob());
    dispatch(getAllMyBrief());
    dispatch(sharedBrief());
    dispatch(getAllMyApplications());
    dispatch(getAllNotifications());
  }, []);
  return (
    <div className="w-full flex flex-col">
      <Helmet>
        <title>FIRM</title>
      </Helmet>
      <HomeNav isSearch={false} />
      <div className="w-full bg-greyBg flex flex-col">
        <Briefs />
        <Employments />
        <JobsSection />
      </div>
    </div>
  );
};

export default FirmHome;
