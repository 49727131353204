import { useEffect, useState } from "react";
import {
  states,
  companyType,
  countries,
  LGAs,
  practiceArea,
  yearsCall,
} from "../../../utils/selectDatas";
import { NormalInput } from "../../Input";
import PrimarySelect from "../../Selects/PrimarySelect";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Btn } from "../../Button";
import { editLawyer } from "../../../features/auth/LawyerSlice";
import { toast } from "react-toastify";
import { restoreDefault } from "../../../features/auth/CorporateSlice";
import { editCorporate } from "../../../features/auth/CorporateSlice";
const CooporateEditProfile = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const corporate = useAppSelector((state) => state.corporate);
  const { userData } = auth;
  const item = userData?.user;

  const {
    first_name,
    last_name,

    year_of_call,

    middle_name,
    phone_number,
    LGA,
  } = item;
  const [email, setEmail] = useState(item?.email || "");
  const [name, setName] = useState(item?.name ? item?.name : "");
  const [address, setAddress] = useState(item?.address ? item?.address : "");
  const [lastName, setLastName] = useState(last_name ? last_name : "");
  const [website, setWebsite] = useState("");

  const [bio, setBio] = useState(item?.bio);

  const [phone, setPhone] = useState(phone_number);

  const [amount, setAmount] = useState(item?.brief_min_amount);

  const [city, setCity] = useState<any>({});

  const [area, setArea] = useState<any>({});
  const [years, setYears] = useState<any>({});
  const [country, setCountry] = useState<any>({});
  const [brief, setBrief] = useState("");

  const handleUpdate = () => {
    const data = {
      name: name,
      address: address,
      area: area.name ? area.name : item?.area,
      bio: bio ? bio : item?.bio,
      phone_number_country_code: "+234",
      phone_number: phone ? phone : phone_number,
      brief_min_amount: amount ? amount : "0",
      brief_min_Currency: "NGN",
      year_of_call: years?.name ? years?.name : year_of_call,

      country: "Nigeria",
      state: city.name ? city.name : item?.state,
    };

    dispatch(editCorporate(data));
  };
  useEffect(() => {
    if (corporate.editCorporateSuccess) {
      toast.success("Profile Update successful");
      setTimeout(() => {
        dispatch(restoreDefault());
      }, 500);
    }
  }, [corporate.editCorporateSuccess]);

  return (
    <div className="w-full flex flex-col bg-white rounded-[10px]">
      <div className="w-full px-4 2xl:px-6 pt-7 flex flex-col pb-1 border-b border-b-border">
        <h1 className="text-blueText text-2xl font-bold">Profile</h1>
      </div>
      <div className="w-full md:w-11/12 grid md:grid-cols-2 grid-cols-2 gap-8 px-4 2xl:px-6 mt-4 pb-10">
        <NormalInput
          onChange={(e) => setName(e.target.value)}
          name="First Name"
          placeholder="First Name"
          value={name}
        />
        <NormalInput
          onChange={(e) => setAddress(e.target.value)}
          name="Address"
          placeholder="Address"
          value={address}
        />

        <NormalInput
          onChange={(e) => setEmail(e.target.value)}
          name="Email"
          placeholder="Email Address"
          value={email}
        />
      </div>
      <div className="flex items-center space-x-4  mb-4 md:w-1/2 w-full px-5">
        <div className="border-border border rounded-md h-[60px] flex   items-center w-2/12 justify-center ">
          <p className="text-base text-customBlack  font-[500]">+234</p>
        </div>
        <div className="w-full md:w-10/12">
          <NormalInput
            placeholder="Phone Number "
            onChange={(e: any) => setPhone(e.target.value)}
            name="phone"
            value={phone}
          />
        </div>
      </div>
      <div className="w-full px-4 2xl:px-6  flex flex-col pb-1 border-b border-b-border">
        <p className="text-neutral-500 text-lg  mt-">Work Details</p>
      </div>
      <div className="w-full md:w-11/12 grid md:grid-cols-2 grid-cols-2 space-y-4 md:space-y-0 gap-8 px-4 2xl:px-6 mt-4 ">
        <div className="flex w-full">
          <PrimarySelect
            selected={area}
            setSelected={setArea}
            label="Practice Area"
            data={practiceArea}
            name={item?.area}
          />
        </div>

        <div className="flex w-full">
          <PrimarySelect
            selected={country}
            setSelected={setCountry}
            label="Country"
            data={countries}
            name={item?.country}
          />
        </div>
        <PrimarySelect
          selected={city}
          setSelected={setCity}
          label="States"
          data={states}
          name={item?.state}
        />
      </div>
      <div className="md:mt-5 my-5 md:my-0  w-full md:w-11/12 px-4 2xl:px-6 ">
        <textarea
          name=""
          id=""
          className="w-full border border-border rounded-[10px] h-24 p-4 placeholder:text-placeholder"
          placeholder="Enter Bio"
          value={bio}
          onChange={(e: any) => setBio(e.target.value)}
        ></textarea>
      </div>
      <div className="w-3/12 mx-auto mt-8 mb-8 ">
        <Btn
          onClick={handleUpdate}
          active={true}
          name={corporate?.loading ? "loading" : "Save"}
        />
      </div>
    </div>
  );
};

export default CooporateEditProfile;
