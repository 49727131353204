import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { BriefLogo } from "../../../assets/svgs/homesvg";
import SideModal from "../../Modal/SideModal";
import noPic from "../../../assets/png/no-pic.png";
import { ModalTitle, Subtitle, TopicText } from "../../Text";
import { NormalInput } from "../../Input";
import UploadFile from "../../Upload/UploadFile";
import { Btn } from "../../Button";
import ArrowUp from "../../../assets/svgs/vuesax/linear/arrowUp";

const Payment = () => {
  const dispatch = useAppDispatch();
  const lawyer = useAppSelector((state) => state.lawyer);
  const allBrief = lawyer?.sharedBrief;
  console.log(allBrief);
  const [open, setOpen] = useState(false);

  const { userData } = useAppSelector((state) => state.auth);
  const user = userData?.user;
  const image =
    user?.profile_image !== null || undefined ? user?.profile_image : noPic;

  const tabs = [
    { name: "Inflow", id: 0 },
    { name: "OutFlow", id: 1 },
    { name: "Withdraw", id: 2 },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const outFlowData = [
    {
      status: "success",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
    {
      status: "pending",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
    {
      status: "success",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
    {
      status: "success",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
    {
      status: "success",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
  ];
  const inFlowData = [
    {
      status: "success",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
    {
      status: "pending",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
    {
      status: "success",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
    {
      status: "success",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
    {
      status: "success",
      name: "Lorem Ipsum",
      createdAt: new Date(),
      amount: 103030,
    },
  ];
  const withdrawalData = { amount: 500000 };
  return (
    <div className="w-full flex flex-col bg-white rounded-[10px]">
      <div className="w-full px-4 2xl:px-6 pt-7 flex flex-col pb-1 border-b border-b-border ">
        <h1 className="text-blueText md:text-2xl text-xl font-bold">Payment</h1>
      </div>
      {/*  */}
      <div className="flex items-center space-x-4  px-4 2xl:px-6 pt-2 border-b-[#CFCFCF] border-b-[1px]">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${
              selectedTab.id === tab.id
                ? "border-b-[#041C40] border-b-[3px]"
                : ""
            } rounded-[3px] cursor-pointer`}
            onClick={() => setSelectedTab(tab)}
          >
            <span
              className={
                selectedTab.id === tab.id
                  ? "text-base font-bold"
                  : "text-base font-[500]"
              }
            >
              {tab.name}
            </span>
          </div>
        ))}
      </div>
      <div className="px-4 2xl:px-6 pt-5 min-h-[40vh]">
        {selectedTab.id === 0 && <InFlow data={inFlowData} />}
        {selectedTab.id === 1 && <OutFlow data={outFlowData} />}
        {selectedTab.id === 2 && <Withdraw data={withdrawalData} />}
      </div>
    </div>
  );
};

const OutFlow = ({ data }: { data: any[] }) => {
  return (
    <div className="space-y-8 pt-2 pb-8">
      {data.map((item) => (
        <HistoryItem item={item} />
      ))}
    </div>
  );
};
const InFlow = ({ data }: { data: any[] }) => {
  return (
    <div className="space-y-8 pt-2 pb-8">
      {data.map((item) => (
        <HistoryItem item={item} />
      ))}
    </div>
  );
};
const Withdraw = ({ data }: { data: any }) => {
  return (
    <div>
      <div className="text-center">
        <p>Balance</p>
        <h1 className="text-2xl text-[#3AC56C] font-bold">
          ₦{Number(data.amount).toLocaleString()}
        </h1>
      </div>
      <div>
        <div className="mt-8 space-y-4">
          <ModalTitle text="Bank Name" />
          <Subtitle text="First Bank" />
        </div>
        <div className="mt-8 space-y-4">
          <ModalTitle text="Account Number" />
          <Subtitle text="1234567890" />
        </div>
      </div>
    </div>
  );
};
const HistoryItem = ({ item }: { item: any }) => {
  return (
    <div className="space-x-3 flex items-center">
      <div className="w-10 h-10 rounded-full bg-[#FAE7CC] flex items-center justify-center">
        <ArrowUp />
      </div>
      <div className="flex items-center justify-between w-full">
        <div className="">
          <h1 className="text-[20px] font-bold text-[#041C40]">
            Transfer From {item.name}
          </h1>
          <div className="flex items-center space-x-3">
            <span>{new Date(item.createdAt).toLocaleDateString()}</span>
            <span className="w-[5px] h-[5px] rounded-full bg-[#D3A518]"></span>
            <span>{new Date(item.createdAt).toLocaleTimeString()} </span>
          </div>
        </div>
        <div className="">
          <h1>₦{Number(item.amount).toLocaleString()}</h1>
          <div
            className={`${
              item.status === "pending" ? "text-[#E89D2B]" : "text-[#3AC56C]"
            } flex items-center space-x-1`}
          >
            <p
              className={`w-[5px] h-[5px] rounded-full ${
                item.status === "pending" ? "bg-[#E89D2B]" : "bg-[#3AC56C]"
              }`}
            ></p>

            <span>{item.status === "pending" ? "Pending" : "Successful"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
